import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FileType } from '@app/enums/FileTypes';
import { SiteStateService } from '@services/state-management/site-state.service';
import { StudyStateService } from '@services/state-management/study-state.service';
import { maxLengthValidator } from '@utility/utility.validators';
import { Observable, Subject, combineLatest, takeUntil, tap } from 'rxjs';
import { Site } from 'src/app/models/site';
import { InputChange } from '../../../../models/event-objects/input-change';
import { Study } from '../../../../models/study';

@Component({
    selector: 'medpace-study-details-card',
    templateUrl: './study-details.component.html',
})
export class MedpaceStudyDetailsCardComponent implements OnInit, OnDestroy, OnChanges {
    study: Study;
    study$: Observable<Study>;

    @Input()
    isClinTrakComparison = false;

    site: Site;
    site$: Observable<Site>;
    private componentDestroyed$: Subject<boolean> = new Subject();

    @Input()
    parentFormGroup: FormGroup;

    @Input()
    isEditing: boolean = false;

    @Output()
    inputChangeEvent = new EventEmitter<InputChange>();

    acceptableFileTypes: string[] = [FileType.JPG, FileType.JPEG, FileType.PNG];
    loaderSpinner: boolean = true;

    constructor(
        private studyStateService: StudyStateService,
        private siteStateService: SiteStateService
    ) {}

    ngOnInit(): void {
        this.initializeFormGroup();

        this.study$ = this.studyStateService.getStudy();
        this.site$ = this.siteStateService.getSite();

        combineLatest({ study: this.study$, site: this.site$ })
            .pipe(
                takeUntil(this.componentDestroyed$),
                tap(({ study, site }) => {
                    this.study = study;
                    this.site = site;
                    this.setValues();
                    if (this.study) this.loaderSpinner = false;
                })
            )
            .subscribe();
    }

    //need this because after click "Edit" all controls are enabled
    ngOnChanges(changes: SimpleChanges): void {
        if (
            changes.isEditing &&
            !changes.isEditing.previousValue &&
            changes.isEditing.currentValue &&
            this.study.isFromClinTrak
        ) {
            this.parentFormGroup.controls.studyCodeControl.disable();
        }
    }

    ngOnDestroy(): void {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }

    initializeFormGroup() {
        if (this.parentFormGroup) {
            this.parentFormGroup.addControl(
                'sponsorControl',
                new FormControl('', [Validators.required, maxLengthValidator(250)])
            );
            this.parentFormGroup.addControl(
                'protocolControl',
                new FormControl('', [Validators.required, maxLengthValidator(250)])
            );
            this.parentFormGroup.addControl(
                'studyCodeControl',
                new FormControl('', [Validators.required, maxLengthValidator(50)])
            );
        }
    }

    setValues() {
        if (this.study) {
            this.parentFormGroup.controls.sponsorControl.setValue(this.study.sponsor);
            this.parentFormGroup.controls.protocolControl.setValue(this.study.protocol);
            this.parentFormGroup.controls.studyCodeControl.setValue(this.study.studyCode);
            this.parentFormGroup.controls.studyCodeControl.disable();
        }
    }

    emitChange: (value: string, target: any) => void = (value: string, target: any) => {
        this.inputChangeEvent.emit({ target: target.id, value: value });
    };
    passFileAlong(event: any): void {
        this.inputChangeEvent.emit({ target: 'logo', value: event });
    }
}
