<mds-dialog
    dialogTitle="Edit Request"
    *ngIf="{ submitEditRequest: submit$ | async, deleteEditRequest: delete$ | async }"
>
    <mds-dialog-content>
        @if (patient$ | async; as patient) {
            <ng-container [ngTemplateOutlet]="dialog_body" [ngTemplateOutletContext]="{ $implicit: patient }" />
        } @else {
            <ng-container [ngTemplateOutlet]="loading" />
        }
    </mds-dialog-content>
    <mds-dialog-actions [align]="'end'">
        <mds-button variant="outline" label="Close" data-cy="close-modal" (clickEvent)="closeModal()" />
        <mds-button
            data-cy="delete-request"
            variant="primary"
            color="warn"
            label="Delete Edit Request"
            (clickEvent)="deleteRequestSubject.next(true)"
        />
        <mds-button data-cy="submit-request" variant="primary" label="Submit" (clickEvent)="submit()" type="submit" />
    </mds-dialog-actions>
</mds-dialog>

<ng-template #dialog_body let-untypedPatient>
    <ng-container *ngIf="toPatient(untypedPatient) as patient">
        @if (
            {
                _: setupFormGroup$ | async,
                countries: countriesStrings$ | async,
                isTravelSupported: isTravelSupported$ | async,
            };
            as setup
        ) {
            <div class="o-grid">
                <div class="o-grid__row row-margin">
                    <div class="o-grid__item">
                        <div class="c-info__block">
                            <h5>Patient Name: {{ getPatientFullNameTemplate(patient) }}</h5>
                        </div>
                    </div>
                </div>
                <div class="o-grid__row center row-margin" *ngIf="!!patientEditRequest?.profileChangeDetails">
                    <div class="o-grid__item">Patient Change Details</div>
                    <div class="o-grid__item show-newline">{{ patientEditRequest.profileChangeDetails }}</div>
                </div>
                @if (showPatientData(editRequestFormGroup)) {
                    <div class="o-grid__row center row-margin grid-margin">
                        <div class="o-grid__item">Field</div>
                        <div class="o-grid__item">Current value</div>
                        <div class="o-grid__item">Requested value</div>
                        <div class="o-grid__item checkbox-element">Accept Change</div>
                    </div>
                    <div
                        *ngIf="
                            showField(
                                patient?.patientIdentification?.phoneNumber,
                                editRequestFormGroup?.defaultValue?.phone
                            )
                        "
                        class="o-grid__row center row-margin"
                    >
                        <div class="o-grid__item text-value">Phone number</div>
                        <div class="o-grid__item text-value">
                            {{ patient?.patientIdentification?.phoneNumber || 'Undefined' }}
                        </div>
                        <div class="o-grid__item form-field">
                            <mds-text-field
                                id="patient-phone"
                                label="Phone number"
                                [formCtrl]="editRequestFormGroup?.controls?.phone"
                                placeholder="Type here..."
                                appearance="outline"
                                floatLabel="always"
                            />
                        </div>
                        <div class="o-grid__item checkbox-element">
                            <mds-checkbox [formCtrl]="acceptedFieldsFormGroup?.controls?.phone" />
                        </div>
                    </div>
                    <div
                        *ngIf="
                            showField(
                                patient?.patientIdentification?.preferredLanguage,
                                editRequestFormGroup?.defaultValue?.preferredLang
                            )
                        "
                        class="o-grid__row center row-margin"
                    >
                        <div class="o-grid__item text-value">Preferred Language</div>
                        <div class="o-grid__item text-value">
                            {{ patient?.patientIdentification?.preferredLanguage || 'Undefined' }}
                        </div>
                        <div class="o-grid__item form-field">
                            <mds-text-field
                                id="patient-preferred-language"
                                label="Preferred Language"
                                [formCtrl]="editRequestFormGroup?.controls?.preferredLang"
                                placeholder="Type here..."
                                appearance="outline"
                                floatLabel="always"
                            />
                        </div>
                        <div class="o-grid__item checkbox-element">
                            <mds-checkbox [formCtrl]="acceptedFieldsFormGroup?.controls?.preferredLang" />
                        </div>
                    </div>
                    <div
                        *ngIf="
                            showField(
                                patient?.patientIdentification?.address1,
                                editRequestFormGroup?.defaultValue?.address1
                            )
                        "
                        class="o-grid__row center row-margin"
                    >
                        <div class="o-grid__item text-value">Address Line 1</div>
                        <div class="o-grid__item text-value">
                            {{ patient?.patientIdentification?.address1 || 'Undefined' }}
                        </div>
                        <div class="o-grid__item form-field">
                            <mds-text-field
                                id="patient-address-1"
                                label="Address Line 1"
                                [formCtrl]="editRequestFormGroup?.controls?.address1"
                                placeholder="Type here..."
                                appearance="outline"
                                floatLabel="always"
                            />
                        </div>
                        <div class="o-grid__item checkbox-element">
                            <mds-checkbox [formCtrl]="acceptedFieldsFormGroup?.controls?.address1" />
                        </div>
                    </div>
                    <div
                        *ngIf="
                            showField(
                                patient?.patientIdentification?.address2,
                                editRequestFormGroup?.defaultValue?.address2
                            )
                        "
                        class="o-grid__row center row-margin"
                    >
                        <div class="o-grid__item text-value">Address Line 2</div>
                        <div class="o-grid__item text-value">
                            {{ patient?.patientIdentification?.address2 || 'Undefined' }}
                        </div>
                        <div class="o-grid__item form-field">
                            <mds-text-field
                                id="patient-address-2"
                                label="Address Line 2"
                                [formCtrl]="editRequestFormGroup?.controls?.address2"
                                placeholder="Type here..."
                                appearance="outline"
                                floatLabel="always"
                            />
                        </div>
                        <div class="o-grid__item checkbox-element">
                            <mds-checkbox [formCtrl]="acceptedFieldsFormGroup?.controls?.address2" />
                        </div>
                    </div>
                    <div
                        *ngIf="
                            showField(patient?.patientIdentification?.city, editRequestFormGroup?.defaultValue?.city)
                        "
                        class="o-grid__row center row-margin"
                    >
                        <div class="o-grid__item text-value">City</div>
                        <div class="o-grid__item text-value">
                            {{ patient?.patientIdentification?.city || 'Undefined' }}
                        </div>
                        <div class="o-grid__item form-field">
                            <mds-text-field
                                id="patient-city"
                                label="City"
                                [formCtrl]="editRequestFormGroup?.controls?.city"
                                placeholder="Type here..."
                                appearance="outline"
                                floatLabel="always"
                            />
                        </div>
                        <div class="o-grid__item checkbox-element">
                            <mds-checkbox [formCtrl]="acceptedFieldsFormGroup?.controls?.city" />
                        </div>
                    </div>

                    <div
                        *ngIf="
                            showField(patient?.patientIdentification?.state, editRequestFormGroup?.defaultValue?.state)
                        "
                        class="o-grid__row center row-margin"
                    >
                        @if (regionType$ | async; as regionType) {
                            <div class="o-grid__item text-value">Province / Region / State</div>
                            <div class="o-grid__item text-value">
                                {{ patient?.patientIdentification?.state || 'Undefined' }}
                            </div>
                            <div class="o-grid__item form-field">
                                <mds-text-field
                                    id="patient-state"
                                    [label]="regionType"
                                    [formCtrl]="editRequestFormGroup?.controls?.state"
                                    placeholder="Type here..."
                                    appearance="outline"
                                    floatLabel="always"
                                />
                            </div>
                            <div class="o-grid__item checkbox-element">
                                <mds-checkbox [formCtrl]="acceptedFieldsFormGroup?.controls?.state" />
                            </div>
                        }
                    </div>

                    <div
                        *ngIf="
                            showField(
                                patient?.patientIdentification?.zipcode,
                                editRequestFormGroup?.defaultValue?.zipcode
                            )
                        "
                        class="o-grid__row center row-margin"
                    >
                        <div class="o-grid__item text-value">Zipcode</div>
                        <div class="o-grid__item text-value">
                            {{ patient?.patientIdentification?.zipcode || 'Undefined' }}
                        </div>
                        <div class="o-grid__item form-field">
                            <mds-text-field
                                id="patient-zipcode"
                                label="Zipcode"
                                [formCtrl]="editRequestFormGroup?.controls?.zipcode"
                                placeholder="Type here..."
                                appearance="outline"
                                floatLabel="always"
                            />
                        </div>
                        <div class="o-grid__item checkbox-element">
                            <mds-checkbox [formCtrl]="acceptedFieldsFormGroup?.controls?.zipcode" />
                        </div>
                    </div>
                    <div
                        *ngIf="
                            showField(
                                patient?.patientIdentification?.country,
                                editRequestFormGroup?.defaultValue?.country
                            )
                        "
                        class="o-grid__row center row-margin"
                    >
                        <div class="o-grid__item text-value">Country</div>
                        <div class="o-grid__item text-value">
                            {{ patient?.patientIdentification?.country || 'Undefined' }}
                        </div>
                        <div class="o-grid__item form-field">
                            <mds-autocomplete
                                id="patient-country"
                                label="Country"
                                [formCtrl]="editRequestFormGroup?.controls?.country"
                                [options]="setup.countries"
                                placeholder="Type here..."
                                appearance="outline"
                                floatLabel="always"
                                [validateInput]="true"
                            />
                        </div>
                        <div class="o-grid__item checkbox-element">
                            <mds-checkbox [formCtrl]="acceptedFieldsFormGroup?.controls?.country" />
                        </div>
                    </div>
                }
            </div>
            <div class="o-grid grid-margin" *ngIf="setup.isTravelSupported && showTravelForm(editRequestFormGroup)">
                <div class="o-grid__row center">
                    <div class="o-grid__item">
                        <mds-expansion-panel
                            panelTitle="Travel Preferences"
                            description="Open To Accept Changes"
                            [expanded]="true"
                            [icon]="'card_travel'"
                        >
                            <mds-tab-group
                                data-cy="travel-tab-group"
                                [tabAlign]="'center'"
                                [dynamicHeight]="true"
                                [animationDuration]="'0ms'"
                            >
                                <!-- FLIGHT TAB -->
                                @if (showFlightTab(editRequestFormGroup?.controls?.airlineEditRequest)) {
                                    <mds-tab
                                        #flightTab
                                        [label]="'Flights'"
                                        formErrorHighlighter
                                        [forms]="[editRequestFormGroup?.controls?.airlineEditRequest]"
                                        [filterContent]="flightTab.label"
                                    >
                                        <div [id]="'flightTab'">
                                            <div class="o-grid grid-margin">
                                                <div class="o-grid__row center row-margin">
                                                    <div class="o-grid__item">Field</div>
                                                    <div class="o-grid__item">Current value</div>
                                                    <div class="o-grid__item">Requested value</div>
                                                    <div class="o-grid__item checkbox-element">Accept Change</div>
                                                </div>
                                                <div
                                                    *ngIf="
                                                        showField(
                                                            patient?.travelPreferences?.airtravel?.airlinePreference1,
                                                            editRequestFormGroup?.defaultValue?.airlineEditRequest
                                                                ?.airlinePrimary
                                                        )
                                                    "
                                                    class="o-grid__row center row-margin"
                                                >
                                                    <div class="o-grid__item text-value">Airline preference #1</div>
                                                    <div class="o-grid__item text-value">
                                                        {{
                                                            patient?.travelPreferences?.airtravel?.airlinePreference1 ||
                                                                'Undefined'
                                                        }}
                                                    </div>
                                                    <div class="o-grid__item form-field">
                                                        <mds-text-field
                                                            id="patient-airline-preference-1"
                                                            label="Airline preference #1"
                                                            [formCtrl]="
                                                                editRequestFormGroup?.controls?.airlineEditRequest
                                                                    .controls.airlinePrimary
                                                            "
                                                            placeholder="Type here..."
                                                            appearance="outline"
                                                            floatLabel="always"
                                                        />
                                                    </div>
                                                    <div class="o-grid__item checkbox-element">
                                                        <mds-checkbox
                                                            [formCtrl]="
                                                                acceptedFieldsFormGroup?.controls?.airlineEditRequest
                                                                    .controls.airlinePrimary
                                                            "
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    *ngIf="
                                                        showField(
                                                            patient?.travelPreferences?.airtravel?.frequentFlyer1,
                                                            editRequestFormGroup?.defaultValue?.airlineEditRequest
                                                                ?.airlineRewardsPrimary
                                                        )
                                                    "
                                                    class="o-grid__row center row-margin"
                                                >
                                                    <div class="o-grid__item text-value">Frequent Flyer No. #1</div>
                                                    <div class="o-grid__item text-value">
                                                        {{
                                                            patient?.travelPreferences?.airtravel?.frequentFlyer1 ||
                                                                'Undefined'
                                                        }}
                                                    </div>
                                                    <div class="o-grid__item form-field">
                                                        <mds-text-field
                                                            id="patient-frequent-flyer-1"
                                                            label="Frequent Flyer No. #1"
                                                            [formCtrl]="
                                                                editRequestFormGroup?.controls?.airlineEditRequest
                                                                    .controls.airlineRewardsPrimary
                                                            "
                                                            placeholder="Type here..."
                                                            appearance="outline"
                                                            floatLabel="always"
                                                        />
                                                    </div>
                                                    <div class="o-grid__item checkbox-element">
                                                        <mds-checkbox
                                                            [formCtrl]="
                                                                acceptedFieldsFormGroup?.controls?.airlineEditRequest
                                                                    .controls.airlineRewardsPrimary
                                                            "
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    *ngIf="
                                                        showField(
                                                            patient?.travelPreferences?.airtravel?.airlinePreference2,
                                                            editRequestFormGroup?.defaultValue?.airlineEditRequest
                                                                ?.airlineSecondary
                                                        )
                                                    "
                                                    class="o-grid__row center row-margin"
                                                >
                                                    <div class="o-grid__item text-value">Airline preference #2</div>
                                                    <div class="o-grid__item text-value">
                                                        {{
                                                            patient?.travelPreferences?.airtravel?.airlinePreference2 ||
                                                                'Undefined'
                                                        }}
                                                    </div>
                                                    <div class="o-grid__item form-field">
                                                        <mds-text-field
                                                            id="patient-airline-preference-2"
                                                            label="Airline preference #2"
                                                            [formCtrl]="
                                                                editRequestFormGroup?.controls?.airlineEditRequest
                                                                    .controls.airlineSecondary
                                                            "
                                                            placeholder="Type here..."
                                                            appearance="outline"
                                                            floatLabel="always"
                                                        />
                                                    </div>
                                                    <div class="o-grid__item checkbox-element">
                                                        <mds-checkbox
                                                            [formCtrl]="
                                                                acceptedFieldsFormGroup?.controls?.airlineEditRequest
                                                                    .controls.airlineSecondary
                                                            "
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    *ngIf="
                                                        showField(
                                                            patient?.travelPreferences?.airtravel?.frequentFlyer2,
                                                            editRequestFormGroup?.defaultValue?.airlineEditRequest
                                                                .airlineRewardsSecondary
                                                        )
                                                    "
                                                    class="o-grid__row center row-margin"
                                                >
                                                    <div class="o-grid__item text-value">Frequent Flyer No. #2</div>
                                                    <div class="o-grid__item text-value">
                                                        {{
                                                            patient?.travelPreferences?.airtravel?.frequentFlyer2 ||
                                                                'Undefined'
                                                        }}
                                                    </div>
                                                    <div class="o-grid__item form-field">
                                                        <mds-text-field
                                                            id="patient-frequent-flyer-2"
                                                            label="Frequent Flyer No. #2"
                                                            [formCtrl]="
                                                                editRequestFormGroup?.controls?.airlineEditRequest
                                                                    .controls.airlineRewardsSecondary
                                                            "
                                                            placeholder="Type here..."
                                                            appearance="outline"
                                                            floatLabel="always"
                                                        />
                                                    </div>
                                                    <div class="o-grid__item checkbox-element">
                                                        <mds-checkbox
                                                            [formCtrl]="
                                                                acceptedFieldsFormGroup?.controls?.airlineEditRequest
                                                                    .controls.airlineRewardsSecondary
                                                            "
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    *ngIf="
                                                        showField(
                                                            patient?.travelPreferences?.airtravel?.seatPreference,
                                                            editRequestFormGroup?.defaultValue?.airlineEditRequest
                                                                ?.seatPreference
                                                        )
                                                    "
                                                    class="o-grid__row center row-margin"
                                                >
                                                    <div class="o-grid__item text-value">Seat Preference</div>
                                                    <div class="o-grid__item text-value">
                                                        {{
                                                            patient?.travelPreferences?.airtravel?.seatPreference ||
                                                                'Undefined'
                                                        }}
                                                    </div>
                                                    <div class="o-grid__item form-field">
                                                        <mds-text-field
                                                            id="patient-seat-preference"
                                                            label="Seat Preference"
                                                            [formCtrl]="
                                                                editRequestFormGroup?.controls?.airlineEditRequest
                                                                    .controls.seatPreference
                                                            "
                                                            placeholder="Type here..."
                                                            appearance="outline"
                                                            floatLabel="always"
                                                        />
                                                    </div>
                                                    <div class="o-grid__item checkbox-element">
                                                        <mds-checkbox
                                                            [formCtrl]="
                                                                acceptedFieldsFormGroup?.controls?.airlineEditRequest
                                                                    .controls.seatPreference
                                                            "
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    *ngIf="
                                                        showField(
                                                            patient?.travelPreferences?.airtravel?.knownTravelerNumber,
                                                            editRequestFormGroup?.defaultValue?.airlineEditRequest
                                                                ?.knownTravelerNum
                                                        )
                                                    "
                                                    class="o-grid__row center row-margin"
                                                >
                                                    <div class="o-grid__item text-value">Known Traveler Number</div>
                                                    <div class="o-grid__item text-value">
                                                        {{
                                                            patient?.travelPreferences?.airtravel
                                                                ?.knownTravelerNumber || 'Undefined'
                                                        }}
                                                    </div>
                                                    <div class="o-grid__item form-field">
                                                        <mds-text-field
                                                            id="patient-known-traveler-number"
                                                            label="Known Traveler Number"
                                                            [formCtrl]="
                                                                editRequestFormGroup?.controls?.airlineEditRequest
                                                                    .controls.knownTravelerNum
                                                            "
                                                            placeholder="Type here..."
                                                            appearance="outline"
                                                            floatLabel="always"
                                                        />
                                                    </div>
                                                    <div class="o-grid__item checkbox-element">
                                                        <mds-checkbox
                                                            [formCtrl]="
                                                                acceptedFieldsFormGroup?.controls?.airlineEditRequest
                                                                    .controls.knownTravelerNum
                                                            "
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    *ngIf="
                                                        showField(
                                                            patient?.travelPreferences?.airtravel?.airlineSpecialNeeds,
                                                            editRequestFormGroup?.defaultValue?.airlineEditRequest
                                                                ?.airlineSpecialNeeds
                                                        )
                                                    "
                                                    class="o-grid__row center row-margin"
                                                >
                                                    <div class="o-grid__item text-value">Special Needs</div>
                                                    <div class="o-grid__item text-value show-newline">
                                                        {{
                                                            patient?.travelPreferences?.airtravel
                                                                ?.airlineSpecialNeeds || 'Undefined'
                                                        }}
                                                    </div>
                                                    <div class="o-grid__item form-field">
                                                        <mds-textarea
                                                            id="patient-airline-special-needs"
                                                            label="Special Needs"
                                                            [formCtrl]="
                                                                editRequestFormGroup?.controls?.airlineEditRequest
                                                                    .controls.airlineSpecialNeeds
                                                            "
                                                            placeholder="Type here..."
                                                            appearance="outline"
                                                            floatLabel="always"
                                                            [autoSize]="true"
                                                        />
                                                    </div>
                                                    <div class="o-grid__item checkbox-element">
                                                        <mds-checkbox
                                                            [formCtrl]="
                                                                acceptedFieldsFormGroup?.controls?.airlineEditRequest
                                                                    .controls.airlineSpecialNeeds
                                                            "
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </mds-tab>
                                }
                                <!-- TRAIN TAB -->
                                @if (
                                    showField(
                                        patient?.travelPreferences?.traintravel?.specialNeeds,
                                        editRequestFormGroup?.defaultValue?.trainEditRequest.trainSpecialNeeds
                                    )
                                ) {
                                    <mds-tab
                                        [label]="'Train'"
                                        #trainTab
                                        formErrorHighlighter
                                        [forms]="[editRequestFormGroup?.controls?.trainEditRequest]"
                                        [filterContent]="trainTab.label"
                                    >
                                        <div [id]="'trainTab'">
                                            <div class="o-grid grid-margin">
                                                <div class="o-grid__row center row-margin">
                                                    <div class="o-grid__item">Field</div>
                                                    <div class="o-grid__item">Current value</div>
                                                    <div class="o-grid__item">Requested value</div>
                                                    <div class="o-grid__item checkbox-element">Accept Change</div>
                                                </div>
                                                <div class="o-grid__row center row-margin">
                                                    <div class="o-grid__item text-value">Special Needs</div>
                                                    <div class="o-grid__item text-value show-newline">
                                                        {{
                                                            patient?.travelPreferences?.traintravel?.specialNeeds ||
                                                                'Undefined'
                                                        }}
                                                    </div>
                                                    <div class="o-grid__item form-field">
                                                        <mds-textarea
                                                            id="patient-train-special-needs"
                                                            label="Special Needs"
                                                            [formCtrl]="
                                                                editRequestFormGroup?.controls?.trainEditRequest
                                                                    .controls.trainSpecialNeeds
                                                            "
                                                            placeholder="Type here..."
                                                            appearance="outline"
                                                            floatLabel="always"
                                                            [autoSize]="true"
                                                        />
                                                    </div>
                                                    <div class="o-grid__item checkbox-element">
                                                        <mds-checkbox
                                                            [formCtrl]="
                                                                acceptedFieldsFormGroup?.controls?.trainEditRequest
                                                                    .controls.trainSpecialNeeds
                                                            "
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </mds-tab>
                                }
                                <!-- INTERNATIONAL TAB -->
                                @if (showInternationalTab(editRequestFormGroup?.controls?.passportInfo)) {
                                    <mds-tab
                                        [label]="'International'"
                                        #internationalTab
                                        formErrorHighlighter
                                        [forms]="[editRequestFormGroup?.controls?.passportInfo]"
                                        [filterContent]="internationalTab.label"
                                    >
                                        <div [id]="'internationalTab'">
                                            <div class="o-grid grid-margin">
                                                <div class="o-grid__row center row-margin">
                                                    <div class="o-grid__item">Field</div>
                                                    <div class="o-grid__item">Current value</div>
                                                    <div class="o-grid__item">Requested value</div>
                                                    <div class="o-grid__item checkbox-element">Accept Change</div>
                                                </div>
                                                <div
                                                    class="o-grid__row center row-margin"
                                                    *ngIf="
                                                        editRequestFormGroup?.controls?.passportInfo.hasError(
                                                            'requiredAllValues'
                                                        )
                                                    "
                                                >
                                                    <medpace-alert
                                                        class="alert"
                                                        [content]="'All passport fields must be inserted'"
                                                        [additionalClass]="'danger'"
                                                    ></medpace-alert>
                                                </div>
                                                <div
                                                    *ngIf="
                                                        showField(
                                                            patient?.travelPreferences?.internationaltravel
                                                                ?.passportNum,
                                                            editRequestFormGroup?.defaultValue?.passportInfo
                                                                ?.passportNum
                                                        )
                                                    "
                                                    class="o-grid__row center row-margin"
                                                >
                                                    <div class="o-grid__item text-value">Passport Number</div>
                                                    <div class="o-grid__item text-value">
                                                        {{
                                                            patient?.travelPreferences?.internationaltravel
                                                                ?.passportNum || 'Undefined'
                                                        }}
                                                    </div>
                                                    <div class="o-grid__item form-field">
                                                        <mds-text-field
                                                            id="patient-passport-number"
                                                            label="Passport Number"
                                                            [formCtrl]="
                                                                editRequestFormGroup?.controls?.passportInfo.controls
                                                                    .passportNum
                                                            "
                                                            placeholder="Type here..."
                                                            appearance="outline"
                                                            floatLabel="always"
                                                        />
                                                    </div>
                                                    <div class="o-grid__item checkbox-element">
                                                        <mds-checkbox
                                                            [formCtrl]="
                                                                acceptedFieldsFormGroup?.controls?.passportInfo.controls
                                                                    .passportNum
                                                            "
                                                        />
                                                    </div>
                                                </div>
                                                @if (
                                                    {
                                                        passportCountry:
                                                            getCountryNameFromCode(
                                                                patient?.travelPreferences?.internationaltravel
                                                                    ?.passportCountry
                                                            ) | async,
                                                    };
                                                    as ps
                                                ) {
                                                    <div
                                                        *ngIf="
                                                            showField(
                                                                ps.passportCountry,
                                                                editRequestFormGroup?.defaultValue?.passportInfo
                                                                    ?.passportCountry
                                                            )
                                                        "
                                                        class="o-grid__row center row-margin"
                                                    >
                                                        <div class="o-grid__item text-value">Passport Country</div>
                                                        <div class="o-grid__item text-value">
                                                            {{ ps.passportCountry || 'Undefined' }}
                                                        </div>
                                                        <div class="o-grid__item form-field">
                                                            <mds-autocomplete
                                                                id="patient-passport-country"
                                                                label="Passport Country"
                                                                [formCtrl]="
                                                                    editRequestFormGroup?.controls?.passportInfo
                                                                        .controls.passportCountry
                                                                "
                                                                placeholder="Type here..."
                                                                appearance="outline"
                                                                floatLabel="always"
                                                                [options]="setup.countries"
                                                                [validateInput]="true"
                                                            />
                                                        </div>
                                                        <div class="o-grid__item checkbox-element">
                                                            <mds-checkbox
                                                                [formCtrl]="
                                                                    acceptedFieldsFormGroup?.controls?.passportInfo
                                                                        .controls.passportCountry
                                                                "
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                                <div
                                                    *ngIf="
                                                        showField(
                                                            patient?.travelPreferences?.internationaltravel
                                                                ?.passportIssue,
                                                            editRequestFormGroup?.defaultValue?.passportInfo
                                                                ?.passportIssue
                                                        )
                                                    "
                                                    class="o-grid__row center row-margin"
                                                >
                                                    <div class="o-grid__item text-value">Passport Issue Date</div>
                                                    <div class="o-grid__item text-value">
                                                        {{
                                                            (getFormattedDate(
                                                                patient?.travelPreferences?.internationaltravel?.passportIssue?.toDate()
                                                            ) | uppercase) || 'Undefined'
                                                        }}
                                                    </div>
                                                    <div class="o-grid__item form-field">
                                                        <medpace-datepicker
                                                            id="patient-passport-issue"
                                                            label="Passport Issue Date"
                                                            [formCtrl]="
                                                                editRequestFormGroup?.controls?.passportInfo.controls
                                                                    .passportIssue
                                                            "
                                                            appearance="outline"
                                                            floatLabel="always"
                                                        />
                                                    </div>
                                                    <div class="o-grid__item checkbox-element">
                                                        <mds-checkbox
                                                            [formCtrl]="
                                                                acceptedFieldsFormGroup?.controls?.passportInfo.controls
                                                                    .passportIssue
                                                            "
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    *ngIf="
                                                        showField(
                                                            patient?.travelPreferences?.internationaltravel
                                                                ?.passportExpiration,
                                                            editRequestFormGroup?.defaultValue?.passportInfo
                                                                ?.passportExpiration
                                                        )
                                                    "
                                                    class="o-grid__row center row-margin"
                                                >
                                                    <div class="o-grid__item text-value">Passport Expiration Date</div>
                                                    <div class="o-grid__item text-value">
                                                        {{
                                                            (getFormattedDate(
                                                                patient?.travelPreferences?.internationaltravel?.passportExpiration?.toDate()
                                                            ) | uppercase) || 'Undefined'
                                                        }}
                                                    </div>
                                                    <div class="o-grid__item form-field">
                                                        <medpace-datepicker
                                                            id="patient-passport-expiration"
                                                            label="Passport Expiration Date"
                                                            [formCtrl]="
                                                                editRequestFormGroup?.controls?.passportInfo.controls
                                                                    .passportExpiration
                                                            "
                                                            appearance="outline"
                                                            floatLabel="always"
                                                        />
                                                    </div>
                                                    <div class="o-grid__item checkbox-element">
                                                        <mds-checkbox
                                                            [formCtrl]="
                                                                acceptedFieldsFormGroup?.controls?.passportInfo.controls
                                                                    .passportExpiration
                                                            "
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </mds-tab>
                                }
                                <!-- LODGING TAB -->
                                @if (showLodgingTab(editRequestFormGroup?.controls?.lodgingEditRequest)) {
                                    <mds-tab
                                        [label]="'Lodging'"
                                        #lodgingTab
                                        formErrorHighlighter
                                        [forms]="[editRequestFormGroup?.controls?.lodgingEditRequest]"
                                        [filterContent]="lodgingTab.label"
                                    >
                                        <div [id]="'lodgingTab'">
                                            <div class="o-grid grid-margin">
                                                <div class="o-grid__row center row-margin">
                                                    <div class="o-grid__item">Field</div>
                                                    <div class="o-grid__item">Current value</div>
                                                    <div class="o-grid__item">Requested value</div>
                                                    <div class="o-grid__item checkbox-element">Accept Change</div>
                                                </div>
                                                <div
                                                    *ngIf="
                                                        showField(
                                                            patient?.travelPreferences?.lodging?.hotelBrandPreference,
                                                            editRequestFormGroup?.defaultValue?.lodgingEditRequest
                                                                ?.hotelChainPrimary
                                                        )
                                                    "
                                                    class="o-grid__row center row-margin"
                                                >
                                                    <div class="o-grid__item text-value">Hotel Brand Preference</div>
                                                    <div class="o-grid__item text-value">
                                                        {{
                                                            patient?.travelPreferences?.lodging?.hotelBrandPreference ||
                                                                'Undefined'
                                                        }}
                                                    </div>
                                                    <div class="o-grid__item form-field">
                                                        <mds-text-field
                                                            id="patient-lodging-hotel"
                                                            label="Hotel Brand Preference"
                                                            [formCtrl]="
                                                                editRequestFormGroup?.controls?.lodgingEditRequest
                                                                    .controls.hotelChainPrimary
                                                            "
                                                            placeholder="Type here..."
                                                            appearance="outline"
                                                            floatLabel="always"
                                                        />
                                                    </div>
                                                    <div class="o-grid__item checkbox-element">
                                                        <mds-checkbox
                                                            [formCtrl]="
                                                                acceptedFieldsFormGroup?.controls?.lodgingEditRequest
                                                                    .controls.hotelChainPrimary
                                                            "
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    *ngIf="
                                                        showField(
                                                            patient?.travelPreferences?.lodging?.lodgingRoomPreference,
                                                            editRequestFormGroup?.defaultValue?.lodgingEditRequest
                                                                ?.lodgingRoomPreference
                                                        )
                                                    "
                                                    class="o-grid__row center row-margin"
                                                >
                                                    <div class="o-grid__item text-value">Hotel Room Preference</div>
                                                    <div class="o-grid__item text-value">
                                                        {{
                                                            patient?.travelPreferences?.lodging
                                                                ?.lodgingRoomPreference || 'Undefined'
                                                        }}
                                                    </div>
                                                    <div class="o-grid__item form-field">
                                                        <mds-text-field
                                                            id="patient-lodging-room"
                                                            label="Hotel Room Preference"
                                                            [formCtrl]="
                                                                editRequestFormGroup?.controls?.lodgingEditRequest
                                                                    .controls.lodgingRoomPreference
                                                            "
                                                            placeholder="Type here..."
                                                            appearance="outline"
                                                            floatLabel="always"
                                                        />
                                                    </div>
                                                    <div class="o-grid__item checkbox-element">
                                                        <mds-checkbox
                                                            [formCtrl]="
                                                                acceptedFieldsFormGroup?.controls?.lodgingEditRequest
                                                                    .controls.lodgingRoomPreference
                                                            "
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    *ngIf="
                                                        showField(
                                                            patient?.travelPreferences?.lodging?.allergies,
                                                            editRequestFormGroup?.defaultValue?.lodgingEditRequest
                                                                ?.allergies
                                                        )
                                                    "
                                                    class="o-grid__row center row-margin"
                                                >
                                                    <div class="o-grid__item text-value">Allergies</div>
                                                    <div class="o-grid__item text-value show-newline">
                                                        {{
                                                            patient?.travelPreferences?.lodging?.allergies ||
                                                                'Undefined'
                                                        }}
                                                    </div>
                                                    <div class="o-grid__item form-field">
                                                        <mds-textarea
                                                            id="patient-lodging-allergies"
                                                            label="Allergies"
                                                            [formCtrl]="
                                                                editRequestFormGroup?.controls?.lodgingEditRequest
                                                                    .controls.allergies
                                                            "
                                                            placeholder="Type here..."
                                                            appearance="outline"
                                                            floatLabel="always"
                                                            [autoSize]="true"
                                                        />
                                                    </div>
                                                    <div class="o-grid__item checkbox-element">
                                                        <mds-checkbox
                                                            [formCtrl]="
                                                                acceptedFieldsFormGroup?.controls?.lodgingEditRequest
                                                                    .controls.allergies
                                                            "
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    *ngIf="
                                                        showField(
                                                            patient?.travelPreferences?.lodging.specialRequirements,
                                                            editRequestFormGroup?.defaultValue?.lodgingEditRequest
                                                                ?.lodgingSpecialNeeds
                                                        )
                                                    "
                                                    class="o-grid__row center row-margin"
                                                >
                                                    <div class="o-grid__item text-value">Special Needs</div>
                                                    <div class="o-grid__item text-value show-newline">
                                                        {{
                                                            patient?.travelPreferences?.lodging.specialRequirements ||
                                                                'Undefined'
                                                        }}
                                                    </div>
                                                    <div class="o-grid__item form-field">
                                                        <mds-textarea
                                                            id="patient-lodging-room"
                                                            label="Special Needs"
                                                            [formCtrl]="
                                                                editRequestFormGroup?.controls?.lodgingEditRequest
                                                                    .controls.lodgingSpecialNeeds
                                                            "
                                                            placeholder="Type here..."
                                                            appearance="outline"
                                                            floatLabel="always"
                                                            [autoSize]="true"
                                                        />
                                                    </div>
                                                    <div class="o-grid__item checkbox-element">
                                                        <mds-checkbox
                                                            [formCtrl]="
                                                                acceptedFieldsFormGroup?.controls?.lodgingEditRequest
                                                                    .controls.lodgingSpecialNeeds
                                                            "
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </mds-tab>
                                }
                                <!-- RENTAL CAR TAB -->
                                @if (
                                    showField(
                                        patient?.travelPreferences?.rentalCar?.rentalCarFrequentTravelerNumber,
                                        editRequestFormGroup?.defaultValue?.rentalCarEditRequest
                                            ?.rentalCarFrequentTravelerNum
                                    )
                                ) {
                                    <mds-tab
                                        [label]="'Rental Car'"
                                        #carRentalTab
                                        formErrorHighlighter
                                        [forms]="[editRequestFormGroup?.controls?.rentalCarEditRequest]"
                                        [filterContent]="carRentalTab.label"
                                    >
                                        <div [id]="'carRentalTab'">
                                            <div class="o-grid grid-margin">
                                                <div class="o-grid__row center row-margin">
                                                    <div class="o-grid__item">Field</div>
                                                    <div class="o-grid__item">Current value</div>
                                                    <div class="o-grid__item">Requested value</div>
                                                    <div class="o-grid__item checkbox-element">Accept Change</div>
                                                </div>
                                                <div class="o-grid__row center row-margin">
                                                    <div class="o-grid__item text-value">
                                                        Rental Company Frequent Traveler Number
                                                    </div>
                                                    <div class="o-grid__item text-value">
                                                        {{
                                                            patient?.travelPreferences?.rentalCar
                                                                ?.rentalCarFrequentTravelerNumber || 'Undefined'
                                                        }}
                                                    </div>
                                                    <div class="o-grid__item form-field">
                                                        <mds-text-field
                                                            id="patient-rental-car-number"
                                                            label="Rental Company Frequent Traveler Number"
                                                            [formCtrl]="
                                                                editRequestFormGroup?.controls?.rentalCarEditRequest
                                                                    .controls.rentalCarFrequentTravelerNum
                                                            "
                                                            placeholder="Type here..."
                                                            appearance="outline"
                                                            floatLabel="always"
                                                        />
                                                    </div>
                                                    <div class="o-grid__item checkbox-element">
                                                        <mds-checkbox
                                                            [formCtrl]="
                                                                acceptedFieldsFormGroup?.controls?.rentalCarEditRequest
                                                                    ?.controls?.rentalCarFrequentTravelerNum
                                                            "
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </mds-tab>
                                }
                                <!-- CAR SERVICE TAB -->
                                @if (
                                    showField(
                                        patient?.travelPreferences?.groundTransportation?.groundSpecialNeeds,
                                        editRequestFormGroup?.defaultValue?.carServiceEditRequest?.groundSpecialNeeds
                                    )
                                ) {
                                    <mds-tab
                                        [label]="'Car Service'"
                                        #transferTab
                                        formErrorHighlighter
                                        [forms]="[editRequestFormGroup?.controls?.carServiceEditRequest]"
                                        [filterContent]="transferTab.label"
                                    >
                                        <div [id]="'car-service-tab'">
                                            <div class="o-grid grid-margin">
                                                <div class="o-grid__row center row-margin">
                                                    <div class="o-grid__item">Field</div>
                                                    <div class="o-grid__item">Current value</div>
                                                    <div class="o-grid__item">Requested value</div>
                                                    <div class="o-grid__item checkbox-element">Accept Change</div>
                                                </div>
                                                <div class="o-grid__row center row-margin">
                                                    <div class="o-grid__item text-value">Special Needs</div>
                                                    <div class="o-grid__item text-value show-newline">
                                                        {{
                                                            patient?.travelPreferences?.groundTransportation
                                                                ?.groundSpecialNeeds || 'Undefined'
                                                        }}
                                                    </div>
                                                    <div class="o-grid__item form-field">
                                                        <mds-textarea
                                                            id="patient-ground-special-needs"
                                                            label="Special Needs"
                                                            [formCtrl]="
                                                                editRequestFormGroup?.controls?.carServiceEditRequest
                                                                    .controls.groundSpecialNeeds
                                                            "
                                                            placeholder="Type here..."
                                                            appearance="outline"
                                                            floatLabel="always"
                                                            [autoSize]="true"
                                                        />
                                                    </div>
                                                    <div class="o-grid__item checkbox-element">
                                                        <mds-checkbox
                                                            [formCtrl]="
                                                                acceptedFieldsFormGroup?.controls?.carServiceEditRequest
                                                                    ?.controls?.groundSpecialNeeds
                                                            "
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </mds-tab>
                                }
                            </mds-tab-group>
                        </mds-expansion-panel>
                    </div>
                </div>
            </div>

            @for (
                caregiverFormGroup of editRequestFormGroup?.controls?.caregiverEditRequest.controls;
                track caregiverFormGroup
            ) {
                <!-- check if any data is changed in caregiver form -->
                @if (showCaregiverCard(caregiverFormGroup)) {
                    <!-- setup data -->
                    @if (
                        {
                            caregiver: getCaregiver(patient, caregiverFormGroup?.controls?.caregiverId.value),
                            acceptedFieldsCaregiver: getAcceptedFieldsCaregiver(
                                acceptedFieldsFormGroup,
                                caregiverFormGroup?.controls?.caregiverId.value
                            ),
                        };
                        as data
                    ) {
                        <!-- check if caregiver and acceptedFieldsCaregiver objects exists -->
                        @if (!!data.caregiver && !!data.acceptedFieldsCaregiver) {
                            <div class="o-grid">
                                <div class="o-grid__row center">
                                    <div class="o-grid__item">
                                        <mds-expansion-panel
                                            panelTitle="Caregiver Information"
                                            [description]="getCaregiverFullNameTemplate(data.caregiver)"
                                            [expanded]="
                                                editRequestFormGroup?.controls?.caregiverEditRequest?.controls?.length <
                                                3
                                            "
                                            [icon]="'supervisor_account'"
                                        >
                                            <div class="o-grid">
                                                <div class="o-grid__row center row-margin grid-margin">
                                                    <div class="o-grid__item">Field</div>
                                                    <div class="o-grid__item">Current value</div>
                                                    <div class="o-grid__item">Requested value</div>
                                                    <div class="o-grid__item checkbox-element">Accept Change</div>
                                                </div>
                                                <div
                                                    *ngIf="
                                                        showField(
                                                            data.caregiver.phone,
                                                            caregiverFormGroup?.defaultValue?.phone
                                                        )
                                                    "
                                                    class="o-grid__row center row-margin"
                                                >
                                                    <div class="o-grid__item text-value">Phone number</div>
                                                    <div class="o-grid__item text-value">
                                                        {{ data.caregiver.phone || 'Undefined' }}
                                                    </div>
                                                    <div class="o-grid__item form-field">
                                                        <mds-text-field
                                                            id="caregiver-{{ data.caregiver.id }}-phone"
                                                            label="Phone number"
                                                            [formCtrl]="caregiverFormGroup?.controls?.phone"
                                                            placeholder="Type here..."
                                                            appearance="outline"
                                                            floatLabel="always"
                                                        />
                                                    </div>
                                                    <div class="o-grid__item checkbox-element">
                                                        <mds-checkbox
                                                            [formCtrl]="data.acceptedFieldsCaregiver?.controls?.phone"
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    *ngIf="
                                                        showField(
                                                            data.caregiver?.preferredLang,
                                                            caregiverFormGroup?.defaultValue?.preferredLang
                                                        )
                                                    "
                                                    class="o-grid__row center row-margin"
                                                >
                                                    <div class="o-grid__item text-value">Preferred Language</div>
                                                    <div class="o-grid__item text-value">
                                                        {{ data.caregiver?.preferredLang || 'Undefined' }}
                                                    </div>
                                                    <div class="o-grid__item form-field">
                                                        <mds-text-field
                                                            id="caregiver-{{ data.caregiver.id }}-preferred-language"
                                                            label="Preferred Language"
                                                            [formCtrl]="caregiverFormGroup?.controls?.preferredLang"
                                                            placeholder="Type here..."
                                                            appearance="outline"
                                                            floatLabel="always"
                                                        />
                                                    </div>
                                                    <div class="o-grid__item checkbox-element">
                                                        <mds-checkbox
                                                            [formCtrl]="
                                                                data.acceptedFieldsCaregiver?.controls?.preferredLang
                                                            "
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    *ngIf="!!caregiverFormGroup?.defaultValue?.addressSameAsPatient"
                                                    class="o-grid__row center row-margin"
                                                >
                                                    <div class="o-grid__item text-value">
                                                        Address the same as patient
                                                    </div>
                                                    <div class="o-grid__item text-value">
                                                        {{ data.caregiver?.addressSameAsPatient }}
                                                    </div>
                                                    <div class="o-grid__item form-field">
                                                        <mds-checkbox
                                                            [formCtrl]="
                                                                caregiverFormGroup.controls.addressSameAsPatient
                                                            "
                                                            [label]="
                                                                caregiverFormGroup.controls.addressSameAsPatient.value
                                                                    ? 'true'
                                                                    : 'false'
                                                            "
                                                        />
                                                    </div>
                                                    <div class="o-grid__item checkbox-element">
                                                        <mds-checkbox
                                                            [formCtrl]="
                                                                data.acceptedFieldsCaregiver?.controls
                                                                    ?.addressSameAsPatient
                                                            "
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    *ngIf="
                                                        showField(
                                                            data.caregiver?.address1,
                                                            caregiverFormGroup?.defaultValue?.address1
                                                        )
                                                    "
                                                    class="o-grid__row center row-margin"
                                                >
                                                    <div class="o-grid__item text-value">Address Line 1</div>
                                                    <div class="o-grid__item text-value">
                                                        {{ data.caregiver?.address1 || 'Undefined' }}
                                                    </div>
                                                    <div class="o-grid__item form-field">
                                                        <mds-text-field
                                                            id="caregiver-{{ data.caregiver.id }}-address-1"
                                                            label="Address Line 1"
                                                            [formCtrl]="caregiverFormGroup?.controls?.address1"
                                                            placeholder="Type here..."
                                                            appearance="outline"
                                                            floatLabel="always"
                                                        />
                                                    </div>
                                                    <div class="o-grid__item checkbox-element">
                                                        <mds-checkbox
                                                            [formCtrl]="
                                                                data.acceptedFieldsCaregiver?.controls?.address1
                                                            "
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    *ngIf="
                                                        showField(
                                                            data.caregiver?.address2,
                                                            caregiverFormGroup?.defaultValue?.address2
                                                        )
                                                    "
                                                    class="o-grid__row center row-margin"
                                                >
                                                    <div class="o-grid__item text-value">Address Line 2</div>
                                                    <div class="o-grid__item text-value">
                                                        {{ data.caregiver?.address2 || 'Undefined' }}
                                                    </div>
                                                    <div class="o-grid__item form-field">
                                                        <mds-text-field
                                                            id="caregiver-{{ data.caregiver.id }}-address-2"
                                                            label="Address Line 2"
                                                            [formCtrl]="caregiverFormGroup?.controls?.address2"
                                                            placeholder="Type here..."
                                                            appearance="outline"
                                                            floatLabel="always"
                                                        />
                                                    </div>
                                                    <div class="o-grid__item checkbox-element">
                                                        <mds-checkbox
                                                            [formCtrl]="
                                                                data.acceptedFieldsCaregiver?.controls?.address2
                                                            "
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    *ngIf="
                                                        showField(
                                                            data.caregiver?.city,
                                                            caregiverFormGroup?.defaultValue?.city
                                                        )
                                                    "
                                                    class="o-grid__row center row-margin"
                                                >
                                                    <div class="o-grid__item text-value">City</div>
                                                    <div class="o-grid__item text-value">
                                                        {{ data.caregiver?.city || 'Undefined' }}
                                                    </div>
                                                    <div class="o-grid__item form-field">
                                                        <mds-text-field
                                                            id="caregiver-{{ data.caregiver.id }}-city"
                                                            label="City"
                                                            [formCtrl]="caregiverFormGroup?.controls?.city"
                                                            placeholder="Type here..."
                                                            appearance="outline"
                                                            floatLabel="always"
                                                        />
                                                    </div>
                                                    <div class="o-grid__item checkbox-element">
                                                        <mds-checkbox
                                                            [formCtrl]="data.acceptedFieldsCaregiver?.controls?.city"
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    *ngIf="
                                                        showField(
                                                            data.caregiver?.state,
                                                            caregiverFormGroup?.defaultValue?.state
                                                        )
                                                    "
                                                    class="o-grid__row center row-margin"
                                                >
                                                    <div class="o-grid__item text-value">Province / Region / State</div>
                                                    <div class="o-grid__item text-value">
                                                        {{ data.caregiver?.state || 'Undefined' }}
                                                    </div>
                                                    <div class="o-grid__item form-field">
                                                        <mds-text-field
                                                            id="caregiver-{{ data.caregiver.id }}-state"
                                                            label="Province / Region / State"
                                                            [formCtrl]="caregiverFormGroup?.controls?.state"
                                                            placeholder="Type here..."
                                                            appearance="outline"
                                                            floatLabel="always"
                                                        />
                                                    </div>
                                                    <div class="o-grid__item checkbox-element">
                                                        <mds-checkbox
                                                            [formCtrl]="data.acceptedFieldsCaregiver?.controls?.state"
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    *ngIf="
                                                        showField(
                                                            data.caregiver?.zipcode,
                                                            caregiverFormGroup?.defaultValue?.zipcode
                                                        )
                                                    "
                                                    class="o-grid__row center row-margin"
                                                >
                                                    <div class="o-grid__item text-value">Zipcode</div>
                                                    <div class="o-grid__item text-value">
                                                        {{ data.caregiver?.zipcode || 'Undefined' }}
                                                    </div>
                                                    <div class="o-grid__item form-field">
                                                        <mds-text-field
                                                            id="caregiver-{{ data.caregiver.id }}-zipcode"
                                                            label="Zipcode"
                                                            [formCtrl]="caregiverFormGroup?.controls?.zipcode"
                                                            placeholder="Type here..."
                                                            appearance="outline"
                                                            floatLabel="always"
                                                        />
                                                    </div>
                                                    <div class="o-grid__item checkbox-element">
                                                        <mds-checkbox
                                                            [formCtrl]="data.acceptedFieldsCaregiver?.controls?.zipcode"
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    *ngIf="
                                                        showField(
                                                            data.caregiver?.country,
                                                            caregiverFormGroup?.defaultValue?.country
                                                        )
                                                    "
                                                    class="o-grid__row center row-margin"
                                                >
                                                    <div class="o-grid__item text-value">Country</div>
                                                    <div class="o-grid__item text-value">
                                                        {{ data.caregiver?.country || 'Undefined' }}
                                                    </div>
                                                    <div class="o-grid__item form-field">
                                                        <mds-autocomplete
                                                            id="caregiver-{{ data.caregiver.id }}-country"
                                                            label="Country"
                                                            [formCtrl]="caregiverFormGroup?.controls?.country"
                                                            [options]="setup.countries"
                                                            placeholder="Type here..."
                                                            appearance="outline"
                                                            floatLabel="always"
                                                            [validateInput]="true"
                                                        />
                                                    </div>
                                                    <div class="o-grid__item checkbox-element">
                                                        <mds-checkbox
                                                            [formCtrl]="data.acceptedFieldsCaregiver?.controls?.country"
                                                        />
                                                    </div>
                                                </div>
                                                @if (setup.isTravelSupported) {
                                                    <div
                                                        class="o-grid__row center row-margin"
                                                        *ngIf="
                                                            caregiverFormGroup?.controls?.passportInfo.hasError(
                                                                'requiredAllValues'
                                                            )
                                                        "
                                                    >
                                                        <medpace-alert
                                                            class="alert"
                                                            [content]="'All passport fields must be inserted'"
                                                            [additionalClass]="'danger'"
                                                        ></medpace-alert>
                                                    </div>
                                                    <div
                                                        *ngIf="
                                                            showField(
                                                                data.caregiver.passportNum,
                                                                caregiverFormGroup?.defaultValue?.passportInfo
                                                                    ?.passportNum
                                                            )
                                                        "
                                                        class="o-grid__row center row-margin"
                                                    >
                                                        <div class="o-grid__item text-value">Passport Number</div>
                                                        <div class="o-grid__item text-value">
                                                            {{ data.caregiver.passportNum || 'Undefined' }}
                                                        </div>
                                                        <div class="o-grid__item form-field">
                                                            <mds-text-field
                                                                id="caregiver-{{ data.caregiver.id }}-passport-number"
                                                                label="Passport Number"
                                                                [formCtrl]="
                                                                    caregiverFormGroup?.controls?.passportInfo?.controls
                                                                        ?.passportNum
                                                                "
                                                                placeholder="Type here..."
                                                                appearance="outline"
                                                                floatLabel="always"
                                                            />
                                                        </div>
                                                        <div class="o-grid__item checkbox-element">
                                                            <mds-checkbox
                                                                [formCtrl]="
                                                                    data.acceptedFieldsCaregiver?.controls?.passportInfo
                                                                        .controls.passportNum
                                                                "
                                                            />
                                                        </div>
                                                    </div>
                                                    @if (
                                                        {
                                                            passportCountry:
                                                                getCountryNameFromCode(data.caregiver?.passportCountry)
                                                                | async,
                                                        };
                                                        as ps
                                                    ) {
                                                        <div
                                                            *ngIf="
                                                                showField(
                                                                    ps.passportCountry,
                                                                    caregiverFormGroup?.defaultValue?.passportInfo
                                                                        ?.passportCountry
                                                                )
                                                            "
                                                            class="o-grid__row center row-margin"
                                                        >
                                                            <div class="o-grid__item text-value">Passport Country</div>
                                                            <div class="o-grid__item text-value">
                                                                {{ ps.passportCountry || 'Undefined' }}
                                                            </div>
                                                            <div class="o-grid__item form-field">
                                                                <mds-autocomplete
                                                                    id="caregiver-{{
                                                                        data.caregiver.id
                                                                    }}-passport-country"
                                                                    label="Passport Country"
                                                                    [formCtrl]="
                                                                        caregiverFormGroup?.controls?.passportInfo
                                                                            .controls.passportCountry
                                                                    "
                                                                    placeholder="Type here..."
                                                                    appearance="outline"
                                                                    floatLabel="always"
                                                                    [options]="setup.countries"
                                                                    [validateInput]="true"
                                                                />
                                                            </div>
                                                            <div class="o-grid__item checkbox-element">
                                                                <mds-checkbox
                                                                    [formCtrl]="
                                                                        data.acceptedFieldsCaregiver?.controls
                                                                            ?.passportInfo.controls.passportCountry
                                                                    "
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                    <div
                                                        *ngIf="
                                                            showField(
                                                                data.caregiver?.passportIssue,
                                                                caregiverFormGroup?.defaultValue?.passportInfo
                                                                    ?.passportIssue
                                                            )
                                                        "
                                                        class="o-grid__row center row-margin"
                                                    >
                                                        <div class="o-grid__item text-value">Passport Issue Date</div>
                                                        <div class="o-grid__item text-value">
                                                            {{
                                                                (getFormattedDate(data.caregiver?.passportIssue)
                                                                    | uppercase) || 'Undefined'
                                                            }}
                                                        </div>
                                                        <div class="o-grid__item form-field">
                                                            <medpace-datepicker
                                                                id="caregiver-{{ data.caregiver.id }}-passport-issue"
                                                                label="Passport Issue Date"
                                                                [formCtrl]="
                                                                    caregiverFormGroup?.controls?.passportInfo?.controls
                                                                        ?.passportIssue
                                                                "
                                                                appearance="outline"
                                                                floatLabel="always"
                                                            />
                                                        </div>
                                                        <div class="o-grid__item checkbox-element">
                                                            <mds-checkbox
                                                                [formCtrl]="
                                                                    data.acceptedFieldsCaregiver?.controls?.passportInfo
                                                                        .controls.passportIssue
                                                                "
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        *ngIf="
                                                            showField(
                                                                data.caregiver.passportExpiration,
                                                                caregiverFormGroup?.defaultValue?.passportInfo
                                                                    ?.passportExpiration
                                                            )
                                                        "
                                                        class="o-grid__row center row-margin"
                                                    >
                                                        <div class="o-grid__item text-value">
                                                            Passport Expiration Date
                                                        </div>
                                                        <div class="o-grid__item text-value">
                                                            {{
                                                                (getFormattedDate(data.caregiver.passportExpiration)
                                                                    | uppercase) || 'Undefined'
                                                            }}
                                                        </div>
                                                        <div class="o-grid__item form-field">
                                                            <medpace-datepicker
                                                                id="caregiver-{{
                                                                    data.caregiver.id
                                                                }}-passport-expiration"
                                                                label="Passport Expiration Date"
                                                                [formCtrl]="
                                                                    caregiverFormGroup?.controls?.passportInfo.controls
                                                                        .passportExpiration
                                                                "
                                                                appearance="outline"
                                                                floatLabel="always"
                                                            />
                                                        </div>
                                                        <div class="o-grid__item checkbox-element">
                                                            <mds-checkbox
                                                                [formCtrl]="
                                                                    data.acceptedFieldsCaregiver?.controls?.passportInfo
                                                                        .controls.passportExpiration
                                                                "
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </mds-expansion-panel>
                                    </div>
                                </div>
                            </div>
                        }
                    }
                }
            }
        }
    </ng-container>
</ng-template>

<ng-template #loading>
    <div class="spinnerOverlay">
        <medpace-loading-card />
    </div>
</ng-template>
