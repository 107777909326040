import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { CaregiverEditRequestDto } from '../../caregiverEditRequestDto';
import { PatientEditRequestDto } from '../../patientEditRequestDto';
import { AcceptedFieldsCaregiverFormType, AcceptedFieldsPatientFormType } from './accepted-field-form-type';

export class AcceptedFieldFormGroup {
    static create(patientEditRequest: PatientEditRequestDto): AcceptedFieldsPatientFormType {
        //Caregiver FormArray
        const caregiversFormArray = new FormArray<AcceptedFieldsCaregiverFormType>([]);
        patientEditRequest?.caregiverEditRequests?.forEach((caregiverEditRequest: CaregiverEditRequestDto) => {
            caregiversFormArray.push(
                new FormGroup({
                    caregiverId: new FormControl<number | null>(caregiverEditRequest.caregiverId),
                    address1: new FormControl<boolean | null>(!!caregiverEditRequest?.address1),
                    address2: new FormControl<boolean | null>(!!caregiverEditRequest?.address2),
                    addressSameAsPatient: new FormControl<boolean | null>(
                        caregiverEditRequest?.addressSameAsPatient ?? false
                    ),
                    city: new FormControl<boolean | null>(!!caregiverEditRequest?.city),
                    country: new FormControl<boolean | null>(!!caregiverEditRequest?.country),
                    phone: new FormControl<boolean | null>(!!caregiverEditRequest?.phone),
                    preferredLang: new FormControl<boolean | null>(!!caregiverEditRequest?.preferredLang),
                    state: new FormControl<boolean | null>(!!caregiverEditRequest?.state),
                    zipcode: new FormControl<boolean | null>(!!caregiverEditRequest?.zipcode),
                    //international
                    passportInfo: new FormGroup({
                        passportCountry: new FormControl<boolean | null>(!!caregiverEditRequest?.passportCountry),
                        passportExpiration: new FormControl<boolean | null>(!!caregiverEditRequest?.passportExpiration),
                        passportIssue: new FormControl<boolean | null>(!!caregiverEditRequest?.passportIssue),
                        passportNum: new FormControl<boolean | null>(!!caregiverEditRequest?.passportNum),
                    }),
                })
            );
        });
        const patientFormGroup: AcceptedFieldsPatientFormType = new FormGroup({
            //patient
            address1: new FormControl<boolean | null>(!!patientEditRequest?.address1),
            address2: new FormControl<boolean | null>(!!patientEditRequest?.address2),
            city: new FormControl<boolean | null>(!!patientEditRequest?.city),
            country: new FormControl<boolean | null>(!!patientEditRequest?.country),
            phone: new FormControl<boolean | null>(!!patientEditRequest?.phone),
            preferredLang: new FormControl<boolean | null>(!!patientEditRequest?.preferredLang),
            state: new FormControl<boolean | null>(!!patientEditRequest?.state),
            zipcode: new FormControl<boolean | null>(!!patientEditRequest?.zipcode),
            //caregivers
            caregiverEditRequest: caregiversFormArray,
            //airline
            airlineEditRequest: new FormGroup({
                airlinePrimary: new FormControl<boolean | null>(!!patientEditRequest?.airlinePrimary),
                airlineRewardsPrimary: new FormControl<boolean | null>(!!patientEditRequest?.airlineRewardsPrimary),
                airlineRewardsSecondary: new FormControl<boolean | null>(!!patientEditRequest?.airlineRewardsSecondary),
                airlineSecondary: new FormControl<boolean | null>(!!patientEditRequest?.airlineSecondary),
                airlineSpecialNeeds: new FormControl<boolean | null>(!!patientEditRequest?.airlineSpecialNeeds),
                seatPreference: new FormControl<boolean | null>(!!patientEditRequest?.seatPreference),
                knownTravelerNum: new FormControl<boolean | null>(!!patientEditRequest?.knownTravelerNum),
            }),
            //train
            trainEditRequest: new FormGroup({
                trainSpecialNeeds: new FormControl<boolean | null>(!!patientEditRequest?.trainSpecialNeeds),
            }),
            //international
            passportInfo: new FormGroup({
                passportCountry: new FormControl<boolean | null>(!!patientEditRequest?.passportCountry),
                passportExpiration: new FormControl<boolean | null>(!!patientEditRequest?.passportExpiration),
                passportIssue: new FormControl<boolean | null>(!!patientEditRequest?.passportIssue),
                passportNum: new FormControl<boolean | null>(!!patientEditRequest?.passportNum),
            }),
            //lodging
            lodgingEditRequest: new FormGroup({
                hotelChainPrimary: new FormControl<boolean | null>(!!patientEditRequest?.hotelChainPrimary),
                lodgingRoomPreference: new FormControl<boolean | null>(!!patientEditRequest?.lodgingRoomPreference),
                allergies: new FormControl<boolean | null>(!!patientEditRequest?.allergies),
                lodgingSpecialNeeds: new FormControl<boolean | null>(!!patientEditRequest?.lodgingSpecialNeeds),
            }),

            //rental car
            rentalCarEditRequest: new FormGroup({
                rentalCarFrequentTravelerNum: new FormControl<boolean | null>(
                    !!patientEditRequest?.rentalCarFrequentTravelerNum
                ),
            }),
            //car service
            carServiceEditRequest: new FormGroup({
                groundSpecialNeeds: new FormControl<boolean | null>(!!patientEditRequest?.groundSpecialNeeds),
            }),
        });

        return patientFormGroup;
    }
}
