import {
    AbstractControl,
    AsyncValidatorFn,
    FormArray,
    FormControl,
    FormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { MdsOption } from '@medpacesoftwaredevelopment/designsystem/interfaces/mds-option';
import { PassportInfoFormGroupType } from '@models/form-models/patient-edit-request-form/patient-edit-request-form-type';
import { Caregiver, Patient } from '@models/patient';
import { UserService } from '@services/user/user.service';
import { Moment } from 'moment';
import { Observable, map, of, withLatestFrom } from 'rxjs';
import { MdsOptionGeneric } from './utility';

export const passwordValidatorPattern: string =
    '^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[\\*\\.\\!\\@\\$\\%\\^\\&\\(\\)\\{\\}\\[\\]\\:;\\<\\>,\\.\\?\\/\\~_\\+\\-\\=\\|]).{8,256}$';

export const noSpacesValidatorPattern: string = '^[^\\s]*$';

export const matToolTipTextPassword: string =
    'Password must contain at least one uppercase letter, one lowercase letter, one digit, one special character(?=.*[*.!@$%^&(){}[]:;<>,.?/~_+-=|]), and must be between 8 to 256 characters in length.';

export const validationMessages = {
    notValidDate: 'Specify valid date!',
    invalidPastDate: 'Past dates are not allowed',
    invalidFutureDate: 'Future dates are not allowed',
    required: 'Required!',
    countryName: 'Invalid country name',
};

export function isValidCountry(array: MdsOption[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        if (array.some((x) => x.value === control.value?.value)) {
            return null;
        } else {
            return { inArray: validationMessages.countryName };
        }
    };
}

export const zeroValidator: ValidatorFn = Validators.min(0.001);

export const NumberFormatValidator: ValidatorFn = Validators.pattern('[0-9]+');

export const phoneNumberFormatValidator: ValidatorFn = Validators.pattern(
    '^(([+][0-9]{1,3}[ ]?)|([(][+][0-9]{1,3}[)][ ]?))?((([0-9]{2,4}[ ]?){2,4})|(([0-9]{2,4}[-]?){2,4}))[0-9]{2,4}$'
);

// Workaround needed until MDS fixes rendering of more than 1 validation message.
// Ultimately, we should be able to use two existing validators: phoneNumberFormatValidator and maxLengthValidator, and not need that one.
export function phoneNumberFormatValidatorWithMaxLength(maxLength: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const phoneNumberFormatValidationResult = phoneNumberFormatValidator(control);
        const lengthValidationResult = Validators.maxLength(maxLength)(control);

        if (phoneNumberFormatValidationResult || lengthValidationResult)
            return {
                invalidPhoneNumber: `Value must be a valid phone number, no longer than ${maxLength} characters.`,
            };
    };
}

export const zipCodeFormatValidator: ValidatorFn = Validators.maxLength(10);

export function alreadyInArrayValidator(array: any[], message: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        if (typeof control.value === 'object' && isEqualObjectInArray(array, control.value)) {
            return { inArray: message }; // Value is in the array
        } else if (array.indexOf(control.value) !== -1) {
            return { inArray: message }; // Value is in the array
        } else {
            return null; // Value is not in the array
        }
    };
}

export function passportValidatorPatient(patient: Patient): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!!control) {
            const typedControl = control as PassportInfoFormGroupType;
            const values = Object.values(typedControl.value);
            const someFieldHasValue = values.find((value) => value !== '' && value !== null) || false;

            const countryHasValue =
                !!typedControl?.value?.passportCountry ||
                !!patient?.travelPreferences?.internationaltravel?.passportCountry;
            const numberHasValue =
                !!typedControl?.value?.passportNum || !!patient?.travelPreferences?.internationaltravel?.passportNum;
            const issueHasValue =
                !!typedControl?.value?.passportIssue ||
                !!patient?.travelPreferences?.internationaltravel?.passportIssue;
            const expirationHasValue =
                !!typedControl?.value?.passportExpiration ||
                !!patient?.travelPreferences?.internationaltravel?.passportExpiration;

            const allFieldsHaveValues = countryHasValue && numberHasValue && issueHasValue && expirationHasValue;
            if (someFieldHasValue && !allFieldsHaveValues) {
                return { requiredAllValues: true };
            }
        }
        return null;
    };
}

export function passportValidatorCaregiver(caregiver: Caregiver): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!!control) {
            const typedControl = control as PassportInfoFormGroupType;
            const values = Object.values(typedControl.value);
            const someFieldHasValue = values.find((value) => value !== '' && value !== null) || false;

            const countryHasValue = !!typedControl?.value?.passportCountry || !!caregiver?.passportCountry;
            const numberHasValue = !!typedControl?.value?.passportNum || !!caregiver?.passportNum;
            const issueHasValue = !!typedControl?.value?.passportIssue || !!caregiver?.passportIssue;
            const expirationHasValue = !!typedControl?.value?.passportExpiration || !!caregiver?.passportExpiration;

            const allFieldsHaveValues = countryHasValue && numberHasValue && issueHasValue && expirationHasValue;
            if (someFieldHasValue && !allFieldsHaveValues) {
                return { requiredAllValues: true };
            }
        }
        return null;
    };
}

/**
 * Validator that checks if there are multiple controls with the same value in formGroup
 * @param formGroup FormGroup that is validated
 * @param message message that will be shown in frontend
 * @returns object { inArray: message } if there are multiple FormControls with the same value, else null
 */
export function duplicateInFormGroupValidator(formGroup: FormGroup, message: string): ValidatorFn {
    return (control: AbstractControl<string>): { [key: string]: any } | null => {
        formGroup.updateValueAndValidity({ emitEvent: false });
        const array: string[] = Object.values(formGroup.value);
        if (array.length > 0 && !!control.value) {
            const isDuplicate =
                array.filter((option) => option?.toLowerCase() === control.value?.toLowerCase()).length > 1;
            //if array has duplicate, return validation error
            if (isDuplicate) {
                control.markAsTouched();
                return { inArray: message }; // Value is duplicated in an array
            } else return null;
        } else return null;
    };
}

/**
 * Validator that checks if there are multiple controls with the same value in formGroup
 * @param formGroup FormGroup that is validated
 * @param message message that will be shown in frontend
 * @returns object { inArray: message } if there are multiple FormControls with the same value, else null
 */
export function duplicateFormGroupInFormGroupValidator(formGroup: FormGroup, message: string): ValidatorFn {
    return (control: AbstractControl<{ name: string; scheduled: boolean }>): { [key: string]: any } | null => {
        formGroup.updateValueAndValidity({ emitEvent: false });

        const array: { name: string; scheduled: boolean }[] = Object.values(formGroup.value);

        const value: { name: string; scheduled: boolean } = control.value;
        if (array.length > 0 && !!value) {
            const isDuplicate =
                array.filter((option) => option?.name?.toLowerCase() === value?.name?.toLowerCase()).length > 1;
            //if array has duplicate, return validation error
            if (isDuplicate) {
                control.get('name').setErrors({ inArray: message });
                control.get('name').markAllAsTouched();
                return { inArray: message };
            } else {
                control.get('name').updateValueAndValidity({ onlySelf: true });
                return null;
            }
        } else return null;
    };
}

export function NumberFormatValidatorWithMaxLength(maxLength: number): ValidatorFn {
    return (control: AbstractControl<string>): ValidationErrors | null => {
        const regex = new RegExp('^[0-9]+$');

        if (control.value === undefined || control.value?.length == 0) {
            return null;
        } else if (control.value?.length > maxLength && !regex.test(control.value)) {
            return {
                onlyDigitAndDeclaredLength: `Value length must be less than or equal to ${maxLength} and must contain only digits.`,
            };
        } else if (control.value?.length > maxLength) {
            return { onlyDigitAndDeclaredLength: `Value length must be less than or equal to ${maxLength}.` };
        } else if (!regex.test(control.value)) {
            return { onlyDigitAndDeclaredLength: `Value must contain only digits.` };
        } else {
            return null;
        }
    };
}
// MDS seems to have hardcoded validation for Validators.min only, while result of Validators.max displays as '[Object object]' instead of actual validation message
// This function is a workaround that only modifies the shape of returned result to be more flat
export function maxValidator(max: number): ValidatorFn {
    return (control: AbstractControl<number>): ValidationErrors | null => {
        const result = Validators.max(max)(control);
        if (result) {
            return { max: `Maximum amount should be ${max.toLocaleString()}` };
        }
    };
}

// Validator similar to Angular's built-in Validators.maxLength, but with the added value of a human-readable error message required by MDS controls
export function maxLengthValidator(maxLength: number): ValidatorFn {
    return (control: AbstractControl<string>): ValidationErrors | null => {
        const maxLengthValidationResult = Validators.maxLength(maxLength)(control);
        if (maxLengthValidationResult) {
            return { maxLengthExceeded: `Value must be at most ${maxLength} characters long.` };
        }
    };
}

export function minLengthValidator(minLength: number): ValidatorFn {
    return (control: AbstractControl<string>): ValidationErrors | null => {
        const minLengthValidationResult = Validators.minLength(minLength)(control);
        if (minLengthValidationResult) {
            return { minLengthExceeded: `Value must be at least ${minLength} characters long.` };
        }
    };
}

/** A wrapper over @angular/forms/Validators.email, extended with a clear error message, to make it compatible with MDS controls */
export function emailValidator(control: AbstractControl<string>): ValidationErrors | null {
    // Validators.email is disallowed in the project, but here we intentionally want to use it so we can create an MDS-compatible wrapper
    // eslint-disable-next-line no-restricted-syntax
    const emailFormatValidationResult = Validators.email(control);

    if (emailFormatValidationResult) {
        return { invalidEmailFormat: `Invalid email address format.` };
    }
}

export function inArrayValidator(array: any[], message: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        if (typeof control.value === 'object' && isEqualObjectInArray(array, control.value)) {
            return null; // Value is in the array
        } else if (array.indexOf(control.value) !== -1) {
            return null; // Value is in the array
        } else {
            return { inArray: message }; // Value is not in the array
        }
    };
}

export function inArrayAsyncValidator<T>(
    array$: Observable<MdsOptionGeneric<T>[]>,
    errorMessage: string
): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
        return of({}).pipe(
            withLatestFrom(array$.pipe(map((array) => array.map((value) => value.viewValue)))),
            map((result) => {
                if (!!control.value && typeof control.value === 'string' && !result[1].includes(control.value)) {
                    return { optionInArray: errorMessage };
                } else {
                    return null;
                }
            })
        );
    };
}
export function differentCRCsValidator(group: FormGroup, controlName: string, message: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        for (const name in group.controls) {
            if (name !== controlName) {
                const otherControl = group.get(name);
                if (otherControl && otherControl.value.value && otherControl.value.value === control.value.value) {
                    if (!otherControl.hasError('identicalValues')) return { identicalValues: message };
                }
            }
        }
        return null;
    };
}

export function lessThanTodayDateValidator(control: AbstractControl): null | { notValidDate: string } {
    const value = control.value;
    return new Date(value) < new Date() ? null : { notValidDate: validationMessages.invalidFutureDate };
}

export function moreThanTodayDateValidator(control: AbstractControl): null | { notValidDate: string } {
    const value = control.value;
    if (!value) return null;
    return new Date(value) > new Date() ? null : { notValidDate: validationMessages.invalidPastDate };
}

export function equalOrMoreThanTodayOnlyDateValidator(control: AbstractControl): null | { notValidDate: string } {
    const value = control.value;
    if (!value) return null;
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    return new Date(value) >= currentDate ? null : { notValidDate: validationMessages.invalidPastDate };
}
export function equalOrMoreThanTodayOnlyIfCRCDateValidator(control: AbstractControl): ValidationErrors | null {
    const user = UserService.staticUser;
    if (!!user && (user.isAdmin || user.isSuperAdmin)) return null;
    else return equalOrMoreThanTodayOnlyDateValidator(control);
}

export function beforeDateValidator(maxdate: Date, message: string): ValidatorFn {
    return (control: AbstractControl): null | { notValidDate: string } => {
        const value = control.value;
        if (new Date(value) <= new Date(maxdate)) return null;
        return { notValidDate: message };
    };
}

export function sameStringValidator(compareString: string, message: string): ValidatorFn {
    return (control: AbstractControl): null | { notSameString: string } => {
        const value = control.value;
        if (value === compareString) return null;
        return { notSameString: message };
    };
}

export function beforeTimeValidator(
    startDate: FormControl<Moment>,
    endDate: FormControl<Moment>,
    startTime: AbstractControl,
    endTime: AbstractControl,
    message: string
): ValidatorFn {
    return (control: AbstractControl): null | { notValidTime: string } => {
        if (!startDate.value || !endDate.value || !endTime.value || !startTime.value) return null;
        if (startDate.value.isSame(endDate.value, 'day')) {
            //if dates are equal, then start time should be earlier than end time
            //time string as HH:MM am/pm, need to change to 24 hour based time
            const startValue = convertToHour(startTime.value);
            const endValue = convertToHour(endTime.value);
            if (startValue < endValue) {
                startTime.setErrors(null);
                endTime.setErrors(null);
                return null;
            } else {
                startTime.setErrors({ notValidTime: message });
                endTime.setErrors({ notValidTime: message });

                startTime.markAsTouched();
                endTime.markAsTouched();

                return { notValidTime: message };
            }
        } else {
            startTime.setErrors(null);
            endTime.setErrors(null);
            return null;
        }
    };
}

export function requiredValidatorCustomMessage(message?: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!!message) {
            const validationResult = Validators.required(control);
            if (!!validationResult) {
                return { required: message };
            }
        } else return Validators.required(control);
        return null;
    };
}

export function isEqualObjectInArray<T extends {}>(array: T[], obj: T): boolean {
    let flag: boolean = false;
    array.forEach((arrelement) => {
        if (objectsEqual(obj, arrelement)) {
            flag = true;
        }
    });
    return flag;
}

export function objectsEqual<T extends {}>(obj1: T, obj2: T): boolean {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (let key of keys1) {
        if (obj1[key] !== obj2[key]) {
            return false;
        }
    }

    return true;
}

export function convertToHour(time: string): number {
    // Split the time input into hours, minutes and part (AM/PM)
    const [hourPart, minutePart, part] = time.split(/[:\s]/);
    let hours = parseInt(hourPart);

    if (hours && !isNaN(+minutePart) && !part) return hours;
    if (typeof part === 'string') {
        let partLowerCase = part.toLowerCase();

        // Adjust hours based on the part
        if (partLowerCase === 'pm' && hours < 12) {
            hours += 12;
        } else if (partLowerCase === 'am' && hours === 12) {
            hours = 0;
        }

        return hours;
    }
}

export function requiredAtLeastOneOption(formGroup: FormGroup): ValidationErrors | null {
    if (!!formGroup) {
        //count elements in formGroup
        const keys = Object.keys(formGroup.value);
        return keys.length === 0 ? <ValidationErrors>{ required: 'At least one option is required' } : null;
    }
    return null;
}

export function requiredAtLeastOneOptionArray(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const formArray = control as FormArray;
        if (!!formArray) {
            //count elements in formArray
            return formArray.length === 0 ? <ValidationErrors>{ required: 'At least one option is required' } : null;
        }
        return null;
    };
}

export function invalidValue(fallbackValue: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.value && fallbackValue.length > 0) {
            return { invalid: 'Invalid format, expected DD-MON-YYYY' };
        }
        return null;
    };
}
