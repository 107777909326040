import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MdsOption } from '@medpacesoftwaredevelopment/designsystem/interfaces/mds-option';
import { ActionModel } from '@models/actionModel';
import { Site } from '@models/site';
import { Observable, ReplaySubject, map, take } from 'rxjs';

@Component({
    selector: 'medpace-site-change-status-modal',
    templateUrl: './site-change-status-modal.component.html',
})
export class MedpaceSiteChangeStatusModalComponent {
    textStatus: string;
    displayStatus: MdsOption[] = [];
    siteStatusChangeFormGroup = new FormGroup({
        statusDropdownControl: new FormControl<string>('', Validators.required),
    });

    statusText$ = this.data.siteSubject.pipe(
        take(1),
        map((site) => site.status.statusName)
    );

    availableActions$ = this.data.actionsObservable.pipe(
        map((actions) => {
            return actions.map((action) => {
                return <MdsOption>{ value: action.name, viewValue: action.name };
            });
        }),
        take(1)
    );

    constructor(
        public dialogRef: MatDialogRef<MedpaceSiteChangeStatusModalComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: { actionsObservable: Observable<ActionModel[]>; siteSubject: ReplaySubject<Site> }
    ) {}

    closeDialog(): void {
        this.dialogRef.close();
    }

    saveDialog(): void {
        if (this.siteStatusChangeFormGroup.valid) {
            this.dialogRef.close(this.siteStatusChangeFormGroup?.controls.statusDropdownControl?.value);
        }
    }
}
