import { Moment } from 'moment';
import { AddressDataModel } from './address';
import { BankDetails } from './bankDetails';

export interface Patient {
    travelPreferences: PatientTravelPreferences;
    id: number;
    statusId: number;
    statusName: string;
    siteId: number;
    siteNumber: string;
    studyId: number;
    protocol: string;
    studyCode: string;
    sponsor: string;
    region: string;
    country: string;
    hasConsent: boolean;
    preferredPayment: string;
    preferredCurrency: string;
    patientIdentification: PatientIdentification;
    caregivers: Caregiver[];
    accessibilityRequirements: PatientAccessibilityRequirements;
    readyForPayment: boolean;
    paymentApprover: string;
    paymentApprovalDate: Moment;
    paymentRecipientRole: string;
    bankDetails: BankDetails;
}

export class Patient {}

export interface StudyPatientViewModel {
    id: number;
    siteId: number;
    studyId: number;
    patientNum: string;
    patientPseudoId: string;
    firstName: string;
    lastName: string;
    lastVisitDate: Date;
    isPatientDeleted: boolean;
}

export interface SitePatientsViewModel {
    id: number;
    patientNum: string;
    patientPseudoId: string;
    siteId: number;
    firstName: string;
    lastName: string;
    statusName: string;
    statusId: number;
    lastVisit: Date;
    visitIds: number[];
    isPatientDeleted: boolean;
}

export interface PatientRequestSummary {
    patientId: number;
    pcsId: string;
    travelRequestCount: number;
    reimbursementRequestCount: number;
    stipendRequestCount: number;
    lastVisitDate: Date;
}

export function createNewPatient() {
    return <Patient>{
        travelPreferences: createNewTravelPreferences(),
        id: undefined,
        statusId: undefined,
        hasConsent: undefined,
        preferredPayment: undefined,
        preferredCurrency: undefined,
        patientIdentification: <PatientIdentification>{
            dataId: undefined,
            firstName: undefined,
            middleName: undefined,
            lastName: undefined,
            patientId: undefined,
            birthDate: undefined,
            address1: undefined,
            address2: undefined,
            city: undefined,
            state: undefined,
            country: undefined,
            zipcode: undefined,
            under18: undefined,
            phoneNumber: undefined,
            emailAddress: undefined,
            preferredLanguage: undefined,
            gender: undefined,
            isMinor: undefined,
            KYCDocument: undefined,
            KYCDocumentInfoId: undefined,
            KYCDocumentName: undefined,
            KYCVendorId: undefined,
            visitScheduleName: undefined,
        },
        accessibilityRequirements: <PatientAccessibilityRequirements>{
            walker: false,
            wheelchair: false,
            ramp: false,
            other: false,
            additionalRequirements: undefined,
        },
        readyForPayment: undefined,
        paymentApprover: undefined,
        paymentApprovalDate: undefined,
        paymentRecipientRole: undefined,
    };
}

export interface PatientIdentification {
    dataId: number;
    firstName: string;
    middleName: string;
    lastName: string;
    patientId: string;
    birthDate: string;
    address1: string;
    address2: string;
    country: string;
    city: string;
    state: string;
    zipcode: string;
    under18: boolean;
    phoneNumber: string;
    emailAddress: string;
    preferredLanguage: string;
    gender: string;
    isMinor: boolean;
    KYCDocument: string;
    KYCDocumentInfoId?: number;
    KYCDocumentName: string;
    KYCVendorId: string;
    visitScheduleName: string;
}

export class PatientIdentification {}

export class Caregiver {
    id: number = 0;
    patientId: number;
    isPrimary: boolean = false;
    firstName: string = '';
    middleName: string = '';
    lastName: string = '';
    phone: string = '';
    email: string = '';
    relationship: string = '';
    preferredLang: string = '';
    gender: string = '';
    birthDate: string = '';
    addressSameAsPatient: boolean = false;
    details: string = '';
    address1: string = '';
    address2: string = '';
    city: string = '';
    state: string = '';
    zipcode: string = '';
    country: string = '';
    passportNum: string = '';
    passportExpiration: string = '';
    passportIssue: string = '';
    passportCountry: string = '';
    isDeleted: boolean = false;
    isChecked: boolean = false;
}
export function getCaregiverFullName(caregiver: Caregiver) {
    return !caregiver ? '' : getFullName(caregiver.firstName, caregiver.middleName, caregiver.lastName);
}
export function getFullName(...params: string[]) {
    return params.filter((name) => !!name).join(' ');
}
export class CaregiverDTO {
    createdTimeStamp: Date;
    firstName: string;
    middleName: string;
    gender: string;
    id: number;
    isPrimary: boolean;
    lastName: string;
    patientId: number;
    relationship: string;
}

function createNewTravelPreferences(): PatientTravelPreferences {
    return <PatientTravelPreferences>{
        airtravel: {
            needsAirTravel: undefined,
            airlinePreference1: undefined,
            frequentFlyer1: undefined,
            airlinePreference2: undefined,
            frequentFlyer2: undefined,
            seatPreference: undefined,
            airlineSpecialNeeds: undefined,
            knownTravelerNumber: undefined,
        },
        traintravel: <PatientTrainPreferences>{
            needsTrainTravel: undefined,
            specialNeeds: '',
        },
        internationaltravel: <PatientInternationalPreferences>{
            needsInternationalTravel: undefined,
            internationalTraveler: undefined,
            passportNum: undefined,
            passportExpiration: undefined,
            passportIssue: undefined,
            passportCountry: undefined,
        },
        lodging: <PatientLodgingPreferences>{
            needsLodging: undefined,
            hotelBrandPreference: undefined,
            lodgingRoomPreference: undefined,
            specialRequirements: undefined,
            allergies: undefined,
        },
        groundTransportation: <PatientTransportationPreferences>{
            needsGroundTransportation: undefined,
            groundSpecialNeeds: undefined,
        },
        rentalCar: <PatientRentalCarPreferences>{
            needsRentalCar: undefined,
            rentalCarFrequentTravelerNumber: undefined,
        },
    };
}

export interface PatientTravelPreferences {
    airtravel: PatientAirlinePreferences;
    traintravel: PatientTrainPreferences;
    internationaltravel: PatientInternationalPreferences;
    lodging: PatientLodgingPreferences;
    groundTransportation: PatientTransportationPreferences;
    rentalCar: PatientRentalCarPreferences;
}

export interface PatientAirlinePreferences {
    needsAirTravel: boolean;
    airlinePreference1: string;
    frequentFlyer1: string;
    airlinePreference2: string;
    frequentFlyer2: string;
    seatPreference: string;
    airlineSpecialNeeds: string;
    knownTravelerNumber: string;
}

export interface PatientTrainPreferences {
    needsTrainTravel: boolean;
    specialNeeds: string;
}

export interface PatientInternationalPreferences {
    needsInternationalTravel: boolean;
    internationalTraveler: string;
    passportNum: string;
    passportExpiration: Moment;
    passportIssue: Moment;
    passportCountry: string;
}

export interface PatientLodgingPreferences {
    needsLodging: boolean;
    lodgingRoomPreference: string;
    hotelBrandPreference: string;
    specialRequirements: string;
    allergies: string;
}

export interface PatientTransportationPreferences {
    needsGroundTransportation: boolean;
    groundSpecialNeeds: string;
}

export interface PatientRentalCarPreferences {
    needsRentalCar: boolean;
    rentalCarFrequentTravelerNumber: string;
}

export interface PatientAccessibilityRequirements {
    wheelchair: boolean;
    walker: boolean;
    ramp: boolean;
    other: boolean;
    additionalRequirements: string;
}

export interface PatientDataModel {
    id: number;
    siteId: number;
    siteNumber: string;
    studyId: number;
    protocol: string;
    code: string;
    sponsor: string;
    region: string;
    country: string;
    patientDatum: PatientDataDataModel;
    caregivers: Caregiver[];
    patientNum: string;
    statusId: number;
    statusName: string;
    airTravelRequired: boolean;
    trainRequired: boolean;
    trainSpecialNeeds: string;
    groundTransportRequired: boolean;
    groundSpecialNeeds: string;
    rentalCarRequired: boolean;
    internationTravelRequired: boolean;
    internationalTraveler: string;
    lodgingRequired: boolean;
    airlinePrimary: string;
    airlineSecondary: string;
    hotelChainPrimary: string;
    seatPreference: string;
    allergies: string;
    knownTravelerNum: string;
    preferredPayment: string;
    preferredCurrency: string;
    accessWalker: boolean;
    accessWheelChair: boolean;
    accessRamp: boolean;
    accessibilityRequirements: string;
    readyForPayment: boolean;
    paymentApprover: string;
    paymentApprovalDate: Date | null;
    paymentRecipientRole: string;
    bankDetails: BankDetails;
}

export interface PatientDataDataModel {
    id: number;
    patientId: number;
    firstName: string;
    middleName: string;
    lastName: string;
    birthDate: string;
    gender: string;
    preferredLang: string;
    address1: string;
    address2: string;
    country: string;
    city: string;
    state: string;
    zipcode: string;
    phone: string;
    email: string;
    underEighteen: boolean;
    hasConsentForm: boolean;
    airlineRewardsPrimary: string;
    airlineRewardsSecondary: string;
    airlineSpecialNeeds: string;
    knownTravelerNum: string;
    passportNum: string;
    passportIssue: string;
    passportCountry: string;
    passportExpiration: string;
    lodgingSpecialNeeds: string;
    lodgingRoomPreference: string;
    groundSpecialNeeds: string;
    trainSpecialNeeds: string;
    rentalCarFrequentTravelerNum: string;
    kycdocument: string;
    kycDocumentInfoId?: number;
    kycdocumentName: string;
    kycvendorId: string;
    visitScheduleName: string;
}

export interface PatientDetailsDataModel {
    id: number;
    siteId: number;
    studyId: number;
    status: number;
    statusName: string;
    patientNum: string;

    /** Study protocol (a long identifier of a study) */
    protocol: string;

    /** Study code = study number (a short identifier of a study) */
    code: string;

    patientAddress: AddressDataModel;
    firstName: string;
    lastName: string;
    sponsor: string;
    siteNum: string;
    region: string;
    country: string;
    lastVisitDate: string;
}

export interface PatientDetailsViewModel {
    id: number;
    siteId: number;
    studyId: number;
    statusId: number;
    statusName: string;
    patientNum: string;
    pcsId: string;
    protocol: string;
    code: string;
    firstName: string;
    lastName: string;
    sponsor: string;
    siteNum: string;
    region: string;
    country: string;
    patientAddress: string;
    lastVisitDate: string;
    isPatientDeleted: boolean;
}

export interface PatchPatientDataModel {
    id: number;
    siteId: number;
    airTravelRequired: boolean;
    trainRequired: boolean;
    internationTravelRequired: boolean;
    internationalTraveler: string;
    lodgingRequired: boolean;
    groundTransportRequired: boolean;
    rentalCarRequired: boolean;
    airlinePrimary: string;
    airlineSecondary: string;
    hotelChainPrimary: string;
    seatPreference: string;
    allergies: string;
    patientDatum: PatchPatientDatumDataModel;
}

export interface PatchPatientDatumDataModel {
    airlineRewardsPrimary: string;
    airlineRewardsSecondary: string;
    airlineSpecialNeeds: string;
    knownTravelerNum: string;
    passportNum: string;
    passportIssue: string;
    passportCountry: string;
    passportExpiration: string;
    lodgingSpecialNeeds: string;
    lodgingRoomPreference: string;
    groundSpecialNeeds: string;
    trainSpecialNeeds: string;
    rentalCarFrequentTravelerNum: string;
}
