<div class="o-page">
    <div class="o-grid__row">
        <div class="o-grid__item">
            <h3>Site Overview</h3>
        </div>
        <div class="o-grid__item" *ngIf="isAddPatientButtonVisible()">
            <div class="flex end">
                <mds-button
                    [id]="'addPatientBtn'"
                    variant="primary"
                    label="Add Patient"
                    (clickEvent)="addNewPatient()"
                ></mds-button>
            </div>
        </div>
    </div>
    <div class="o-grid__row">
        <div class="o-grid__item">
            <medpace-info-card
                *ngIf="site; else spinner"
                [isAdmin]="isAdmin"
                [data]="site"
                type="site"
                inputCTA="ADDITIONAL SITE DETAIL"
                [contentHeaders]="infoHeaderColumns"
                [contentMapping]="infoColumnMap"
                [additionalContent]="primaryCRC"
                (navButtonClick)="onAdditionalSiteDetailsClick()"
            ></medpace-info-card>
        </div>
    </div>
    <div class="o-grid__row">
        <div class="o-grid__item">
            <mds-expansion-panel
                [panelTitle]="'Site contacts'"
                [description]="''"
                icon="account_circle"
                [attr.data-cy]="'siteCrcsExpansion'"
            >
                <div *ngIf="site; else spinner" class="c-card__content" style="margin-top: 10px">
                    <div *ngFor="let crc of this.site?.siteCRCs" class="o-grid__row">
                        <div
                            class="o-grid__item"
                            [ngStyle]="crc.isPrimary ? { 'font-weight': 'bold' } : { 'font-weight': 'normal' }"
                        >
                            {{ crc.user.firstName }} {{ crc.user.lastName }}
                        </div>
                        <div
                            class="o-grid__item"
                            [ngStyle]="crc.isPrimary ? { 'font-weight': 'bold' } : { 'font-weight': 'normal' }"
                        >
                            {{ crc.user.emailAddress }}
                        </div>
                    </div>
                </div>
            </mds-expansion-panel>
        </div>
    </div>
    <div class="o-grid__row">
        <div class="o-grid__item"></div>
    </div>
    <div class="o-grid__row">
        <div class="o-grid__item">
            <medpace-table
                *ngIf="site; else spinner"
                [data]="patients"
                [columns]="tableColumnHeaders"
                isDeletedPropertyName="isPatientDeleted"
                title="Patients"
                [fieldMap]="tableColumnMap"
                searchPlaceholder="Visit Type"
                [filterOptions]="visitTypes"
                [ifFiltering]="true"
                [isSearching]="true"
                (rowClickEvent)="doClickRow($event)"
            ></medpace-table>
        </div>
    </div>
</div>
<ng-template #spinner>
    <div class="spinnerOverlay">
        <medpace-loading-card></medpace-loading-card>
    </div>
</ng-template>
