import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SiteStatusEnum } from '@app/enums/site-status.enum';
import { dateFormatPipe } from '@app/pipes/datepipe';
import { ContentMapping } from '@models/event-objects/content-mapping';
import { SitePatientsViewModel } from '@models/patient';
import { Site } from '@models/site';
import { AdminSiteServices } from '@services/admin/admin-site.service';
import { AdminStudyServices } from '@services/admin/admin-study.sevice';
import { GroupService } from '@services/group/group.service';
import { Subject, forkJoin, takeUntil } from 'rxjs';

interface StudyPatientTableElement {
    id: number;
    firstName: string;
    lastName: string;
    patientNum: string;
    pcsId: string;
    statusName: string;
    statusId: number;
    visitIds: string;
    isPatientDeleted: boolean;
}

@Component({
    selector: 'medpace-site-overview',
    templateUrl: './site-overview.component.html',
    styleUrls: ['./site-overview.component.scss'],
})
export class MedpaceSiteOverviewComponent implements OnInit, OnDestroy {
    site: Site;
    primaryCRC: string;
    visitTypes: any[];
    isAdmin: boolean;

    patients: any[];
    private componentDestroyed$: Subject<boolean> = new Subject();

    infoHeaderColumns: string[] = ['Site #', 'Institution Name', 'PI Last Name', 'Sponsor', 'Protocol'];
    infoColumnMap: ContentMapping[] = [
        { name: 'Site #', field: 'siteNumber', isNested: true, nest: 'info' },
        { name: 'Sponsor', field: 'sponsor', isNested: false, nest: undefined },
        { name: 'Protocol', field: 'protocol', isNested: false, nest: undefined },
        { name: 'Institution Name', field: 'name', isNested: true, nest: 'info' },
        { name: 'PI Last Name', field: 'pilastName', isNested: true, nest: 'info' },
    ];
    tableColumnHeaders: string[] = ['statusName', 'patientNum', 'pcsId', 'firstName', 'lastName', 'lastVisitDate'];
    tableColumnMap: any[] = [
        { header: 'Status', name: 'statusName' },
        { header: 'Patient ID', name: 'patientNum' },
        { header: 'PCS ID', name: 'pcsId' },
        { header: 'First Name', name: 'firstName' },
        { header: 'Last Name', name: 'lastName' },
        { header: 'Last Visit Date', name: 'lastVisitDate' },
    ];

    constructor(
        private router: Router,
        private activeRoute: ActivatedRoute,
        private siteServices: AdminSiteServices,
        private studyServices: AdminStudyServices,
        private groups: GroupService,
        private datePipe: dateFormatPipe
    ) {}

    ngOnInit(): void {
        this.patients = [];
        this.visitTypes = [];

        let crcs = this.siteServices.getSiteCRCs(this.activeRoute.snapshot.params.siteId);
        let sitePatients = this.siteServices.getSitePatients(this.activeRoute.snapshot.params.siteId);
        let visits = this.studyServices.getStudyVisitTypes(this.activeRoute.snapshot.params.id);
        let siteObj = this.siteServices.getSite(this.activeRoute.snapshot.params.siteId);
        let study = this.studyServices.getStudy(this.activeRoute.snapshot.params.id);

        this.groups
            .getGroupsFromStorage()
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe((result) => {
                this.isAdmin = this.groups.isUserAdmin(result);
            });

        forkJoin([siteObj, visits, sitePatients, crcs, study])
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe((results) => {
                this.site = results[0];
                let temparray = [];
                this.site.patients = results[2];
                const study = results[4];
                this.site['sponsor'] = study.sponsor;
                this.site['protocol'] = study.protocol;

                this.siteServices
                    .getSitePatientRequestSummaries(this.site.id)
                    .pipe(takeUntil(this.componentDestroyed$))
                    .subscribe((counts) => {
                        results[2].forEach((r: SitePatientsViewModel) => {
                            const p: StudyPatientTableElement = {
                                patientNum: r.patientNum,
                                pcsId: r.patientPseudoId,
                                firstName: r.firstName,
                                lastName: r.lastName,
                                id: r.id,
                                visitIds: r.visitIds.toString(),
                                statusName: r.statusName,
                                statusId: r.statusId,
                                isPatientDeleted: r.isPatientDeleted,
                            };
                            temparray.push(p);

                            if (counts) {
                                let countSummary = counts.find((c) => c.patientId === p.id);

                                if (countSummary)
                                    p['lastVisitDate'] = this.datePipe
                                        .transform(countSummary.lastVisitDate)
                                        ?.toUpperCase();
                            }

                            r.visitIds.forEach((id: number) => {
                                let visitName = results[1].find((vt) => vt.id === id);
                                if (visitName) {
                                    let idAsString = id.toString();

                                    if (!this.visitTypes.some((x) => idAsString == x.value)) {
                                        this.visitTypes.push({ value: idAsString, viewValue: visitName.name });
                                    }
                                }
                            });
                        });

                        this.patients = temparray;
                    });

                this.site.siteCRCs = results[3];
                let crcObj = results[3].find((c) => c.isPrimary == true);

                if (crcObj != null) {
                    let crcFName = crcObj.user.firstName;
                    let crcLName = crcObj.user.lastName ? crcObj.user.lastName : '';

                    this.primaryCRC = `${crcFName} ${crcLName}`;
                } else {
                    this.primaryCRC = 'N/A';
                }
            });
    }

    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }
    public onAdditionalSiteDetailsClick() {
        this.router.navigate([`sites/${this.site.id}/edit`], { state: this.site });
    }
    addNewPatient(): void {
        this.studyServices.getStudy(this.site.studyId).subscribe((result) => {
            this.router.navigate([`studies/${this.site.studyId}/sites/${this.site.id}/patients/new`]);
        });
    }
    doClickRow(event: any): void {
        this.router.navigate([`studies/${this.site.studyId}/sites/${this.site.id}/patients/${event.id}`], {
            state: event,
        });
    }

    isAddPatientButtonVisible(): boolean {
        return this.site?.status?.statusId === SiteStatusEnum.Active;
    }
}
