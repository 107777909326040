import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { safeUTC } from '@app/extensions/moment-extensions';
import { MdsOption } from '@medpacesoftwaredevelopment/designsystem/interfaces/mds-option';
import { MdsRadioButton } from '@medpacesoftwaredevelopment/designsystem/interfaces/mds-radio-button';
import { CountryViewModel } from '@models/country';
import { EmittedEvent } from '@models/event';
import { Study } from '@models/study';
import { EventService } from '@services/event/event.service';
import { GoogleMapsService } from '@services/google-maps/google-maps.service';
import { SiteService } from '@services/site/site.service';
import { CountryStateService } from '@services/state-management/country-state.service';
import { PatientStateService } from '@services/state-management/patient-state.service';
import { RequestStateService } from '@services/state-management/request-state.service';
import { StudyStateService } from '@services/state-management/study-state.service';
import { PersistentFormControl } from '@utility/persistent-forms';
import { tryFormatPhoneNumberToE164 } from '@utility/utility';
import {
    emailValidator,
    lessThanTodayDateValidator,
    maxLengthValidator,
    phoneNumberFormatValidatorWithMaxLength,
    requiredValidatorCustomMessage,
    zipCodeFormatValidator,
} from '@utility/utility.validators';
import { Moment } from 'moment';
import { Observable, Subject, of, takeUntil } from 'rxjs';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { InputChange } from 'src/app/models/event-objects/input-change';
import { Patient, PatientIdentification } from 'src/app/models/patient';
import { globals } from '../../../../../globals';
@Component({
    selector: 'medpace-personal-info-card',
    templateUrl: './personal-info-card.component.html',
    styleUrls: ['./personal-info-card.component.scss'],
})
export class MedpacePersonalInformationCardComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
    @Input()
    isEditing: boolean;

    @Input()
    isRequestView: boolean;

    @Input()
    isUserAdmin: boolean;

    @Input()
    isPatientProfile: boolean;

    @Input()
    parentFormGroup: FormGroup;

    @Output()
    inputChangeEvent = new EventEmitter<InputChange>();

    @Output()
    profileLinkClickEvent = new EventEmitter<InputChange>();

    @Output()
    patientActivityLinkClickEvent = new EventEmitter<InputChange>();

    isNew: boolean;
    patientIdent: PatientIdentification;
    birthDate: Moment;
    patient: Patient;
    patient$: Observable<Patient> = this.patientStateService.getSummaryPatientData();
    study$: Observable<Study> = this.studyStateService.getStudy();
    studyVisitGroups: MdsOption[] = [];
    patientVisits: string[] = [];
    studyHasMultipleVisitSchedules: boolean = false;
    private componentDestroyed$: Subject<boolean> = new Subject();
    requestStatus: number;
    patientOver18: boolean = false;
    patientUnder18: boolean;
    patientActivityUrl: string;
    patientProfileUrl: string;
    asListedOnGovID: string = '(' + globals.constants.asListedOnGovernmentID + ')';
    genderFormGroup: FormGroup;
    minorFormGroup: FormGroup;
    loaderSpinner: boolean = true;
    countries$: Observable<string[]> = this.countryStateService.getCountriesStringArray();
    componentIdName: string = 'personal-info';
    visitScheduleDropdownControl = new FormControl('', [Validators.required]);
    genderRadioButtons: MdsRadioButton[] = [
        {
            label: 'Male',
            value: 'male',
            name: `${this.componentIdName}male`,
            id: `${this.componentIdName}male`,
        },
        {
            label: 'Female',
            value: 'female',
            name: `${this.componentIdName}female`,
            id: `${this.componentIdName}female`,
        },
    ];
    minorRadioButtons: MdsRadioButton[] = [
        {
            label: 'Yes',
            value: 'true',
            name: `${this.componentIdName}yes`,
            id: `${this.componentIdName}yes`,
        },
        {
            label: 'No',
            value: 'false',
            name: `${this.componentIdName}no`,
            id: `${this.componentIdName}no`,
        },
    ];
    regionStateType$ = of('Province / Region / State');

    constructor(
        private eventService: EventService,
        private formBuilder: FormBuilder,
        private patientStateService: PatientStateService,
        private requestStateService: RequestStateService,
        private countryStateService: CountryStateService,
        private siteService: SiteService,
        private cdRef: ChangeDetectorRef,
        private googleMapsService: GoogleMapsService,
        private ngZone: NgZone,
        private studyStateService: StudyStateService
    ) {}

    ngOnInit(): void {
        //isNew has the first value of isEditing => for Edited Request: False (Starts with ViewMode) For New Request: True
        this.isNew = this.isEditing;
        this.initializeFormGroup();
        this.patient$
            .pipe(
                takeUntil(this.componentDestroyed$),
                tap((patient) => {
                    this.patient = patient;
                    if (patient) {
                        this.patientIdent = patient?.patientIdentification;
                        this.birthDate = safeUTC(patient?.patientIdentification.birthDate);
                        this.patientProfileUrl = `${this.siteService.getSiteBaseUrl()}/studies/${
                            patient.studyId
                        }/sites/${patient.siteId}/patients/${patient.id}/edit`;
                        this.patientActivityUrl = `${this.siteService.getSiteBaseUrl()}/studies/${
                            patient.studyId
                        }/sites/${patient.siteId}/patients/${patient.id}`;
                    }
                    if (!this.isNew) {
                        this.setValues();
                    }
                    this.loaderSpinner = false;
                }),
                switchMap((x) => this.study$),
                tap((study) => {
                    this.studyHasMultipleVisitSchedules = study?.studyInfo?.hasMultipleVisitSchedules;

                    if (this.studyHasMultipleVisitSchedules) {
                        study.studyInfo.visitTypes.forEach((vN) => {
                            if (
                                vN.groupName == this.patient?.patientIdentification?.visitScheduleName &&
                                !this.patientVisits.includes(vN.name)
                            )
                                this.patientVisits.push(vN.name);

                            if (!this.studyVisitGroups.some((x) => x.value == vN.groupName))
                                this.studyVisitGroups.push(<MdsOption>{ value: vN.groupName, viewValue: vN.groupName });
                        });

                        this.initAndSetVisitScheduleDropdownControl();
                    }
                })
            )
            .subscribe();

        if (!this.isPatientProfile) {
            this.requestStateService
                .getRequest()
                .pipe(
                    takeUntil(this.componentDestroyed$),
                    tap((request) => {
                        if (request) this.requestStatus = request.status;
                    })
                )
                .subscribe();
        }

        this.parentFormGroup
            .get('countryControl')
            ?.valueChanges.pipe(
                takeUntil(this.componentDestroyed$),
                filter(Boolean),
                tap((country) => {
                    this.regionStateType$ = this.countryStateService.getRegionStateType(country);
                    this.cdRef.detectChanges();
                }),
                switchMap((countryString: string) => this.countryStateService.getCountryByName(countryString)),
                filter(Boolean),
                //Valid country
                tap((country: CountryViewModel) => {
                    this.emitChange(country.viewValue, { id: 'country' });
                })
            )
            .subscribe();

        this.googleMapsService
            .observePlaceListener()
            .pipe(
                takeUntil(this.componentDestroyed$),
                tap((place) => {
                    this.googleMapsService
                        .getFieldName()
                        .pipe(
                            tap((fieldName) => {
                                if (place && fieldName === 'address1') this.setAddressControls(place);
                            }),
                            take(1)
                        )
                        .subscribe();
                })
            )
            .subscribe();
    }

    initAndSetVisitScheduleDropdownControl() {
        this.parentFormGroup.addControl('visitScheduleDropdownControl', this.visitScheduleDropdownControl);
        this.parentFormGroup.controls.visitScheduleDropdownControl.setValue(this.patientIdent.visitScheduleName);
    }

    ngOnDestroy(): void {
        this.googleMapsService.clearPlaceObservable();
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.isEditing && changes.isEditing.currentValue && !changes.isEditing.firstChange) {
            this.setEventListenerForAddressControl();
        }
    }

    private setAddressControls(placeResult: google.maps.places.PlaceResult) {
        const addressComponents = placeResult.address_components;
        const line1 = `${this.googleMapsService.getAddressComponent(
            'street_number',
            addressComponents
        )} ${this.googleMapsService.getAddressComponent('route', addressComponents)}`;
        const city = this.googleMapsService.getCityComponent(placeResult);
        const state = this.googleMapsService.getAddressComponent('administrative_area_level_1', addressComponents);
        const postalCode = this.googleMapsService.getAddressComponent('postal_code', addressComponents);
        const country = this.googleMapsService.getAddressComponent('country', addressComponents);

        this.parentFormGroup.controls.address1Control.setValue(line1);
        this.parentFormGroup.controls.cityControl.setValue(city);
        this.parentFormGroup.controls.stateControl.setValue(state);
        this.parentFormGroup.controls.zipcodeControl.setValue(postalCode);
        this.parentFormGroup.controls.countryControl.setValue(country);

        this.inputChangeEvent.emit({ target: 'address1', value: line1 });
        this.inputChangeEvent.emit({ target: 'city', value: city });
        this.inputChangeEvent.emit({ target: 'state', value: state });
        this.inputChangeEvent.emit({ target: 'zipcode', value: postalCode });
        this.inputChangeEvent.emit({ target: 'country', value: country });
    }

    setEventListenerForAddressControl() {
        this.ngZone.onStable.pipe(take(1)).subscribe(() => {
            const mdsComp = document.querySelector('mds-text-field > #address1');
            const inputField = mdsComp?.querySelector('input') as HTMLInputElement | null;

            if (inputField) this.googleMapsService.setPlaceListener(inputField);
        });
    }

    ngAfterViewInit(): void {
        this.setEventListenerForAddressControl();
    }

    initializeFormGroup() {
        this.parentFormGroup.addControl(
            'patientIDControl',
            new FormControl('', [Validators.required, maxLengthValidator(50)])
        );
        this.parentFormGroup.addControl(
            'firstNameControl',
            new FormControl('', [Validators.required, maxLengthValidator(150)])
        );
        this.parentFormGroup.addControl('middleNameControl', new FormControl('', maxLengthValidator(150)));
        this.parentFormGroup.addControl(
            'lastNameControl',
            new FormControl('', [Validators.required, maxLengthValidator(150)])
        );
        this.parentFormGroup.addControl(
            'phoneNumberControl',
            new FormControl('', [Validators.required, phoneNumberFormatValidatorWithMaxLength(16)])
        );
        this.parentFormGroup.addControl(
            'emailAddressControl',
            new FormControl('', [Validators.required, emailValidator, maxLengthValidator(56)])
        );
        this.parentFormGroup.addControl(
            'preferredLanguageControl',
            new FormControl('', [Validators.required, maxLengthValidator(50)])
        );

        this.genderFormGroup = this.formBuilder.group({
            genderControl: ['', requiredValidatorCustomMessage('Please select gender')],
        });
        this.parentFormGroup.addControl('genderFormGroup', this.genderFormGroup);

        this.parentFormGroup.addControl(
            'birthDateControl',
            new PersistentFormControl<Moment>(null, [Validators.required, lessThanTodayDateValidator])
        );
        this.parentFormGroup.controls.birthDateControl.valueChanges
            .pipe(
                tap((value) => {
                    this.birthDate = value;
                }),
                takeUntil(this.componentDestroyed$)
            )
            .subscribe();

        this.minorFormGroup = this.formBuilder.group({
            minorControl: ['', requiredValidatorCustomMessage('Please select if patient is a minor')],
        });
        this.parentFormGroup.addControl('minorFormGroup', this.minorFormGroup);
        this.parentFormGroup.addControl(
            'address1Control',
            new FormControl('', [Validators.required, maxLengthValidator(100)])
        );
        this.parentFormGroup.addControl('address2Control', new FormControl('', maxLengthValidator(100)));
        this.parentFormGroup.addControl(
            'cityControl',
            new FormControl('', [Validators.required, maxLengthValidator(100)])
        );
        this.parentFormGroup.addControl('zipcodeControl', new FormControl('', [zipCodeFormatValidator]));
        this.parentFormGroup.addControl('stateControl', new FormControl('', maxLengthValidator(100)));

        this.parentFormGroup.addControl('countryControl', new FormControl<string>('', Validators.required));
    }

    setValues() {
        if (this.patientIdent) {
            this.parentFormGroup.controls.patientIDControl.setValue(this.patientIdent.patientId);
            this.parentFormGroup.controls.firstNameControl.setValue(this.patientIdent.firstName);
            this.parentFormGroup.controls.middleNameControl.setValue(this.patientIdent.middleName);
            this.parentFormGroup.controls.lastNameControl.setValue(this.patientIdent.lastName);
            this.parentFormGroup.controls.phoneNumberControl.setValue(this.patientIdent.phoneNumber);
            this.parentFormGroup.controls.emailAddressControl.setValue(this.patientIdent.emailAddress);
            this.parentFormGroup.controls.preferredLanguageControl.setValue(this.patientIdent.preferredLanguage);
            this.genderFormGroup.controls.genderControl.setValue(this.patientIdent.gender);
            this.parentFormGroup.controls.birthDateControl.setValue(this.patientIdent.birthDate ? this.birthDate : '');
            this.minorFormGroup.controls.minorControl.setValue(this.patientIdent.isMinor ? 'true' : 'false');
            this.parentFormGroup.controls.address1Control.setValue(this.patientIdent.address1);
            this.parentFormGroup.controls.address2Control.setValue(this.patientIdent.address2);
            this.parentFormGroup.controls.cityControl.setValue(this.patientIdent.city);
            this.parentFormGroup.controls.zipcodeControl.setValue(this.patientIdent.zipcode);
            this.parentFormGroup.controls.stateControl.setValue(this.patientIdent.state);
            this.parentFormGroup.controls.countryControl.setValue(this.patientIdent.country);
        }
    }

    emitChange: (value: any, target: any) => void = (value: any, target: any) => {
        this.inputChangeEvent.emit({ target: target.id, value: value });
        this.eventService.emit(new EmittedEvent('newPatientCreated', null));
    };

    selectGender(value: any, target: any): void {
        this.inputChangeEvent.emit({ target: target.id, value: value });
    }

    typeAddress: (value: any, target: any) => void = (value: any, target: any) => {
        this.inputChangeEvent.emit({ target: target.id, value: value });
        this.eventService.emit(new EmittedEvent('newPatientCreated', null));
        this.googleMapsService.setActiveField(target.id);
    };

    selectUnder18(value: any, target: any): void {
        if (value === 'false') {
            value = false;
        }
        if (value === 'true') {
            value = true;
        }
        this.inputChangeEvent.emit({ target: target.id, value: value });
    }

    profileLinkClick() {
        if (this.patient?.statusId !== 2) {
            window.open(this.patientProfileUrl, '_blank');
        }
    }

    activityLinkClick() {
        window.open(this.patientActivityUrl, '_blank');
    }

    parsePhoneNumber(phoneNumber: string): string {
        return tryFormatPhoneNumberToE164(phoneNumber);
    }
}
