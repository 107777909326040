import { safeUTC } from '@app/extensions/moment-extensions';
import { transformToAddressString } from './address-transform';

import {
    PatchPatientDataModel,
    Patient,
    PatientAccessibilityRequirements,
    PatientDataModel,
    PatientDetailsDataModel,
    PatientDetailsViewModel,
    PatientIdentification,
    PatientInternationalPreferences,
    PatientLodgingPreferences,
    PatientRentalCarPreferences,
    PatientTrainPreferences,
    PatientTransportationPreferences,
} from '@models/patient';
import { AllPatientStatusesEnum } from '@services/patient-status/patient-status.service';

export function transformToPatientDataModel(patient: Patient): PatientDataModel {
    return {
        id: patient.id,
        siteId: patient.siteId,
        siteNumber: patient.siteNumber,
        statusId: patient.statusId,
        statusName: patient.statusName,
        studyId: patient.studyId,
        protocol: patient.protocol,
        code: patient.studyCode,
        sponsor: patient.sponsor,
        region: patient.region,
        country: patient.country,
        patientNum: patient.patientIdentification.patientId,
        preferredPayment: patient.preferredPayment,
        preferredCurrency: patient.preferredCurrency,
        airTravelRequired: patient.travelPreferences.airtravel.needsAirTravel,
        trainRequired: patient.travelPreferences.traintravel.needsTrainTravel,
        internationTravelRequired: patient.travelPreferences.internationaltravel.needsInternationalTravel,
        internationalTraveler: patient.travelPreferences.internationaltravel.internationalTraveler,
        lodgingRequired: patient.travelPreferences.lodging.needsLodging,
        groundTransportRequired: patient.travelPreferences.groundTransportation.needsGroundTransportation,
        rentalCarRequired: patient.travelPreferences.rentalCar.needsRentalCar,
        groundSpecialNeeds: patient.travelPreferences.groundTransportation.groundSpecialNeeds,
        trainSpecialNeeds: patient.travelPreferences.traintravel.specialNeeds,
        airlinePrimary: patient.travelPreferences.airtravel.airlinePreference1,
        airlineSecondary: patient.travelPreferences.airtravel.airlinePreference2,
        hotelChainPrimary: patient.travelPreferences.lodging.hotelBrandPreference,
        seatPreference: patient.travelPreferences.airtravel.seatPreference,
        allergies: patient.travelPreferences.lodging.allergies,
        knownTravelerNum: patient.travelPreferences.airtravel.knownTravelerNumber,
        accessWalker: patient.accessibilityRequirements.walker,
        accessWheelChair: patient.accessibilityRequirements.wheelchair,
        accessRamp: patient.accessibilityRequirements.ramp,
        accessibilityRequirements: patient.accessibilityRequirements.additionalRequirements,
        readyForPayment: patient.readyForPayment,
        paymentApprover: patient.paymentApprover,
        paymentApprovalDate: patient.paymentApprovalDate ? patient.paymentApprovalDate?.toDate() : null,
        paymentRecipientRole: patient.paymentRecipientRole,
        caregivers: patient.caregivers,
        patientDatum: {
            id: patient.patientIdentification.dataId,
            patientId: patient.id,
            firstName: patient.patientIdentification.firstName,
            middleName: patient.patientIdentification.middleName,
            lastName: patient.patientIdentification.lastName,
            birthDate: patient.patientIdentification.birthDate,
            gender: patient.patientIdentification.gender,
            preferredLang: patient.patientIdentification.preferredLanguage,
            country: patient.patientIdentification.country,
            address1: patient.patientIdentification.address1,
            address2: patient.patientIdentification.address2,
            city: patient.patientIdentification.city,
            state: patient.patientIdentification.state,
            zipcode: patient.patientIdentification.zipcode,
            phone: patient.patientIdentification.phoneNumber,
            email: patient.patientIdentification.emailAddress,
            underEighteen: patient.patientIdentification.under18,
            hasConsentForm: patient.hasConsent,
            airlineRewardsPrimary: patient.travelPreferences.airtravel.frequentFlyer1,
            airlineRewardsSecondary: patient.travelPreferences.airtravel.frequentFlyer2,
            airlineSpecialNeeds: patient.travelPreferences.airtravel.airlineSpecialNeeds,
            knownTravelerNum: patient.travelPreferences.airtravel.knownTravelerNumber,
            passportNum: patient.travelPreferences.internationaltravel.passportNum,
            passportIssue: patient.travelPreferences.internationaltravel.passportIssue?.toISOString(),
            passportCountry: patient.travelPreferences.internationaltravel.passportCountry,
            passportExpiration: patient.travelPreferences.internationaltravel.passportExpiration?.toISOString(),
            lodgingSpecialNeeds: patient.travelPreferences.lodging.specialRequirements,
            lodgingRoomPreference: patient.travelPreferences.lodging.lodgingRoomPreference,
            groundSpecialNeeds: patient.travelPreferences.groundTransportation.groundSpecialNeeds,
            trainSpecialNeeds: patient.travelPreferences.traintravel.specialNeeds,
            rentalCarFrequentTravelerNum: patient.travelPreferences.rentalCar.rentalCarFrequentTravelerNumber,
            kycdocument: patient.patientIdentification.KYCDocument,
            kycDocumentInfoId: patient.patientIdentification.KYCDocumentInfoId,
            kycdocumentName: patient.patientIdentification.KYCDocumentName,
            kycvendorId: patient.patientIdentification.KYCVendorId,
            visitScheduleName: patient.patientIdentification.visitScheduleName,
        },
        bankDetails: patient.bankDetails,
    };
}

export function transformToPatient(model: PatientDataModel): Patient {
    return {
        id: model.id,
        siteId: model.siteId,
        siteNumber: model.siteNumber,
        studyId: model.studyId,
        protocol: model.protocol,
        studyCode: model.code,
        sponsor: model.sponsor,
        region: model.region,
        country: model.country,
        statusId: model.statusId,
        statusName: model.statusName,
        hasConsent: model.patientDatum?.hasConsentForm ?? false,
        preferredPayment: model.preferredPayment,
        preferredCurrency: model.preferredCurrency,
        readyForPayment: model.readyForPayment,
        paymentApprover: model.paymentApprover,
        paymentApprovalDate: model.paymentApprovalDate ? safeUTC(model.paymentApprovalDate) : null,
        paymentRecipientRole: model.paymentRecipientRole,
        caregivers: model.patientDatum ? model.caregivers : null,
        patientIdentification: <PatientIdentification>{
            dataId: model.patientDatum.id,
            firstName: model.patientDatum?.firstName ?? '',
            middleName: model.patientDatum?.middleName ?? '',
            lastName: model.patientDatum?.lastName ?? '',
            patientId: model.patientNum,
            birthDate: model.patientDatum?.birthDate ?? '',
            address1: model.patientDatum?.address1 ?? '',
            address2: model.patientDatum?.address2 ?? '',
            city: model.patientDatum?.city ?? '',
            state: model.patientDatum?.state ?? '',
            zipcode: model.patientDatum?.zipcode ?? '',
            country: model.patientDatum?.country ?? '',
            under18: model.patientDatum?.underEighteen ?? false,
            phoneNumber: model.patientDatum?.phone ?? '',
            emailAddress: model.patientDatum?.email ?? '',
            preferredLanguage: model.patientDatum?.preferredLang ?? '',
            gender: model.patientDatum?.gender ?? undefined,
            isMinor: model.patientDatum?.underEighteen ?? undefined,
            KYCDocument: model.patientDatum?.kycdocument ?? undefined,
            KYCDocumentInfoId: model.patientDatum?.kycDocumentInfoId ?? undefined,
            KYCDocumentName: model.patientDatum?.kycdocumentName ?? '',
            KYCVendorId: model.patientDatum?.kycvendorId ?? '',
            visitScheduleName: model.patientDatum?.visitScheduleName ?? '',
        },
        accessibilityRequirements: <PatientAccessibilityRequirements>{
            walker: model.accessWalker,
            wheelchair: model.accessWheelChair,
            ramp: model.accessRamp,
            other: model.accessibilityRequirements ? true : false,
            additionalRequirements: model.accessibilityRequirements,
        },
        travelPreferences: {
            airtravel: {
                needsAirTravel: model.airTravelRequired,
                airlinePreference1: model.airlinePrimary,
                frequentFlyer1: model.patientDatum?.airlineRewardsPrimary ?? '',
                airlinePreference2: model.airlineSecondary,
                frequentFlyer2: model.patientDatum?.airlineRewardsSecondary ?? '',
                seatPreference: model.seatPreference,
                airlineSpecialNeeds: model.patientDatum?.airlineSpecialNeeds ?? '',
                knownTravelerNumber: model.patientDatum?.knownTravelerNum ?? '',
            },
            traintravel: <PatientTrainPreferences>{
                needsTrainTravel: model.trainRequired,
                specialNeeds: model.patientDatum?.trainSpecialNeeds ?? '',
            },
            internationaltravel: <PatientInternationalPreferences>{
                needsInternationalTravel: model.internationTravelRequired,
                internationalTraveler: model.internationalTraveler,
                passportNum: model.patientDatum?.passportNum ?? '',
                passportExpiration: model.patientDatum ? safeUTC(model.patientDatum.passportExpiration) : undefined,
                passportIssue: model.patientDatum ? safeUTC(model.patientDatum.passportIssue) : undefined,
                passportCountry: model.patientDatum ? model.patientDatum.passportCountry : undefined,
            },
            lodging: <PatientLodgingPreferences>{
                needsLodging: model.lodgingRequired,
                hotelBrandPreference: model.hotelChainPrimary,
                lodgingRoomPreference: model.patientDatum?.lodgingRoomPreference ?? '',
                specialRequirements: model.patientDatum?.lodgingSpecialNeeds ?? '',
                allergies: model.allergies,
            },
            groundTransportation: <PatientTransportationPreferences>{
                needsGroundTransportation: model.groundTransportRequired,
                groundSpecialNeeds: model.patientDatum?.groundSpecialNeeds ?? '',
            },
            rentalCar: <PatientRentalCarPreferences>{
                needsRentalCar: model.rentalCarRequired,
                rentalCarFrequentTravelerNumber: model.patientDatum?.rentalCarFrequentTravelerNum ?? '',
            },
        },
        bankDetails: model.bankDetails,
    };
}

export function transformToPatientArray(model: PatientDataModel[]): Patient[] {
    let patients = <Patient[]>[];
    model.forEach((m) => patients.push(transformToPatient(m)));

    return patients;
}

export function transformToPatientDetailsViewModel(patient: PatientDetailsDataModel): PatientDetailsViewModel {
    let date: string = '';
    if (patient.lastVisitDate != undefined) {
        date = patient.lastVisitDate.split(' ')[0];
    }
    const transformed: PatientDetailsViewModel = {
        id: patient.id,
        siteId: patient.siteId,
        studyId: patient.studyId,
        statusId: patient.status,
        statusName: patient.statusName,
        patientNum: patient.patientNum,
        protocol: patient.protocol,
        code: patient.code,
        firstName: patient.firstName,
        lastName: patient.lastName,
        sponsor: patient.sponsor,
        siteNum: patient.siteNum,
        region: patient.region,
        country: patient.country,
        patientAddress: transformToAddressString(patient.patientAddress),
        lastVisitDate: date,
        isPatientDeleted: patient.status == AllPatientStatusesEnum.Removed,
        pcsId: `${patient.code}-${patient.id}`,
    };
    return transformed;
}

export function transformToPatientDetailsViewModelArray(model: PatientDetailsDataModel[]): PatientDetailsViewModel[] {
    let patients = <PatientDetailsViewModel[]>[];
    model.forEach((m) => patients.push(transformToPatientDetailsViewModel(m)));
    return patients;
}

export function transformToPatchPatientDataModel(patient: Patient): PatchPatientDataModel {
    return {
        id: patient.id,
        siteId: patient.siteId,
        airTravelRequired: patient.travelPreferences.airtravel.needsAirTravel,
        trainRequired: patient.travelPreferences.traintravel.needsTrainTravel,
        internationTravelRequired: patient.travelPreferences.internationaltravel.needsInternationalTravel,
        internationalTraveler: patient.travelPreferences.internationaltravel.internationalTraveler,
        lodgingRequired: patient.travelPreferences.lodging.needsLodging,
        groundTransportRequired: patient.travelPreferences.groundTransportation.needsGroundTransportation,
        rentalCarRequired: patient.travelPreferences.rentalCar.needsRentalCar,
        airlinePrimary: patient.travelPreferences.airtravel.airlinePreference1,
        airlineSecondary: patient.travelPreferences.airtravel.airlinePreference2,
        hotelChainPrimary: patient.travelPreferences.lodging.hotelBrandPreference,
        seatPreference: patient.travelPreferences.airtravel.seatPreference,
        allergies: patient.travelPreferences.lodging.allergies,
        patientDatum: {
            airlineRewardsPrimary: patient.travelPreferences.airtravel.frequentFlyer1,
            airlineRewardsSecondary: patient.travelPreferences.airtravel.frequentFlyer2,
            airlineSpecialNeeds: patient.travelPreferences.airtravel.airlineSpecialNeeds,
            knownTravelerNum: patient.travelPreferences.airtravel.knownTravelerNumber,
            passportNum: patient.travelPreferences.internationaltravel.passportNum,
            passportIssue: patient.travelPreferences.internationaltravel.passportIssue?.toISOString(),
            passportCountry: patient.travelPreferences.internationaltravel.passportCountry,
            passportExpiration: patient.travelPreferences.internationaltravel.passportExpiration?.toISOString(),
            lodgingSpecialNeeds: patient.travelPreferences.lodging.specialRequirements,
            lodgingRoomPreference: patient.travelPreferences.lodging.lodgingRoomPreference,
            groundSpecialNeeds: patient.travelPreferences.groundTransportation.groundSpecialNeeds,
            trainSpecialNeeds: patient.travelPreferences.traintravel.specialNeeds,
            rentalCarFrequentTravelerNum: patient.travelPreferences.rentalCar.rentalCarFrequentTravelerNumber,
        },
    };
}
