<mds-dialog dialogTitle="Visit Mapping" *ngIf="vm$ | async; else spinner">
    <mds-dialog-content *ngIf="clinTrakVisits?.length > 0">
        <medpace-alert
            *ngIf="notSelectedVisitsAlert"
            content="Please select the PCS visit and at least one ClinTrak visit."
            [additionalClass]="'warning'"
        ></medpace-alert>
        <mds-tab-group
            [animationDuration]="'300'"
            [disablePagination]="false"
            [headerPosition]="'above'"
            [tabAlign]="'start'"
        >
            <ng-container *ngFor="let groupName of getVisitsGroupName()">
                <mds-tab label="{{ groupName }}" id="tab-{{ groupName }}">
                    <div class="o-grid__row tab-content">
                        <div class="o-grid__col col-width">
                            <h5 class="header-align">PCS</h5>
                            <ng-container *ngIf="!loaderSpinner; else spinner"
                                ><div
                                    class="o-grid__item"
                                    *ngFor="
                                        let studyMappedVisit of getStudyMappedVisitsByGroupName(groupName);
                                        let i = index
                                    "
                                >
                                    <mds-expansion-panel
                                        id="pcs-visit-expansion-{{ studyMappedVisit.visitName.name }}"
                                        [panelTitle]="studyMappedVisit.visitName.name"
                                        [checkboxFormCtrl]="getFormArrayByGroupName(groupName).controls[i]"
                                        [description]="''"
                                        [disabled]="getFormArrayByGroupName(groupName).controls[i].disabled"
                                        [expanded]="false"
                                        [hideToggle]="false"
                                        [enableCheckbox]="true"
                                        [enablePanelRibbon]="false"
                                        [checkboxOptions]="{
                                            id: studyMappedVisit.visitName.name,
                                            name: studyMappedVisit.visitName.name,
                                        }"
                                        (changeEvent)="expansionPanelchangeEvent($event, i, groupName)"
                                    >
                                        <div
                                            class="o-grid__item mapped-visit-list"
                                            *ngFor="let ctVisit of studyMappedVisit.patientVisitsMaster"
                                        >
                                            <span class="width-auto">{{ ctVisit.visitName }}</span>
                                            <div class="width-auto">
                                                <a
                                                    (click)="
                                                        removeSingleClinTrakMappedVisit(
                                                            studyMappedVisit,
                                                            ctVisit.visitName
                                                        )
                                                    "
                                                    ><i class="fas fa-trash icon"></i
                                                ></a>
                                            </div>
                                        </div>
                                        <div
                                            class="o-grid__item"
                                            *ngIf="studyMappedVisit.patientVisitsMaster?.length > 0"
                                        >
                                            <mds-button
                                                [id]="'removeVisit-btn'"
                                                mat-button
                                                (clickEvent)="clearMapping(studyMappedVisit)"
                                                label="Clear mapping"
                                                variant="primary"
                                            ></mds-button>
                                        </div>
                                    </mds-expansion-panel></div
                            ></ng-container>
                        </div>
                        <div class="o-grid__col col-width">
                            <h5 class="header-align">ClinTrak</h5>
                            <ng-container *ngIf="!loaderSpinner; else spinner">
                                <div class="o-grid__row">
                                    <mds-selection-list
                                        id="clintrak-visits-list"
                                        [multiple]="true"
                                        [disabled]="false"
                                        [items]="getClinTrakVisitsPerGroupName(groupName)"
                                        (selectionChange)="clinTrakVisitsSelectionChange($event, groupName)"
                                    ></mds-selection-list>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </mds-tab>
            </ng-container>
        </mds-tab-group>
    </mds-dialog-content>
    <mds-dialog-content *ngIf="clinTrakVisits?.length === 0">
        <medpace-alert content="There are no ClinTrak visits to map." [additionalClass]="'primary'"></medpace-alert>
    </mds-dialog-content>
    <mds-dialog-actions [align]="'center'" *ngIf="clinTrakVisits?.length > 0">
        <mds-button [id]="'done-btn'" mat-button (clickEvent)="confirm()" label="Done" variant="primary"></mds-button>
        <mds-button
            [id]="'map-visits-btn'"
            mat-button
            (clickEvent)="mapSelectedClinTrakVisits()"
            label="Map selected visits"
            variant="primary"
        ></mds-button>
        <mds-button
            [id]="'cancel-btn'"
            *ngIf="showCancelButton"
            mat-button
            mat-dialog-close
            label="Cancel"
            variant="outline"
        ></mds-button>
    </mds-dialog-actions>
    <mds-dialog-actions [align]="'center'" *ngIf="clinTrakVisits?.length === 0">
        <mds-button [id]="'ok-btn'" mat-button mat-dialog-close label="Ok" variant="primary"></mds-button>
    </mds-dialog-actions>
</mds-dialog>

<ng-template #spinner>
    <div class="spinnerOverlay">
        <mds-spinner
            color="primary"
            class="loading-card"
            mode="indeterminate"
            [diameter]="100"
            [strokeWidth]="6"
        ></mds-spinner>
    </div>
</ng-template>
