/// <reference types="@angular/localize" />

import { CSP_NONCE, enableProdMode, inject } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Configuration, EnvConfigurationService } from '@services/env/env-configuration.service';
import { AppModule } from './app/app.module';

document.addEventListener('DOMContentLoaded', () => {
    // We designed the app so the same Angular app build can be used on many environments, only differing by configuration parameters provided in runtime.
    // However, it requires fetching those parameters very early in the startup process, even before Angular initializes:
    fetch('assets/config/config.json?version=20240911101134', { method: 'get' })
        .then((response) => {
            response
                .json()
                .then((envConfig: Configuration) => {
                    if (envConfig.baseUrl.includes('localhost')) {
                        envConfig.nonce = 'localhost';
                    } else {
                        let splitedVersion = envConfig.hashVersion.split('.');
                        envConfig.nonce = splitedVersion[splitedVersion.length - 1];
                    }

                    EnvConfigurationService.setConfiguration(envConfig);

                    if (envConfig.production) {
                        enableProdMode();
                    }

                    platformBrowserDynamic([{ provide: CSP_NONCE, useValue: envConfig.nonce }])
                        .bootstrapModule(AppModule)
                        .catch((err) => console.error(err));
                })
                .catch((err) => {
                    console.error(`Failed to parse environment's configuration as JSON. Application cannot start.`);
                    console.error(err);
                });
        })
        .catch((err) => {
            console.error(`Failed to fetch configuration for the current environment. Application cannot start.`);
            console.error(err);
        });
});

export function nonceFactory(): string {
    const env = inject(EnvConfigurationService);
    return env.configuration.nonce;
}
