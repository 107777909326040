<medpace-editable-card
    *ngIf="{
        value: vm$ | async,
        mode: mode$ | async,
        downloadAttachment: downloadAttachment$ | async,
        radioButtonState: radioButtonState$ | async,
        paymentRequestType: paymentRequestType$ | async,
        authorizedAmountEnabled: authorizedAmountEnabled$ | async,
    } as vm"
    title="Payment Information"
    [mode]="vm.mode"
    [showSwitchModeButton]="switchModeButtonAvailable(vm)"
    [showTitleAsteriskWhenEditMode]="true"
    [isLoading]="!vm.value"
    [formGroup]="formGroup"
    class="o-grid"
>
    <medpace-editable-card-view-mode-content *ngIf="vm.value && vm.mode === 'view'">
        <ng-container [ngTemplateOutlet]="shared"></ng-container>
        <div *ngIf="vm.value.state.reimbursementType === ReimbursementTypes.Stipend"></div>
        <div *ngIf="vm.value.state.reimbursementType === ReimbursementTypes.OutOfPocket">
            <div class="o-grid__row">
                <div class="o-grid__item">
                    <div cdkDropList class="c-bar-list show-overflow" name="expenseList">
                        <div
                            *ngFor="let expenseHandler of vm.value.reimbursementVM.expenseHandlers; let index = index"
                            class="cdk-drag c-bar"
                        >
                            <ng-container
                                *ngIf="isExpenseMileage(expenseHandler.expense.category)"
                                [ngTemplateOutlet]="mileageReimbursementTemplate_ViewMode"
                                [ngTemplateOutletContext]="{
                                    item: {
                                        expenseHandler,
                                        index,
                                        countries: vm.value.state.countries,
                                    },
                                }"
                            ></ng-container>
                            <ng-container
                                *ngIf="!isExpenseMileage(expenseHandler.expense.category)"
                                [ngTemplateOutlet]="otherReimbursementTemplate_ViewMode"
                                [ngTemplateOutletContext]="{
                                    item: {
                                        expenseHandler,
                                        index,
                                        countries: vm.value.state.countries,
                                    },
                                }"
                            ></ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <hr class="summary" />
        <div id="summary-information" class="o-grid__row">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5 class="blackTitle">Date Requested</h5>
                    <span>{{
                        vm.value.state.transactionDate
                            ? (vm.value.state.transactionDate?.toDate() | dateFormat | uppercase)
                            : 'Request was not submitted yet'
                    }}</span>
                </div>
            </div>
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5 class="blackTitle">Total to be Authorized</h5>
                    <span
                        >{{ formGroup.controls.totalAmount.value | number: '1.2' : 'en-US' }}
                        {{ formGroup.controls.currency.value }}</span
                    >
                </div>
            </div>
            @if (vm.value.state.request.requestContextState.requestStatus === GenericRequestStatus.InProcess) {
                <div class="o-grid__item">
                    <div class="c-info__block">
                        <h5 class="blackTitle">Amount Authorized</h5>
                        <span
                            >{{ formGroup.controls.totalAuthorizedAmount.value | number: '1.2' : 'en-US' }}
                            {{ formGroup.controls.currency.value }}</span
                        >
                    </div>
                </div>
            }
        </div>
    </medpace-editable-card-view-mode-content>
    <medpace-editable-card-edit-mode-content *ngIf="vm.value && vm.mode === 'edit'">
        <ng-container [ngTemplateOutlet]="shared"></ng-container>

        <div *ngIf="vm.value.state.reimbursementType === ReimbursementTypes.Stipend"></div>
        <div *ngIf="vm.value.state.reimbursementType === ReimbursementTypes.OutOfPocket">
            <div class="o-grid__row" *ngIf="vm.value.reimbursementVM">
                <div class="o-grid__item">
                    <div class="c-bar-list show-overflow">
                        <medpace-alert
                            *ngIf="
                                !formGroup.controls.expenseControls.valid && formGroup.controls.expenseControls.errors
                            "
                            [content]="formGroup.controls.expenseControls.errors?.required"
                            [additionalClass]="'danger'"
                        />
                        <div
                            class="cdk-drag c-bar"
                            *ngFor="let expenseHandler of vm.value.reimbursementVM.expenseHandlers; let index = index"
                        >
                            <ng-container
                                *ngIf="isExpenseMileage(expenseHandler.expense.category)"
                                [ngTemplateOutlet]="mileageReimbursementTemplate_EditMode"
                                [ngTemplateOutletContext]="{
                                    item: {
                                        expenseHandler,
                                        index,
                                        state: vm.value.state,
                                    },
                                }"
                            ></ng-container>
                            <ng-container
                                *ngIf="!isExpenseMileage(expenseHandler.expense.category)"
                                [ngTemplateOutlet]="otherReimbursementTemplate_EditMode"
                                [ngTemplateOutletContext]="{
                                    item: {
                                        expenseHandler,
                                        index,
                                        state: vm.value.state,
                                    },
                                }"
                            ></ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <div class="o-grid__row">
                <div class="o-grid__item">
                    <div class="flex start">
                        <mds-button
                            (clickEvent)="onAddExpenseClick()"
                            variant="outline"
                            [id]="'addExpense'"
                            data-cy="addExpenseButton"
                            label="Add Expense"
                        ></mds-button>
                    </div>
                </div>
            </div>
        </div>

        <div class="o-grid__row">
            <div class="o-grid__item">
                <div class="c-info__block">
                    <h5>Date Requested</h5>
                    <span>{{
                        vm.value.state.transactionDate
                            ? (vm.value.state.transactionDate?.toDate() | dateFormat | uppercase)
                            : 'Request was not submitted yet'
                    }}</span>
                </div>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <mds-text-field
                    id="totalToBeAuthorizedField"
                    [formCtrl]="formGroup.controls.totalAmount"
                    placeholder=""
                    appearance="outline"
                    floatLabel="always"
                    placeholder="Type here..."
                    label="Total to be Authorized"
                    width="100%"
                    inputType="decimal"
                ></mds-text-field>
            </div>
            @if (formGroup.controls.totalAuthorizedAmount.enabled) {
                <div class="o-grid__item">
                    <mds-text-field
                        id="totalAuthorizedField"
                        [formCtrl]="formGroup.controls.totalAuthorizedAmount"
                        placeholder=""
                        appearance="outline"
                        floatLabel="always"
                        placeholder="Type here..."
                        label="Amount Authorized"
                        width="100%"
                        inputType="decimal"
                    ></mds-text-field>
                </div>
            }
            <div class="o-grid__item">
                <mds-dropdown
                    floatLabel="always"
                    [closeOnPageScroll]="false"
                    appearance="outline"
                    label="Currency"
                    placeholder="Select Currency"
                    [options]="vm.value.state.currencies"
                    id="filterDropdown"
                    [formCtrl]="formGroup.controls.currency"
                ></mds-dropdown>
            </div>
        </div>
    </medpace-editable-card-edit-mode-content>
</medpace-editable-card>

<ng-template #mileageReimbursementTemplate_ViewMode let-item="item">
    <ng-container *ngIf="toMileageExpenseVM(item); let vm">
        <div class="o-grid">
            <div class="o-grid__row">
                <div class="o-grid__item">
                    <div class="c-info__block">
                        <h5>Expense Type</h5>
                        <span>{{ vm.expenseHandler.expense.category }}</span>
                    </div>
                </div>
            </div>

            <div class="o-grid__row">
                <div class="o-grid__item">
                    <div class="c-info__block">
                        <h5>From Location</h5>
                        <span>{{ vm.expenseHandler.expense.mileageStartLocation }}</span>
                    </div>
                </div>
            </div>
            <div class="o-grid__row">
                <div class="o-grid__item">
                    <div class="c-info__block">
                        <h5>To Location</h5>
                        <span>{{ vm.expenseHandler.expense.mileageEndLocation }}</span>
                    </div>
                </div>
            </div>
            <div class="o-grid__row">
                <div class="o-grid__item">
                    <div class="c-info__block">
                        <h5>Total Distance</h5>
                        <span>{{ vm.expenseHandler.expense.mileageTotalDistance }}</span>
                    </div>
                </div>
                <div class="o-grid__item">
                    <div class="c-info__block">
                        <h5>Distance Measurement</h5>
                        <span>{{ parseDistanceUnit(vm.expenseHandler.expense.mileageDistanceUnits) }}</span>
                    </div>
                </div>
            </div>
            <div class="o-grid__row">
                <div class="o-grid__item">
                    <div class="c-info__block">
                        <h5>Country</h5>
                        <span>{{ getCountry(vm.expenseHandler.expense.mileageReimbursementCountry) | async }}</span>
                    </div>
                </div>
            </div>
            <div class="o-grid__row">
                <div class="o-grid__item">
                    <div class="c-info__block">
                        <h5>Comments</h5>
                        <span>{{ vm.expenseHandler.expense.mileageComments }}</span>
                    </div>
                </div>
            </div>
            <hr />
            <div class="o-grid__row">
                <div class="o-grid__item">
                    <div class="c-info__block">
                        <h5>RECEIPT DATE</h5>
                        <span>{{ vm.expenseHandler.expense.timestamp?.toDate() | dateFormat | uppercase }}</span>
                    </div>
                </div>
                <div class="o-grid__item">
                    <div class="c-info__block">
                        <h5 class="blackTitle">Mileage Reimbursement Total</h5>
                        <span
                            >{{ vm.expenseHandler.expense.amount | number: '1.2' : 'en-US' }}
                            {{ vm.expenseHandler.expense.currency }}</span
                        >
                    </div>
                </div>
                @if ((vm.expenseHandler.requestStatus$ | async) === GenericRequestStatus.InProcess) {
                    <div class="o-grid__item">
                        <div class="c-info__block">
                            <h5 class="blackTitle">Amount Authorized</h5>
                            <span
                                >{{ vm.expenseHandler.expense.authorizedAmount | number: '1.2' : 'en-US' }}
                                {{ vm.expenseHandler.expense.currency }}</span
                            >
                        </div>
                    </div>
                }
            </div>
            @if (vm.state?.request.requestContextState.requestStatus === GenericRequestStatus.InProcess) {
                <div class="o-grid__row">
                    <div class="o-grid__item">
                        <div class="c-info__block">
                            <h5 class="blackTitle">Notes</h5>
                            <span>{{ vm.expenseHandler.expense.notes }} </span>
                        </div>
                    </div>
                </div>
            }
        </div>
    </ng-container>
</ng-template>
<ng-template #otherReimbursementTemplate_ViewMode let-item="item">
    <ng-container *ngIf="toOtherExpenseVM(item); let vm">
        <div class="o-grid">
            <div class="o-grid__row">
                <div class="o-grid__item">
                    <div class="c-info__block">
                        <h5>Expense Type</h5>
                        <span>{{ vm.expenseHandler.expense.category }}</span>
                    </div>
                </div>
            </div>

            <div class="o-grid__row">
                <div class="o-grid__item">
                    <div class="c-info__block">
                        <h5>Receipt Date</h5>
                        <span>{{ vm.expenseHandler.expense.timestamp?.toDate() | dateFormat | uppercase }}</span>
                    </div>
                </div>
                <div class="o-grid__item">
                    <div class="c-info__block">
                        <h5 class="blackTitle">Amount to be authorized</h5>
                        <span
                            >{{ vm.expenseHandler.expense.amount | number: '1.2' : 'en-US' }}
                            {{ vm.expenseHandler.expense.currency }}</span
                        >
                    </div>
                </div>
                @if ((vm.expenseHandler.requestStatus$ | async) === GenericRequestStatus.InProcess) {
                    <div class="o-grid__item">
                        <div class="c-info__block">
                            <h5 class="blackTitle">Amount Authorized</h5>
                            <span
                                >{{ vm.expenseHandler.expense.authorizedAmount | number: '1.2' : 'en-US' }}
                                {{ vm.expenseHandler.expense.currency }}</span
                            >
                        </div>
                    </div>
                }
            </div>
            @if (vm.state?.request.requestContextState.requestStatus === GenericRequestStatus.InProcess) {
                <div class="o-grid__row">
                    <div class="o-grid__item">
                        <div class="c-info__block">
                            <h5 class="blackTitle">Notes</h5>
                            <span>{{ vm.expenseHandler.expense.notes }} </span>
                        </div>
                    </div>
                </div>
            }

            <hr />
            <div
                class="o-grid__row"
                *ngIf="
                    vm.expenseHandler.expense.attachmentId !== null &&
                    vm.expenseHandler.expense.attachmentId !== undefined
                "
            >
                <div class="o-grid__item">
                    <a (click)="onAttachmentDownloadClick(vm.expenseHandler.expense.attachmentId)">Download File</a>
                </div>
            </div>
        </div>
    </ng-container>
</ng-template>
<ng-template #mileageReimbursementTemplate_EditMode let-item="item">
    <ng-container *ngIf="toMileageExpenseVM(item); let vm">
        <div
            class="o-grid"
            id="paymentInformation_{{ vm.index }}"
            [attr.data-cy]="'paymentInformation_' + vm.index"
            *ngIf="{
                initFormGroup: vm.expenseHandler.initFormGroup$ | async,
                file: vm.expenseHandler.fileUpload$ | async,
                formChange: vm.expenseHandler.formChange$ | async,
                sharedCurrencyChange: vm.expenseHandler.sharedCurrencyChange$ | async,
                reimbursementRate: vm.expenseHandler.reimbursementRate$ | async,
                totalReimbursement: vm.expenseHandler.totalReimbursement$ | async,
                isIncomplete: vm.expenseHandler.isIncomplete$ | async,
                formEnabled: vm.expenseHandler.formEnabled$ | async,
                filteredCountries: vm.expenseHandler.filteredCountries$ | async,
                initGoogleMapsService: vm.expenseHandler.initGoogleMapsService$ | async,
                authorizedAmountEnabled: vm.expenseHandler.authorizedAmountEnabled$ | async,
            } as expenseVM"
        >
            <div class="o-grid__row">
                <div class="o-grid__item u-ta-right">
                    <a (click)="vm.expenseHandler.onRemoveExpenseClick()" href="javascript:void(0);"
                        ><i class="fas fa-trash"></i
                    ></a>
                </div>
            </div>
            <div class="o-grid__row">
                <div class="o-grid__field">
                    <mds-dropdown
                        floatLabel="always"
                        [closeOnPageScroll]="false"
                        appearance="outline"
                        label="expense type"
                        placeholder="Select Expense Type"
                        [options]="expenseOptions"
                        id="expensesDropdown"
                        [attr.data-cy]="'expenseTypeDropdown'"
                        [formCtrl]="vm.expenseHandler.formGroup.controls.category"
                    ></mds-dropdown>
                </div>
            </div>
            <div class="o-grid__row">
                <div class="o-grid__field">
                    <mds-text-field
                        id="FromLocationField"
                        data-cy="fromLocationField"
                        [formCtrl]="vm.expenseHandler.formGroup.controls.fromLocation"
                        placeholder="Enter address here"
                        appearance="outline"
                        floatLabel="always"
                        label="From Location"
                        width="100%"
                        (input)="vm.expenseHandler.locationInput('fromLocationField', vm.index)"
                    ></mds-text-field>
                </div>
            </div>
            <div class="o-grid__row">
                <div class="o-grid__field">
                    <mds-text-field
                        id="ToLocationField"
                        [formCtrl]="vm.expenseHandler.formGroup.controls.toLocation"
                        placeholder="Enter address here"
                        appearance="outline"
                        floatLabel="always"
                        label="To Location"
                        width="100%"
                        (input)="vm.expenseHandler.locationInput('toLocationField', vm.index)"
                    ></mds-text-field>
                </div>
            </div>

            <div class="o-grid__row">
                <div class="o-grid__field half">
                    <mds-autocomplete
                        [closeOnPageScroll]="false"
                        [disableRipple]="false"
                        [options]="expenseVM.filteredCountries"
                        appearance="outline"
                        floatLabel="always"
                        placeholder="Enter country"
                        id="country"
                        inputType="text"
                        label="Country"
                        [formCtrl]="vm.expenseHandler.formGroup.controls.country"
                        [validateInput]="true"
                    >
                    </mds-autocomplete>
                </div>
            </div>
            <div class="o-grid__row" *ngIf="vm.state.user.isAdmin">
                <div class="o-grid__item">
                    <mds-checkbox
                        label="Manual Total Calculation"
                        id="ManualTotalCalculation"
                        [formCtrl]="vm.expenseHandler.formGroup.controls.manualTotalCalculation"
                    ></mds-checkbox>
                </div>
            </div>
            <div class="o-grid__row" style="align-items: baseline">
                <div class="o-grid__item">
                    <mds-text-field
                        id="ReimbursementRate"
                        [inputType]="'decimal'"
                        [decimalPlaces]="3"
                        placeholder="Type here..."
                        appearance="outline"
                        floatLabel="always"
                        label="Reimbursement Rate"
                        width="100%"
                        [formCtrl]="vm.expenseHandler.formGroup.controls.reimbursementRate"
                    ></mds-text-field>
                </div>
                <div class="o-grid__item">
                    <mds-dropdown
                        floatLabel="always"
                        [closeOnPageScroll]="false"
                        appearance="outline"
                        label="Currency"
                        placeholder="Select Currency"
                        [options]="vm.state.currencies"
                        id="filterDropdown"
                        [formCtrl]="vm.expenseHandler.formGroup.controls.currency"
                    ></mds-dropdown>
                </div>
                <div class="o-grid__item">Per Distance Unit</div>
            </div>
            <div class="o-grid__row" *ngIf="expenseVM.isIncomplete">
                <div class="o-grid__item">
                    <ng-container *ngIf="!vm.state.user.isAdmin && !vm.state.user.isSuperAdmin">
                        <medpace-alert
                            content="Either the reimbursement rate for this country is not available or the currency in the
                        country does not match the currency of the request."
                            additionalClass="secondary"
                        />
                    </ng-container>
                    <ng-container *ngIf="vm.state.user.isAdmin || vm.state.user.isSuperAdmin">
                        <medpace-alert
                            content="Either the reimbursement rate for this country is not available or the currency in the
                            country does not match the currency of the request. Use the manual total calculation to
                            enter a reimbursement rate and total manually."
                            additionalClass="secondary"
                        />
                    </ng-container>
                </div>
            </div>
            <div class="o-grid__row">
                <div class="o-grid__field half">
                    <mds-text-field
                        appDisableMouseScroll
                        id="TotalDistanceField"
                        [formCtrl]="vm.expenseHandler.formGroup.controls.totalDistance"
                        inputType="number"
                        placeholder="Type here..."
                        appearance="outline"
                        floatLabel="always"
                        label="Total Distance"
                        width="100%"
                    ></mds-text-field>
                </div>
                <div class="o-grid__field half distance-measurement-content">
                    <mds-radio-group
                        [label]="'Distance Unit'"
                        [required]="true"
                        [buttons]="distanceUnit_RadioButtons"
                        [formCtrl]="vm.expenseHandler.formGroup.controls.distanceUnit"
                        id="distanceUnit"
                    >
                    </mds-radio-group>
                </div>
            </div>
            <div class="o-grid__row">
                <div class="o-grid__field">
                    <mds-text-field
                        id="CommentField"
                        [formCtrl]="vm.expenseHandler.formGroup.controls.comment"
                        label="Additional Details"
                        appearance="outline"
                        floatLabel="always"
                        placeholder="Type here..."
                        width="100%"
                    ></mds-text-field>
                </div>
            </div>

            <div class="o-grid__row">
                <div class="o-grid__field">
                    <div class="c-datepicker" id="TransactionDatePicker" data-cy="transactionDatePicker">
                        <medpace-datepicker
                            [formCtrl]="vm.expenseHandler.formGroup.controls.timestamp"
                            id="mileage-timestamp"
                            appearance="outline"
                            label="Receipt Date"
                        ></medpace-datepicker>
                    </div>
                </div>
                <div class="o-grid__field">
                    <mds-text-field
                        id="AmountToAuthorizeField"
                        [formCtrl]="vm.expenseHandler.formGroup.controls.amountToAuthorize"
                        [inputType]="'decimal'"
                        [decimalPlaces]="2"
                        placeholder="Type here..."
                        appearance="outline"
                        floatLabel="always"
                        label="Mileage Reimbursement Total"
                        width="100%"
                    ></mds-text-field>
                </div>
                @if (vm.expenseHandler.formGroup.controls.authorizedAmount.enabled) {
                    <div class="o-grid__field">
                        <mds-text-field
                            id="AuthorizedAmountField"
                            [formCtrl]="vm.expenseHandler.formGroup.controls.authorizedAmount"
                            [inputType]="'decimal'"
                            [decimalPlaces]="2"
                            placeholder="Type here..."
                            appearance="outline"
                            floatLabel="always"
                            label="Amount Authorized"
                            width="100%"
                        ></mds-text-field>
                    </div>
                }
            </div>
            @if (vm.expenseHandler.formGroup.controls.notes.enabled) {
                <div class="o-grid__row">
                    <div class="o-grid__field">
                        <mds-text-field
                            id="CommentField"
                            [formCtrl]="vm.expenseHandler.formGroup.controls.notes"
                            label="Notes"
                            appearance="outline"
                            floatLabel="always"
                            placeholder="Type here..."
                            width="100%"
                        ></mds-text-field>
                    </div>
                </div>
            }
        </div>
    </ng-container>
</ng-template>
<ng-template #otherReimbursementTemplate_EditMode let-item="item">
    <ng-container *ngIf="toOtherExpenseVM(item); let vm">
        <div
            class="o-grid"
            id="paymentInformation_{{ vm.index }}"
            [attr.data-cy]="'paymentInformation_' + vm.index"
            *ngIf="{
                file: vm.expenseHandler.fileUpload$ | async,
                formChange: vm.expenseHandler.formChange$ | async,
                authorizedAmountEnabled: vm.expenseHandler.authorizedAmountEnabled$ | async,
                sharedCurrencyChange: vm.expenseHandler.sharedCurrencyChange$ | async,
            }"
        >
            <div class="o-grid__row">
                <div class="o-grid__item u-ta-right">
                    <a (click)="vm.expenseHandler.onRemoveExpenseClick()" href="javascript:void(0);"
                        ><i class="fas fa-trash"></i
                    ></a>
                </div>
            </div>
            <div class="o-grid__row">
                <div class="o-grid__field">
                    <mds-dropdown
                        floatLabel="always"
                        [closeOnPageScroll]="false"
                        appearance="outline"
                        label="expense type"
                        placeholder="Select Expense Type"
                        [options]="expenseOptions"
                        id="expensesDropdown"
                        [attr.data-cy]="'expenseTypeDropdown'"
                        [formCtrl]="vm.expenseHandler.formGroup.controls.category"
                    ></mds-dropdown>
                </div>
            </div>
            <div class="o-grid__row">
                <div class="o-grid__item">
                    <div *cdkDragPlaceholder class="example-custom-placeholder"></div>
                    <medpace-logo-upload
                        [inputObj]="!!vm.expenseHandler.expense.attachmentId"
                        [acceptableTypes]="acceptableFileTypes"
                        idVal="attachment-{{ vm.index }}"
                        uploadButtonTitle="Upload Document"
                        [attr.data-cy]="'fileDropzone'"
                        (emitStuff)="vm.expenseHandler.onFileUploaded($event)"
                    ></medpace-logo-upload>
                </div>
            </div>
            <div class="o-grid__row">
                <div class="o-grid__field half">
                    <div class="c-datepicker" id="TransactionDatePicker" data-cy="transactionDatePicker">
                        <medpace-datepicker
                            [formCtrl]="vm.expenseHandler.formGroup.controls.timestamp"
                            id="timestamp1"
                            appearance="outline"
                            label="Receipt Date"
                        ></medpace-datepicker>
                    </div>
                </div>
                <div class="o-grid__field half">
                    <mds-text-field
                        appDisableMouseScroll
                        id="AmountToAuthorizeField"
                        data-cy="amountToAuthorizeField"
                        [formCtrl]="vm.expenseHandler.formGroup.controls.amountToAuthorize"
                        inputType="decimal"
                        placeholder="Type here..."
                        appearance="outline"
                        floatLabel="always"
                        label="Amount to be authorized"
                        width="100%"
                    ></mds-text-field>
                </div>
                @if (vm.expenseHandler.formGroup.controls.authorizedAmount.enabled) {
                    <div class="o-grid__field half">
                        <mds-text-field
                            appDisableMouseScroll
                            id="AuthorizedAmountField"
                            data-cy="authorizedAmountField"
                            [formCtrl]="vm.expenseHandler.formGroup.controls.authorizedAmount"
                            inputType="decimal"
                            placeholder="Type here..."
                            appearance="outline"
                            floatLabel="always"
                            label="Amount authorized"
                            width="100%"
                        ></mds-text-field>
                    </div>
                }
            </div>
            @if (vm.expenseHandler.formGroup.controls.notes.enabled) {
                <div class="o-grid__row">
                    <div class="o-grid__field">
                        <mds-text-field
                            id="CommentField"
                            [formCtrl]="vm.expenseHandler.formGroup.controls.notes"
                            label="Notes"
                            appearance="outline"
                            floatLabel="always"
                            placeholder="Type here..."
                            width="100%"
                        ></mds-text-field>
                    </div>
                </div>
            }
        </div>
    </ng-container>
</ng-template>
<ng-template #shared>
    <div class="o-grid__row">
        <div class="o-grid__item">
            <span
                >What are you requesting?
                <span class="required-asterisk">*</span>
            </span>
        </div>
    </div>
    <div class="o-grid__row">
        <div class="o-grid__item">
            <mds-radio-group
                [buttons]="reimbursementType_RadioButtons"
                [formCtrl]="formGroup.controls.reimbursementType"
                id="requestType"
            >
            </mds-radio-group>
        </div>
    </div>
</ng-template>
