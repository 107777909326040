import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren,
    WritableSignal,
    inject,
    signal,
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import {
    MedpaceDraggableListComponent,
    RemoveOptionData,
} from '@components/atoms/medpace-draggable-list/medpace-draggable-list.component';
import { DisplayErrorModalComponent } from '@components/molecules/modals/display-error-modal/display-error-modal.component';
import { MdsOption } from '@medpacesoftwaredevelopment/designsystem/interfaces/mds-option';
import { ClinTrakPersonViewModel, CountryRegionCtsmViewModel, StudyPersonLocaleViewModel } from '@models/clintrak';
import { Target } from '@models/event-objects/target';
import { Region } from '@models/region';
import { AdminRequestServices } from '@services/admin/admin-request.service';
import { EventService } from '@services/event/event.service';
import { LoadingViewTriggerService } from '@services/loading-module-trigger/loading-module-trigger.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { ClinTrakDataStateService } from '@services/state-management/clintrak-data-state.service';
import { StudyStateService } from '@services/state-management/study-state.service';
import { TravelOptionStateService } from '@services/state-management/travel-option-state.service';
import { PersistentFormControl } from '@utility/persistent-forms';
import { NumberFormatValidator, beforeDateValidator, maxLengthValidator } from '@utility/utility.validators';
import { Moment } from 'moment';
import {
    Observable,
    Subject,
    Subscription,
    defer,
    iif,
    of,
    switchMap,
    take,
    takeUntil,
    tap,
    withLatestFrom,
} from 'rxjs';
import { MedpaceVisitMappingModalComponent } from 'src/app/components/molecules/modals/medpace-visit-mapping-modal/medpace-visit-mapping-modal.component';
import { InputChange } from 'src/app/models/event-objects/input-change';
import { ExpenseType, Study, StudyInformation, StudyTravelOption, SupportTier, VisitName } from 'src/app/models/study';
export interface RemoveOptionDataWithIndex extends RemoveOptionData<VisitName> {
    index: number;
}
@Component({
    selector: 'medpace-study-info-card',
    templateUrl: './study-info-card.component.html',
    styleUrls: ['../../../organisms/input-card/input-card.component.scss', './study-info-card.component.scss'],
})
export class MedpaceStudyInfoCardComponent implements OnInit, OnDestroy {
    @Input()
    content: StudyInformation;

    content$: Observable<Study>;

    @Input()
    isClinTrakComparison: boolean = false;

    @Input()
    isEditing: boolean;

    @Input()
    parentFormGroup: FormGroup;

    @Output()
    inputChangeEvent = new EventEmitter<InputChange>();

    private cdr = inject(ChangeDetectorRef);

    @ViewChild('auto') autocompleteChild: MatAutocomplete;
    @ViewChild('regionInput') regionInput: ElementRef<HTMLInputElement>;
    @ViewChildren('visitNamesDraggableList') visitNamesDraggableList: QueryList<
        MedpaceDraggableListComponent<VisitName>
    >;
    @ViewChild('expenseTypesDraggableList') expenseTypesDraggableList: MedpaceDraggableListComponent<ExpenseType>;
    regionControl = new UntypedFormControl();
    separatorKeysCodes: number[] = [ENTER, COMMA];
    selectable: boolean = true;
    removable: boolean = true;
    filteredRegions: Observable<Region[]>;
    showTravelOptions: WritableSignal<boolean> = signal(true);

    // regions: StudyRegion[];
    regionsForView: MdsOption[];
    countriesForView: MdsOption[];
    countries: CountryRegionCtsmViewModel[];
    regions: CountryRegionCtsmViewModel[];
    selectedRegions: CountryRegionCtsmViewModel[];
    selectedCountries: CountryRegionCtsmViewModel[];
    regionsFormGroup: FormGroup;
    countriesFormGroup = new FormGroup({
        countriesDropdown: new FormControl<string[]>([], Validators.required),
    });
    datesFormGroup: FormGroup<{ dateFPFV: PersistentFormControl<Moment>; dateLPLV: PersistentFormControl<Moment> }>;
    loaderSpinner: boolean = true;
    private componentDestroyed$: Subject<boolean> = new Subject();

    travelOptions: StudyTravelOption[];
    travelOptionsForView: MdsOption[];
    selectedTravelOptions: StudyTravelOption[];
    travelOptionsFormGroup: FormGroup;
    eventServiceSubscription: Subscription;
    visitGroups: { [groupName: string]: VisitName[] } = {};
    multipleVisitRadioFormGroup: FormGroup;
    hasMultipleVisitSchedules: boolean = false;
    showVisitGroupField: boolean = false;
    cohortExists: boolean = false;
    isPrimaryGroupName: string = null;

    radioButtons = [
        {
            label: 'Yes',
            value: 'yes',
            name: `Yes`,
            id: `YesMultipleVisitSchedules`,
        },
        {
            label: 'No',
            value: 'no',
            name: `No`,
            id: `NoMultipleVisitSchedules`,
        },
    ];

    private tryRemoveVisitNameSubject = new Subject<RemoveOptionDataWithIndex>();
    private tryRemoveExpenseTypeSubject = new Subject<RemoveOptionData<ExpenseType>>();
    protected tryRemoveVisitName$ = this.tryRemoveVisitNameSubject.pipe(
        withLatestFrom(this.stateService.getStudy()),
        switchMap(([removeOptionData, study]) => {
            const isNewOption = !removeOptionData.optionToRemove.id; // no id - option just added, not yet saved
            const isNewOption$ = of({}).pipe(
                tap((_) =>
                    this.visitNamesDraggableList
                        .toArray()
                        [removeOptionData.index].removeOption(removeOptionData.optionToRemove)
                )
            ); // can safely remove newly added option, there is for sure no requests using it
            const isNotNewOption$ = defer(() => {
                this.loadingViewTriggerService.openLoading();
                return this.requestService
                    .getAllRequestsCount({
                        studyIds: [study.id],
                        visitNamesIds: [removeOptionData.optionToRemove.id],
                    }) // need to call api to check for requests
                    .pipe(
                        tap((_) => this.loadingViewTriggerService.closeLoading()),
                        tap((count) => {
                            if (count === 0)
                                this.visitNamesDraggableList
                                    .toArray()
                                    [removeOptionData.index].removeOption(removeOptionData.optionToRemove);
                            else
                                this.dialog.open(DisplayErrorModalComponent, {
                                    autoFocus: false,
                                    width: '500px',
                                    disableClose: false,
                                    data: `Cannot remove visit type: ${removeOptionData.optionToRemove.name} because there are requests using it. You must first delete requests that utilize this visit type`,
                                });
                        })
                    );
            });

            return iif(() => isNewOption, isNewOption$, isNotNewOption$);
        })
    );
    protected tryRemoveExpenseType$ = this.tryRemoveExpenseTypeSubject.pipe(
        withLatestFrom(this.stateService.getStudy()),
        switchMap(([removeOptionData, study]) => {
            const isNewOption = !removeOptionData.optionToRemove.id; // no id - option just added, not yet saved
            const isNewOption$ = of({}).pipe(
                tap((_) => this.expenseTypesDraggableList.removeOption(removeOptionData.optionToRemove))
            ); // can safely remove newly added option, there is for sure no requests using it
            const isNotNewOption$ = defer(() => {
                this.loadingViewTriggerService.openLoading();
                return this.requestService
                    .getAllReimbursementRequestsCount({
                        studyIds: [study.id],
                        visitNamesIds: [],
                        expenseTypesNames: [removeOptionData.optionToRemove.name],
                    }) // need to call api to check for requests
                    .pipe(
                        tap((_) => this.loadingViewTriggerService.closeLoading()),
                        tap((count) => {
                            if (count === 0)
                                this.expenseTypesDraggableList.removeOption(removeOptionData.optionToRemove);
                            else
                                this.dialog.open(DisplayErrorModalComponent, {
                                    autoFocus: false,
                                    width: '500px',
                                    disableClose: false,
                                    data: `Cannot remove expense type: ${removeOptionData.optionToRemove.name} because there are requests using it. You must first delete travel requests that utilize this expense type`,
                                });
                        })
                    );
            });

            return iif(() => isNewOption, isNewOption$, isNotNewOption$);
        })
    );
    constructor(
        private formBuilder: FormBuilder,
        private stateService: StudyStateService,
        private travelOptionStateService: TravelOptionStateService,
        private loadingViewTriggerService: LoadingViewTriggerService,
        private requestService: AdminRequestServices,
        private snackbarService: SnackbarService,
        private eventService: EventService,
        private clinTrakDataStateService: ClinTrakDataStateService,
        private dialog: MatDialog
    ) {}

    ngOnInit(): void {
        this.clinTrakDataStateService
            .getCountries()
            .pipe(
                takeUntil(this.componentDestroyed$),
                tap((result) => {
                    this.countries = result;
                    this.countriesForView = result.map((x) => ({ value: x.countryId, viewValue: x.countryName }));

                    this.regions = result;
                    let distRegions: MdsOption[] = [];
                    result.forEach((cr) => {
                        if (!distRegions?.some((x) => x.value === cr.regionId))
                            distRegions.push({ value: cr.regionId, viewValue: cr.regionName });
                    });
                    this.regionsForView = distRegions;
                })
            )
            .subscribe();

        this.travelOptionStateService
            .getTravelOptions()
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe((result) => {
                this.travelOptions = result.map((x) => <StudyTravelOption>{ id: x.id, name: x.name, studyId: 0 });
                this.travelOptionsForView = result.map((x) => ({ value: x.id, viewValue: x.name }));
            });

        this.initializeFormGroup();

        this.content$ = this.stateService.getStudy();
        this.content$
            .pipe(
                tap((study) => {
                    if (study) {
                        this.content = study.studyInfo;
                        this.hasMultipleVisitSchedules = this.content.hasMultipleVisitSchedules;
                        this.initializePersonsArrays();
                        this.initRadioButtonsFormGroup();
                        this.setValues();
                        this.mapVisitsByGroup();
                        this.loaderSpinner = false;
                    }
                }),
                takeUntil(this.componentDestroyed$)
            )
            .subscribe();

        this.eventServiceSubscription = this.eventService.on('tierChanged', (value: SupportTier) => {
            this.handleAvailableTravelOptions(value);
        });
    }

    initRadioButtonsFormGroup() {
        this.multipleVisitRadioFormGroup = this.formBuilder.group({
            multipleVisitControl: [this.content?.hasMultipleVisitSchedules ? 'yes' : 'no'],
        });
    }

    getRegionNamesByGuid(locales: StudyPersonLocaleViewModel[]): string {
        let regions = this.regionsForView.filter((x) => locales?.map((z) => z.regionId).includes(<string>x.value));
        let result = regions?.map((x) => x.viewValue).join(', ');
        return result;
    }

    hasMultipleVisitScheduleRadioChanged(value: string, target: Target) {
        this.hasMultipleVisitSchedules = value === 'yes' ? true : false;

        if (!this.hasMultipleVisitSchedules) {
            let visitGroupsName = this.getVistGroupNamesAsArray();
            let first = this.visitGroups[visitGroupsName[0]];
            this.visitGroups = {};
            this.visitGroups[visitGroupsName[0]] = first;

            visitGroupsName.forEach((groupName, index) => {
                if (index !== 0) {
                    this.visitNamesDraggableList.toArray()[index].removeGroup();
                }
            });
        }

        this.emitChange(this.hasMultipleVisitSchedules, target);
    }

    addVisitScheduleGroup() {
        this.showVisitGroupField = true;
        this.parentFormGroup.addControl(
            'groupNameInput',
            new FormControl<string>('', [Validators.required, maxLengthValidator(200)])
        );
    }

    changeGroupName({ currentGroupName, newGroupName }) {
        this.visitGroups[newGroupName] = this.visitGroups[currentGroupName];
        delete this.visitGroups[currentGroupName];
    }

    isPrimaryChange({ groupName, isPrimary }) {
        this.isPrimaryGroupName = isPrimary ? groupName : null;
    }

    addVisitGroup() {
        let groupName = <string>this.parentFormGroup.controls.groupNameInput.value;
        if (!this.getVistGroupNamesAsArray().some((x) => x === groupName)) {
            if (this.isPrimaryGroupName !== null) {
                let currentVisits = this.visitGroups[this.isPrimaryGroupName];
                let tempVisits = <VisitName[]>JSON.parse(JSON.stringify(currentVisits));
                let resultVisit = tempVisits.map((visit) => {
                    return <VisitName>{
                        name: visit.name,
                        scheduled: visit.scheduled,
                        sortOrder: visit.sortOrder,
                        groupName: groupName,
                    };
                });
                this.visitGroups[groupName] = resultVisit;
                let allVisits = <VisitName[]>[].concat(...Object.values(this.visitGroups));
                this.inputChangeEvent.emit({ target: 'visitTypes', value: allVisits });
            } else {
                this.visitGroups[groupName] = [
                    <VisitName>{ name: '', scheduled: false, sortOrder: 1, groupName: groupName },
                ];
            }
            this.showVisitGroupField = false;
            this.cohortExists = false;
        } else this.cohortExists = true;
    }

    handleAvailableTravelOptions(value: SupportTier) {
        //disable travel options
        switch (value) {
            case SupportTier.fullService:
                //show travel options
                this.showTravelOptions.set(true);
                //enable all options
                this.travelOptionsForView?.forEach((val) => (val.disabled = false));
                break;
            case SupportTier.limitedService:
                //show travel options
                this.showTravelOptions.set(true);
                //disable Flight and Train options in TravelOptions
                this.travelOptionsForView
                    ?.filter((val) => val.viewValue === 'Flights' || val.viewValue === 'Trains')
                    ?.forEach((val) => (val.disabled = true));

                if (!!this.travelOptionsFormGroup?.controls?.travelOptionsDropdown?.value && !!this.content) {
                    //TravelOptionsDropdown consists of an array of numbers, Flight = 1, Train = 2
                    const limitedServiceTravelOptions: number[] =
                        this.travelOptionsFormGroup?.controls?.travelOptionsDropdown?.value.filter((v) => v > 2);
                    // emit only when travel options were changed
                    if (
                        limitedServiceTravelOptions.length !==
                        this.travelOptionsFormGroup?.controls?.travelOptionsDropdown?.value.length
                    ) {
                        this.stateService
                            .getStudy()
                            .pipe(
                                takeUntil(this.componentDestroyed$),
                                tap(() => {
                                    this.emitTravelOptions(limitedServiceTravelOptions, { id: 'studyTravelOptions' });
                                })
                            )
                            .subscribe();
                        this.travelOptionsFormGroup.controls.travelOptionsDropdown.setValue(
                            limitedServiceTravelOptions
                        );
                    }
                }
                break;
            case SupportTier.payment:
                //hide travel options
                this.showTravelOptions.set(false);
                //can enable all options, won't matter as we can't pick travel options
                this.travelOptionsForView?.forEach((val) => (val.disabled = false));
                break;
        }
        //handle travelOptionsFormGroup.controls.travelOptionsDropdown validation
        this.handleTravelOptionsDropdownValidation(value);
    }

    ngOnDestroy() {
        this.eventServiceSubscription.unsubscribe();
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }

    mapVisitsByGroup() {
        this.visitGroups = {};
        this.content?.visitTypes?.forEach((visit) => {
            if (!visit.groupName) visit.groupName = 'Visit Schedule';
            if (this.visitGroups[visit.groupName]) {
                if (!this.visitGroups[visit.groupName].includes(visit)) this.visitGroups[visit.groupName].push(visit);
            } else this.visitGroups[visit.groupName] = [visit];
        });
    }

    getVistGroupNamesAsArray(): string[] {
        return Object.keys(this.visitGroups);
    }

    getVistNamesByGroupName(groupName: string): VisitName[] {
        return this.visitGroups[groupName];
    }

    removeVisitGroup(groupName: string) {
        delete this.visitGroups[groupName];
        let visits = <VisitName[]>[].concat(...Object.values(this.visitGroups));
        this.inputChangeEvent.emit({ target: 'visitTypes', value: visits });
    }

    isValidVisitsFormGroup(): boolean {
        let isValid = true;
        if (this.getVistGroupNamesAsArray().length > 0) {
            this.getVistGroupNamesAsArray().forEach((groupName) => {
                if (this.parentFormGroup.contains(groupName + 'Group')) {
                    if (!this.parentFormGroup.controls[groupName + 'Group'].valid) {
                        isValid = false;
                    }
                } else {
                    isValid = false;
                }
            });
        }

        return isValid;
    }

    openVisitMappingModal() {
        let mappingDialog = this.dialog.open<MedpaceVisitMappingModalComponent>(MedpaceVisitMappingModalComponent, {
            id: 'MappingDialog',
            width: '80%',
            maxWidth: 600,
            minWidth: 360,
            disableClose: true,
            data: { visits: this.content?.visitTypes },
        });

        mappingDialog
            .afterClosed()
            .pipe(
                tap(({ mappedVisits, id }) => {
                    if (mappedVisits && id) this.inputChangeEvent.emit({ target: id, value: mappedVisits });
                }),
                take(1)
            )
            .subscribe();
    }

    initializePersonsArrays() {
        if (!!this.content) {
            if (this.content?.globalCtm) {
                this.addPersonsArray('globalCtm', this.content?.globalCtm);
            }

            if (this.content?.regionalCtms) {
                this.addPersonsArray('regionalCtms', this.content?.regionalCtms);
            }

            if (this.content?.pc) {
                this.addPersonsArray('pc', this.content?.pc);
            }

            if (this.content?.globalRsm) {
                this.addPersonsArray('globalRsm', this.content?.globalRsm);
            }

            if (this.content?.prm) {
                this.addPersonsArray('prm', this.content?.prm);
            }
        }
    }

    addEmptyPersonControl(roleName: string) {
        let formGroup = this.parentFormGroup.controls[roleName + 'FormGroup'] as FormGroup;
        let array = formGroup.controls.formArray as FormArray;

        array.push(
            this.formBuilder.group({
                fullName: new FormControl('', [Validators.required, maxLengthValidator(150)]),
                regionsDropdown: new FormControl(''),
            })
        );
    }

    public removePerson(roleName: string, index: number) {
        let formGroup = this.parentFormGroup.controls[roleName + 'FormGroup'] as FormGroup;
        let array = formGroup.controls.formArray as FormArray;
        array.removeAt(index);

        this.inputChangeEvent.emit({ target: roleName + '_' + index, value: null });
    }

    addPersonsArray(roleName: string, persons: ClinTrakPersonViewModel[]): void {
        let newFormGroup = this.formBuilder.group({
            formArray: new FormArray<FormControl<string>>([]),
        });

        this.parentFormGroup.addControl(roleName + 'FormGroup', newFormGroup);
        let formGroup = this.parentFormGroup.controls[roleName + 'FormGroup'] as FormGroup;
        let array = formGroup.controls.formArray as FormArray;

        array.clear();

        persons?.forEach((globalCtm, i) => {
            array.push(
                this.formBuilder.group({
                    fullName: new FormControl<string>('', [Validators.required, maxLengthValidator(150)]),
                    regionsDropdown: new FormControl<string>(''),
                })
            );
        });
    }

    initializeFormGroup() {
        this.travelOptionsFormGroup = this.formBuilder.group({
            travelOptionsDropdown: ['', Validators.required],
        });

        this.datesFormGroup = new FormGroup({
            dateFPFV: new PersistentFormControl(<Moment>undefined, [Validators.required]),
            dateLPLV: new PersistentFormControl(<Moment>undefined, [Validators.required]),
        });
        this.datesFormGroup.controls.dateLPLV.valueChanges
            .pipe(
                takeUntil(this.componentDestroyed$),
                tap((_) => {
                    this.setPassportDatesValidation();
                })
            )
            .subscribe();

        if (this.parentFormGroup) {
            this.parentFormGroup.addControl('countriesDropdown', this.countriesFormGroup);
            this.parentFormGroup.addControl('travelOptionsFormGroup', this.travelOptionsFormGroup);
            this.parentFormGroup.addControl('datesFormGroup', this.datesFormGroup);
            this.parentFormGroup.addControl(
                'sitesControl',
                new FormControl('', [Validators.required, NumberFormatValidator])
            );
            this.parentFormGroup.addControl(
                'numPatients',
                new FormControl('', [Validators.required, NumberFormatValidator])
            );
        }
    }

    setValues() {
        if (this.content) {
            const tempCountries = this.content?.studyCountries?.map((x) => x.countryId);
            this.countriesFormGroup.controls.countriesDropdown.setValue(tempCountries);

            const tempTravelOptions = this.content?.studyTravelOptions?.map((x) => x.id);
            this.travelOptionsFormGroup.controls.travelOptionsDropdown.setValue(tempTravelOptions);

            this.datesFormGroup.controls.dateFPFV.setValue(this.content.fpfv);
            this.datesFormGroup.controls.dateLPLV.setValue(this.content.lplv);

            this.setRoleFormArray('globalCtm', this.content?.globalCtm);
            this.setRoleFormArray('regionalCtms', this.content?.regionalCtms);
            this.setRoleFormArray('pc', this.content?.pc);
            this.setRoleFormArray('globalRsm', this.content?.globalRsm);
            this.setRoleFormArray('prm', this.content?.prm);

            this.parentFormGroup.controls.sitesControl.setValue(this.content.numSites);
            this.parentFormGroup.controls.numPatients.setValue(this.content.numPatients);
        }
    }

    setRoleFormArray(roleName: string, persons: ClinTrakPersonViewModel[]): void {
        let formGroup = this.parentFormGroup?.controls[roleName + 'FormGroup'] as FormGroup;
        let array = formGroup?.controls.formArray as FormArray<FormGroup>;

        if (array?.controls.length > 0 && persons?.length > 0) {
            array?.controls.forEach((controlGroup, index) => {
                switch (roleName) {
                    case 'pc':
                        controlGroup.controls.fullName.setValue(`${persons[index]?.fullName}`);
                        controlGroup.controls.regionsDropdown.setValue(persons[index]?.regionsId);
                        this.emitLocaleData(persons, index, roleName);
                        break;
                    case 'regionalCtms':
                        controlGroup.controls.fullName.setValue(`${persons[index]?.fullName}`);
                        controlGroup.controls.regionsDropdown.setValue(persons[index]?.regionsId);
                        this.emitLocaleData(persons, index, roleName);
                        break;
                    default:
                        controlGroup.controls.fullName.setValue(`${persons[index]?.fullName}`);
                        break;
                }
            });
        }
    }

    emitLocaleData(persons: ClinTrakPersonViewModel[], index: number, roleName: string) {
        if (
            persons[index]?.regionsId?.length > 0 &&
            (!persons[index]?.studyPersonLocales || persons[index]?.studyPersonLocales.length === 0)
        ) {
            let tempArray = [];

            persons[index]?.regionsId.forEach((id: string) => {
                let newLocaleModel = <StudyPersonLocaleViewModel>{
                    studyPersonId: '0',
                    regionId: id,
                };

                tempArray.push(newLocaleModel);
            });
            this.inputChangeEvent.emit({
                target: roleName + '_' + index + '-Regions',
                value: tempArray,
            });
        }
    }

    setPassportDatesValidation() {
        if (this.datesFormGroup.controls.dateLPLV.value?.toDate()) {
            this.datesFormGroup.controls.dateFPFV.setValidators([
                Validators.required,
                beforeDateValidator(
                    this.datesFormGroup.controls.dateLPLV.value.toDate(),
                    'FPFV date should be smaller than LPLV date!'
                ),
            ]);
            this.datesFormGroup.controls.dateFPFV.updateValueAndValidity();
        }
    }

    tryRemoveVisitName(removeOptionData: RemoveOptionData<VisitName>, index?: number) {
        let removeOptionWithIndex = <RemoveOptionDataWithIndex>{
            options: removeOptionData.options,
            optionToRemove: removeOptionData.optionToRemove,
            index: index,
        };
        this.tryRemoveVisitNameSubject.next(removeOptionWithIndex);
    }
    tryRemoveExpenseType(removeOptionData: RemoveOptionData<ExpenseType>) {
        this.tryRemoveExpenseTypeSubject.next(removeOptionData);
    }

    emitVisitScheduleChange: (value: VisitName[], target: Target, groupName: string) => void = (
        value: VisitName[],
        target: Target,
        groupName: string
    ) => {
        this.visitGroups[groupName] = value;
        let allVisits = <VisitName[]>[].concat(...Object.values(this.visitGroups));
        this.inputChangeEvent.emit({ target: target.id, value: allVisits });
    };

    emitChange: (value: any, target: Target) => void = (value: string, target: Target) => {
        this.inputChangeEvent.emit({ target: target.id, value: value });
    };

    emitTravelOptions: (value: number[], target: Target) => void = (value: number[], target: Target) => {
        this.selectedTravelOptions = [];

        value.forEach((id) => {
            let travelOption = this.travelOptions.find((element) => element.id === id);
            this.selectedTravelOptions.push(travelOption);
        });
        this.inputChangeEvent.emit({ target: target.id, value: this.selectedTravelOptions });
    };

    handleTravelOptionsDropdownValidation(value: SupportTier) {
        switch (value) {
            case SupportTier.fullService:
            case SupportTier.limitedService:
                this.travelOptionsFormGroup?.controls?.travelOptionsDropdown?.addValidators([Validators.required]);
                break;
            case SupportTier.payment:
                this.travelOptionsFormGroup?.controls?.travelOptionsDropdown?.clearValidators();
                break;
        }
        this.travelOptionsFormGroup?.controls?.travelOptionsDropdown.updateValueAndValidity();
    }

    countriesSelectionChange(event: MatSelectChange, id: string) {
        this.selectedCountries = [];

        event.value.forEach((id: string) => {
            let country = this.countries.find((element) => element.countryId === id);
            this.selectedCountries.push(country);
        });
        this.inputChangeEvent.emit({ target: id, value: this.selectedCountries });
    }

    regionsSelectionChange(event: MatSelectChange, id: string) {
        this.selectedRegions = [];
        let tempArray = [];
        let personType = id.substring(0, id.indexOf('_'));
        let index = +id.substring(id.indexOf('_') + 1, id.indexOf('-'));

        event.value.forEach((id: string) => {
            let region = this.regions.find((element) => element.regionId === id);
            let existingStudyPerson = this.content[personType][index];
            let spL = <StudyPersonLocaleViewModel>(
                existingStudyPerson?.studyPersonLocales?.find((x) => x.regionId == region.regionId)
            );

            if (spL) tempArray.push(spL);
            else {
                let newLocaleModel = <StudyPersonLocaleViewModel>{
                    studyPersonId: existingStudyPerson?.id,
                    regionId: region.regionId,
                };

                tempArray.push(newLocaleModel);
            }
        });
        this.inputChangeEvent.emit({ target: id, value: tempArray });
    }

    getFormArray(role: string): FormArray<FormGroup> {
        switch (role) {
            case 'globalCtm':
                return this.getFormGroup('globalCtmFormGroup')?.controls.formArray as FormArray<FormGroup>;
            case 'regionalCtms':
                return this.getFormGroup('regionalCtmsFormGroup')?.controls.formArray as FormArray<FormGroup>;
            case 'pc':
                return this.getFormGroup('pcFormGroup')?.controls.formArray as FormArray<FormGroup>;
            case 'globalRsm':
                return this.getFormGroup('globalRsmFormGroup')?.controls.formArray as FormArray<FormGroup>;
            case 'prm':
                return this.getFormGroup('prmFormGroup')?.controls.formArray as FormArray<FormGroup>;
        }
    }

    getFormGroup(formGroupName: string): FormGroup {
        return this.parentFormGroup.controls[formGroupName] as FormGroup;
    }

    getFullNameFormControl(personFormGroup: FormGroup): FormControl<string> {
        return personFormGroup.controls.fullName as FormControl<string>;
    }

    getRegionsFormControl(personFormGroup: FormGroup): FormControl {
        return personFormGroup.controls.regionsDropdown as FormControl;
    }
}
