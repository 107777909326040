import { safeUTC } from '@app/extensions/moment-extensions';
import { MdsRadioButton } from '@medpacesoftwaredevelopment/designsystem/interfaces/mds-radio-button';
import { Moment } from 'moment';
import { ClinTrakPersonViewModel, ClinTrakStudyVisitViewModel, CountryRegionCtsmViewModel } from './clintrak';
import { iDraggableObject, iDraggableWithCheckboxesObject } from './interfaces/iDraggableObject';
import { Patient } from './patient';
import { ISite, SiteDataModel } from './site';

export interface IStudy {
    id: number;
    sponsor: string;
    protocol: string;
    studyCode: string;
    logoUri: string;
    studyInfo: StudyInformation;
    isActive: boolean;
    sites: ISite[];
    supportTier: SupportTier;
    region: string;
    numSites: number;
    numPatients: number;
    isFromClinTrak: boolean;
    isApproved: boolean;
}

export interface Study_DTO {
    id: number;
    sponsor: string;
    protocol: string;
    studyCode: string;
    logoUri: string;
    studyInfo: StudyInformation_DTO;
    isActive: boolean;
    sites: ISite[];
    supportTier: SupportTier;
    region: string;
    numSites: number;
    numPatients: number;
    isFromClinTrak: boolean;
    isApproved: boolean;
}

export function mapStudy_DTO_To_Study(dto: Study_DTO): Study {
    return <Study>{
        ...dto,
        studyInfo: dto?.studyInfo ? mapStudyInformation_DTO_To_StudyInformation(dto?.studyInfo) : '',
    };
}

export interface IActiveStudySummaryViewModel {
    Id: number;
    protocol: string;
    sponsor: string;
    ctm: string;
    prm: string;
    indication: string;
    status: string;
    requests: number;
    isFromClinTrak: boolean;
    isApproved: boolean;
}

export class ActiveStudySummaryViewModel implements IActiveStudySummaryViewModel {
    Id: number;
    protocol: string;
    sponsor: string;
    ctm: string;
    prm: string;
    indication: string;
    status: string;
    requests: number;
    isFromClinTrak: boolean;
    isApproved: boolean;
}

export class Study implements IStudy {
    id: number;
    sponsor: string;
    protocol: string;
    studyCode: string;
    logoUri: string;
    studyInfo: StudyInformation;
    isActive: boolean;
    sites: ISite[];
    supportTier: SupportTier;
    region: string;
    numSites: number;
    numPatients: number;
    isFromClinTrak: boolean;
    isApproved: boolean;
    indication: string;
}
export interface CreateUpdateStudyDataModel {
    id: number;
    sponsor: string;
    protocol: string;
    code: string;
    supportTierId: number;
    isActive: boolean;
    studyPersons: StudyPersonsDataModel;
    studyMappedVisits: StudyMappedVisitDto[];
    hasMultipleVisitSchedules: boolean;
    indication: string;
    status: string;
    firstPatientFirstVisit: Moment;
    lastPatientLastVisit: Moment;
    logo: any; //byte array
    logoUri: string;
    patient: Patient[];
    siteCrcStudy: any;
    visitNames: VisitName[];
    expenseTypes: ExpenseType[];
    numSites: number;
    numPatients: number;
    studyRegions: StudyRegionDataModel[];
    studyCountries: StudyCountryDataModel[];
    studyTravelOptions: StudyTravelOptionDataModel[];
    isFromClinTrak: boolean;
    isApproved: boolean;
}

export interface StudyPersonsDataModel {
    studyId: number;
    globalCtm: ClinTrakPersonViewModel[];
    regionalCtms: ClinTrakPersonViewModel[];
    pc: ClinTrakPersonViewModel[];
    globalRsm: ClinTrakPersonViewModel[];
    prm: ClinTrakPersonViewModel[];
}

export interface StudyMappedVisitDto {
    studyId: number;
    visitName: VisitName;
    patientVisitsMaster: ClinTrakStudyVisitViewModel[];
}

export interface CreateUpdateStudyDataModel_DTO {
    id: number;
    sponsor: string;
    protocol: string;
    code: string;
    supportTierId: number;
    isActive: boolean;
    studyPersons: StudyPersonsDataModel;
    studyMappedVisits: StudyPersonsDataModel[];
    hasMultipleVisitSchedules: boolean;
    firstPatientFirstVisit: string;
    lastPatientLastVisit: string;
    logo: any; //byte array
    patient: Patient[];
    siteCrcStudy: any;
    visitNames: VisitName[];
    numSites: number;
    numPatients: number;
    studyRegions: StudyRegionDataModel[];
    studyCountries: StudyCountryDataModel[];
    studyTravelOptions: StudyTravelOptionDataModel[];
    isFromClinTrak: boolean;
    isApproved: boolean;
}

export interface StudyDataModel extends CreateUpdateStudyDataModel {
    sites: SiteDataModel[];
}

/**
 * Get DTO, has additional sites field
 */
export interface StudyDataModel_DTO extends CreateUpdateStudyDataModel_DTO {
    sites: SiteDataModel[];
}

export function mapStudyDataModel_DTO_To_StudyDataModel(dto: StudyDataModel_DTO): StudyDataModel {
    return <StudyDataModel>(<unknown>{
        ...dto,
        firstPatientFirstVisit: safeUTC(dto.firstPatientFirstVisit),
        lastPatientLastVisit: safeUTC(dto.lastPatientLastVisit),
    });
}

export function mapStudyDataModel_DTO_To_StudyDataModel_CreateUpdate(
    dto: CreateUpdateStudyDataModel_DTO
): CreateUpdateStudyDataModel {
    return <CreateUpdateStudyDataModel>(<unknown>{
        ...dto,
        firstPatientFirstVisit: safeUTC(dto.firstPatientFirstVisit),
        lastPatientLastVisit: safeUTC(dto.lastPatientLastVisit),
    });
}
export interface StudyRegion {
    name: string;
    id: number;
    studyId: number;
}
export interface StudyRegionDataModel {
    studyId: number;
    regionId: number;
    name: string;
    id: number;
}

export interface StudyCountryDataModel {
    studyId: number;
    countryRegion: string;
}

export type StudyTravelNames = 'Flights' | 'Trains' | 'Hotels' | 'Rental Cars' | 'Car Services';
export interface StudyTravelOption {
    name: string;
    id: number;
    studyId: number;
}

export interface TravelOptionViewModel {
    id: number;
    name: string;
    studyTravelOptions: StudyTravelOptionViewModel;
}

export interface StudyTravelOptionViewModel {
    studyId: number;
    travelOptionId: number;
}

export interface StudyTravelOptionDataModel {
    studyId: number;
    travelOptionId: number;
    name: string;
    id: number;
}

export interface StudyInformation {
    globalCtm: ClinTrakPersonViewModel[];
    pc: ClinTrakPersonViewModel[];
    prm: ClinTrakPersonViewModel[];
    regionalCtms: ClinTrakPersonViewModel[];
    globalRsm: ClinTrakPersonViewModel[];
    numSites: number;
    numPatients: number;
    /** First Patient First Visit */
    fpfv: Moment;
    /** Last Patient Last Visit */
    lplv: Moment;
    visitTypes: VisitName[];
    expenseTypes: ExpenseType[];
    studyRegions: StudyRegion[];
    studyCountries: CountryRegionCtsmViewModel[];
    studyTravelOptions: StudyTravelOption[];
    status: string;
    indication: string;
    studyMappedVisits: StudyMappedVisitDto[];
    hasMultipleVisitSchedules: boolean;
}

export interface StudyInformation_DTO {
    globalCtm: ClinTrakPersonViewModel[];
    pc: ClinTrakPersonViewModel[];
    prm: ClinTrakPersonViewModel[];
    regionalCtms: ClinTrakPersonViewModel[];
    globalRsm: ClinTrakPersonViewModel[];
    numSites: number;
    numPatients: number;
    /** First Patient First Visit */
    fpfv: string;
    /** Last Patient Last Visit */
    lplv: string;
    visitTypes: VisitName[];
    studyRegions: StudyRegion[];
    studyCountries: CountryRegionCtsmViewModel[];
    studyTravelOptions: StudyTravelOption[];
    status: string;
    indication: string;
}
export class StudyInformation {}

export function mapStudyInformation_DTO_To_StudyInformation(dto: StudyInformation_DTO): StudyInformation {
    return <StudyInformation>{
        ...dto,
        fpfv: safeUTC(dto.fpfv),
        lplv: safeUTC(dto.lplv),
    };
}

export interface VisitName {
    id: number;
    name: string;
    studyId: number;
    scheduled: boolean;
    sortOrder: number;
    groupName: string;
}

export class VisitName implements iDraggableWithCheckboxesObject {}

export class ExpenseType implements iDraggableObject {
    id: number;
    name: string;
    studyId: number;
    sortOrder: number;
}

export enum SupportTier {
    fullService = 1,
    limitedService = 2,
    payment = 3,
}

export const StudyTierFields = {
    fullService: {
        title: 'Full Service',
        description: 'Flight/Train, Lodging and Ground Transportation + Payment Program',
        value: SupportTier.fullService,
        selected: undefined,
    },
    limitedService: {
        title: 'Limited Service',
        description: 'Ground Transportation and/or Lodging + Payment Program',
        value: SupportTier.limitedService,
        selected: undefined,
    },
    payment: {
        title: 'Payment Program Only',
        description: 'Direct Deposit and Debit Card',
        value: SupportTier.payment,
        selected: undefined,
    },
};

export const StudyRadioButtons: MdsRadioButton[] = [
    {
        label: 'Full Service - Flight, Train, Lodging and Ground Transportation + Payment Program',
        value: SupportTier.fullService,
        name: 'fullService',
        id: 'fullService',
    },
    {
        label: 'Limited Service - Ground Transportation and/or Lodging + Payment Program',
        value: SupportTier.limitedService,
        name: 'limitedService',
        id: 'limitedService',
    },
    {
        label: 'Payment Program Only - Direct Deposit and Debit Card',
        value: SupportTier.payment,
        name: 'payment',
        id: 'payment',
    },
];

export interface IStudySummaryViewModel {
    id: number;
    sponsor?: string | null;
    protocol?: string | null;
    code?: string | null;
    isActive?: boolean | null;
    globalCtm?: string | null;
    pc?: string | null;
    prm?: string | null;
    firstPatientFirstVisit?: Date | null;
    lastPatientLastVisit?: Date | null;
    supportTierId: number;
    siteCount: number;
    patientCount: number;
    requestCount: number;
    isApproved: boolean;
}

export class StudySummaryViewModel implements IStudySummaryViewModel {
    id!: number;
    sponsor?: string | null;
    protocol?: string | null;
    code?: string | null;
    isActive?: boolean | null;
    globalCtm?: string | null;
    pc?: string | null;
    prm?: string | null;
    firstPatientFirstVisit?: Date | null;
    lastPatientLastVisit?: Date | null;
    supportTierId!: number;
    siteCount!: number;
    patientCount!: number;
    requestCount!: number;
    isApproved: boolean;

    constructor(data?: IStudySummaryViewModel) {
        if (data) {
            for (const property in data) {
                if (data.hasOwnProperty(property)) (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.id = _data['id'] !== undefined ? _data['id'] : <any>null;
            this.sponsor = _data['sponsor'] !== undefined ? _data['sponsor'] : <any>null;
            this.protocol = _data['protocol'] !== undefined ? _data['protocol'] : <any>null;
            this.code = _data['code'] !== undefined ? _data['code'] : <any>null;
            this.isActive = _data['isActive'] !== undefined ? _data['isActive'] : <any>null;
            this.globalCtm = _data['globalCtm'] !== undefined ? _data['globalCtm'] : <any>null;
            this.pc = _data['pc'] !== undefined ? _data['pc'] : <any>null;
            this.prm = _data['prm'] !== undefined ? _data['prm'] : <any>null;
            this.firstPatientFirstVisit = _data['firstPatientFirstVisit']
                ? new Date(_data['firstPatientFirstVisit'].toString())
                : <any>null;
            this.lastPatientLastVisit = _data['lastPatientLastVisit']
                ? new Date(_data['lastPatientLastVisit'].toString())
                : <any>null;
            this.supportTierId = _data['supportTierId'] !== undefined ? _data['supportTierId'] : <any>null;
            this.siteCount = _data['siteCount'] !== undefined ? _data['siteCount'] : <any>null;
            this.patientCount = _data['patientCount'] !== undefined ? _data['patientCount'] : <any>null;
            this.requestCount = _data['requestCount'] !== undefined ? _data['requestCount'] : <any>null;
            this.isApproved = _data['isApproved'] !== undefined ? _data['isApproved'] : <any>null;
        }
    }

    static fromJS(data: any): StudySummaryViewModel {
        data = typeof data === 'object' ? data : {};
        let result = new StudySummaryViewModel();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data['id'] = this.id !== undefined ? this.id : <any>null;
        data['sponsor'] = this.sponsor !== undefined ? this.sponsor : <any>null;
        data['protocol'] = this.protocol !== undefined ? this.protocol : <any>null;
        data['code'] = this.code !== undefined ? this.code : <any>null;
        data['isActive'] = this.isActive !== undefined ? this.isActive : <any>null;
        data['globalCtm'] = this.globalCtm !== undefined ? this.globalCtm : <any>null;
        data['pc'] = this.pc !== undefined ? this.pc : <any>null;
        data['prm'] = this.prm !== undefined ? this.prm : <any>null;
        data['firstPatientFirstVisit'] = this.firstPatientFirstVisit
            ? this.firstPatientFirstVisit.toISOString()
            : <any>null;
        data['lastPatientLastVisit'] = this.lastPatientLastVisit ? this.lastPatientLastVisit.toISOString() : <any>null;
        data['supportTierId'] = this.supportTierId !== undefined ? this.supportTierId : <any>null;
        data['siteCount'] = this.siteCount !== undefined ? this.siteCount : <any>null;
        data['patientCount'] = this.patientCount !== undefined ? this.patientCount : <any>null;
        data['requestCount'] = this.requestCount !== undefined ? this.requestCount : <any>null;
        data['isApproved'] = this.isApproved !== undefined ? this.isApproved : <any>null;
        return data;
    }
}
