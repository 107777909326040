import {
    AbstractControlOptions,
    AsyncValidatorFn,
    FormArray,
    FormControl,
    FormGroup,
    ValidatorFn,
} from '@angular/forms';
import { PersistentFormControl, PersistentFormGroup } from '@utility/persistent-forms';
import { Moment } from 'moment';

type PassportInfoFormControls = Partial<{
    passportCountry: PersistentFormControl<string | null>;
    passportExpiration: PersistentFormControl<Moment | null>;
    passportIssue: PersistentFormControl<Moment | null>;
    passportNum: PersistentFormControl<string | null>;
}>;
export class PassportInfoFormGroupType extends PersistentFormGroup<PassportInfoFormControls> {
    defaultValue: typeof this.value;
    constructor(
        controls: PassportInfoFormControls,
        validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
        asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
    ) {
        super(controls, validatorOrOpts, asyncValidator);
        this.defaultValue = this.value;
    }
}
type AirlineFormControls = Partial<{
    airlinePrimary: FormControl<string | null>;
    airlineRewardsPrimary: FormControl<string | null>;
    airlineRewardsSecondary: FormControl<string | null>;
    airlineSecondary: FormControl<string | null>;
    airlineSpecialNeeds: FormControl<string | null>;
    seatPreference: FormControl<string | null>;
    knownTravelerNum: FormControl<string | null>;
}>;

export class AirlineFormGroupType extends FormGroup<AirlineFormControls> {
    defaultValue: typeof this.value;
    constructor(
        controls: AirlineFormControls,
        validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
        asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
    ) {
        super(controls, validatorOrOpts, asyncValidator);
        this.defaultValue = this.value;
    }
}
type LodgingFormControls = Partial<{
    hotelChainPrimary: FormControl<string | null>;
    lodgingRoomPreference: FormControl<string | null>;
    allergies: FormControl<string | null>;
    lodgingSpecialNeeds: FormControl<string | null>;
}>;

export class LodgingFormGroupType extends FormGroup<LodgingFormControls> {
    defaultValue: typeof this.value;
    constructor(
        controls: LodgingFormControls,
        validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
        asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
    ) {
        super(controls, validatorOrOpts, asyncValidator);
        this.defaultValue = this.value;
    }
}
type CaregiverEditFormControls = Partial<{
    id: FormControl<number | null>;
    patientEditRequestId: FormControl<number | null>;
    address1: FormControl<string | null>;
    address2: FormControl<string | null>;
    caregiverId: FormControl<number | null>;
    addressSameAsPatient: FormControl<boolean | null>;
    city: FormControl<string | null>;
    country: FormControl<string | null>;
    phone: FormControl<string | null>;
    preferredLang: FormControl<string | null>;
    state: FormControl<string | null>;
    zipcode: FormControl<string | null>;
    //international
    passportInfo: PassportInfoFormGroupType;
}>;

export class CaregiverEditFormType extends FormGroup<CaregiverEditFormControls> {
    defaultValue: typeof this.value;
    constructor(
        controls: CaregiverEditFormControls,
        validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
        asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
    ) {
        super(controls, validatorOrOpts, asyncValidator);
        this.defaultValue = this.value;
    }
}
type PatientEditFormControls = Partial<{
    id: FormControl<number | null>;
    address1: FormControl<string | null>;
    address2: FormControl<string | null>;
    patientId: FormControl<number | null>;
    city: FormControl<string | null>;
    country: FormControl<string | null>;
    phone: FormControl<string | null>;
    preferredLang: FormControl<string | null>;
    state: FormControl<string | null>;
    zipcode: FormControl<string | null>;
    profileChangeDetails: FormControl<string | null>;
    caregiverEditRequest: FormArray<CaregiverEditFormType>;
    airlineEditRequest: AirlineFormGroupType;
    trainEditRequest: FormGroup<{ trainSpecialNeeds: FormControl<string | null> }>;
    passportInfo: PassportInfoFormGroupType;
    lodgingEditRequest: LodgingFormGroupType;
    rentalCarEditRequest: FormGroup<{ rentalCarFrequentTravelerNum: FormControl<string | null> }>;
    carServiceEditRequest: FormGroup<{ groundSpecialNeeds: FormControl<string | null> }>;
}>;

export class PatientEditFormType extends FormGroup<PatientEditFormControls> {
    defaultValue: typeof this.value;
    constructor(
        controls: PatientEditFormControls,
        validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
        asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
    ) {
        super(controls, validatorOrOpts, asyncValidator);
        this.defaultValue = this.value;
    }
}
