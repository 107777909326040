export enum DateFormat {
    dateOnly = 'dd-MMM-YYYY',
    dbDateTime = 'YYYY-MM-dd hh:mm:ss:sss',
    dateTime24 = 'dd-MMM-YYYY, HH:mm',
    dateTime12 = 'dd-MMM-YYYY, hh:mm a',
    timeOnly = 'HH:mm',
}

export enum MomentFormat {
    dateOnly = 'DD-MMM-YYYY',
    dbDateTime = 'YYYY-MM-DD hh:mm:ss:sss',
    dateTime24 = 'DD-MMM-YYYY, HH:mm',
    dateTime12 = 'DD-MMM-YYYY, hh:mm a',
    timeOnly = 'HH:mm',
}
