<div
    *ngIf="
        isEditing && {
            tryRemoveVisitName: tryRemoveVisitName$ | async,
            tryRemoveExpenseType: tryRemoveExpenseType$ | async,
        }
    "
    class="o-grid"
>
    <div class="o-grid__row" *ngIf="this.content?.globalCtm && getFormArray('globalCtm')?.controls">
        <div class="o-grid__item">
            <mds-expansion-panel
                [panelTitle]="'Global CTM'"
                [description]="''"
                icon="people"
                [attr.data-cy]="'globalCtmExpansion'"
            >
                <ng-container *ngFor="let globalCtmFormGroup of getFormArray('globalCtm')?.controls; let i = index">
                    <div class="o-grid__row">
                        <div class="o-grid__item">
                            <mds-text-field
                                id="globalCtm{{ i }}"
                                [formCtrl]="getFullNameFormControl(globalCtmFormGroup)"
                                placeholder="Type here..."
                                appearance="outline"
                                inputType="text"
                                floatLabel="always"
                                label="Global CTM"
                                width="100%"
                                [required]="true"
                                (input)="emitChange($event.target.value, { id: 'globalCtm_' + i })"
                            ></mds-text-field>
                        </div>
                        <div class="delete-icon width-10">
                            <a id="globalCtmTrash{{ i }}" (click)="removePerson('globalCtm', i)"
                                ><i class="fas fa-trash icon"></i
                            ></a>
                        </div>
                    </div>
                </ng-container>
                <ng-container
                    [ngTemplateOutlet]="AddPerson"
                    [ngTemplateOutletContext]="{
                        $implicit: {
                            roleName: 'globalCtm',
                        },
                    }"
                ></ng-container>
            </mds-expansion-panel>
        </div>
    </div>

    <div class="o-grid__row" *ngIf="this.content?.regionalCtms && getFormArray('regionalCtms')?.controls">
        <div class="o-grid__item">
            <mds-expansion-panel
                [panelTitle]="'Regional CTMs'"
                [description]="''"
                icon="people"
                [attr.data-cy]="'regionalCtmsExpansion'"
            >
                <ng-container
                    *ngFor="let regionalCtmFormGroup of getFormArray('regionalCtms')?.controls; let i = index"
                >
                    <div class="o-grid__row">
                        <div class="o-grid__item half">
                            <mds-text-field
                                id="regionalCtms{{ i }}"
                                [formCtrl]="getFullNameFormControl(regionalCtmFormGroup)"
                                placeholder="Type here..."
                                appearance="outline"
                                inputType="text"
                                floatLabel="always"
                                label="Regional CTMs"
                                width="100%"
                                [required]="true"
                                (input)="emitChange($event.target.value, { id: 'regionalCtms_' + i })"
                            ></mds-text-field>
                        </div>
                        <div class="o-grid__item third">
                            <mds-dropdown
                                id="regionalCtmsRegions{{ i }}"
                                [formCtrl]="getRegionsFormControl(regionalCtmFormGroup)"
                                floatLabel="always"
                                appearance="outline"
                                [multiple]="true"
                                [closeOnPageScroll]="true"
                                [label]="'Regions'"
                                [options]="regionsForView"
                                [required]="false"
                                [disabled]="false"
                                [defaultErrorSpacing]="false"
                                [placeholder]="'Select Region'"
                                [hasSelectAll]="false"
                                [filterable]="true"
                                [autofocusFilter]="false"
                                (selectionChange)="regionsSelectionChange($event, 'regionalCtms_' + i + '-Regions')"
                            ></mds-dropdown>
                        </div>
                        <div class="delete-icon width-10">
                            <a id="regionalCtmsTrash{{ i }}" (click)="removePerson('regionalCtms', i)"
                                ><i class="fas fa-trash icon"></i
                            ></a>
                        </div>
                    </div>
                </ng-container>
                <ng-container
                    [ngTemplateOutlet]="AddPerson"
                    [ngTemplateOutletContext]="{
                        $implicit: {
                            roleName: 'regionalCtms',
                        },
                    }"
                ></ng-container>
            </mds-expansion-panel>
        </div>
    </div>

    <div class="o-grid__row" *ngIf="this.content?.pc && getFormArray('pc')?.controls">
        <div class="o-grid__item">
            <mds-expansion-panel [panelTitle]="'PC'" [description]="''" icon="people" [attr.data-cy]="'pcExpansion'">
                <ng-container *ngFor="let pcFormGroup of getFormArray('pc')?.controls; let i = index">
                    <div class="o-grid__row">
                        <div class="o-grid__item half">
                            <mds-text-field
                                id="pc{{ i }}"
                                [formCtrl]="getFullNameFormControl(pcFormGroup)"
                                placeholder="Type here..."
                                appearance="outline"
                                inputType="text"
                                floatLabel="always"
                                label="PC"
                                width="100%"
                                [required]="true"
                                (input)="emitChange($event.target.value, { id: 'pc_' + i })"
                            ></mds-text-field>
                        </div>
                        <div class="o-grid__item third">
                            <mds-dropdown
                                id="pcRegions{{ i }}"
                                [formCtrl]="getRegionsFormControl(pcFormGroup)"
                                floatLabel="always"
                                appearance="outline"
                                [multiple]="true"
                                [closeOnPageScroll]="true"
                                [label]="'Regions'"
                                [options]="regionsForView"
                                [required]="false"
                                [disabled]="false"
                                [defaultErrorSpacing]="false"
                                [placeholder]="'Select Region'"
                                [hasSelectAll]="false"
                                [filterable]="true"
                                [autofocusFilter]="false"
                                (selectionChange)="regionsSelectionChange($event, 'pc_' + i + '-Regions')"
                            ></mds-dropdown>
                        </div>
                        <div class="delete-icon width-10">
                            <a id="pcTrash{{ i }}" (click)="removePerson('pc', i)"><i class="fas fa-trash icon"></i></a>
                        </div>
                    </div>
                </ng-container>

                <ng-container
                    [ngTemplateOutlet]="AddPerson"
                    [ngTemplateOutletContext]="{
                        $implicit: {
                            roleName: 'pc',
                        },
                    }"
                ></ng-container>
            </mds-expansion-panel>
        </div>
    </div>

    <div class="o-grid__row">
        <div class="o-grid__item">
            <mds-expansion-panel
                [panelTitle]="'Global RSM'"
                [description]="''"
                icon="people"
                [attr.data-cy]="'globalRsmExpansion'"
            >
                <ng-container *ngFor="let globalRsmFormGroup of getFormArray('globalRsm')?.controls; let i = index">
                    <div class="o-grid__row">
                        <div class="o-grid__item">
                            <mds-text-field
                                id="globalRsm{{ i }}"
                                [formCtrl]="getFullNameFormControl(globalRsmFormGroup)"
                                placeholder="Type here..."
                                appearance="outline"
                                inputType="text"
                                floatLabel="always"
                                label="Global Rsm"
                                width="100%"
                                [required]="true"
                                (input)="emitChange($event.target.value, { id: 'globalRsm_' + i })"
                            ></mds-text-field>
                        </div>
                        <div class="delete-icon width-10">
                            <a id="globalRsmTrash{{ i }}" (click)="removePerson('globalRsm', i)"
                                ><i class="fas fa-trash icon"></i
                            ></a>
                        </div>
                    </div>
                </ng-container>
                <ng-container
                    [ngTemplateOutlet]="AddPerson"
                    [ngTemplateOutletContext]="{
                        $implicit: {
                            roleName: 'globalRsm',
                        },
                    }"
                ></ng-container>
            </mds-expansion-panel>
        </div>
    </div>

    <div class="o-grid__row">
        <div class="o-grid__item">
            <mds-expansion-panel [panelTitle]="'PRM'" [description]="''" icon="people" [attr.data-cy]="'prmExpansion'">
                <ng-container *ngFor="let prmFormGroup of getFormArray('prm')?.controls; let i = index">
                    <div class="o-grid__row">
                        <div class="o-grid__item">
                            <mds-text-field
                                id="prm{{ i }}"
                                [formCtrl]="getFullNameFormControl(prmFormGroup)"
                                placeholder="Type here..."
                                appearance="outline"
                                inputType="text"
                                floatLabel="always"
                                label="PRM"
                                width="100%"
                                [required]="true"
                                (input)="emitChange($event.target.value, { id: 'prm_' + i })"
                            ></mds-text-field>
                        </div>
                        <div class="delete-icon width-10">
                            <a id="prmTrash{{ i }}" (click)="removePerson('prm', i)"
                                ><i class="fas fa-trash icon"></i
                            ></a>
                        </div>
                    </div>
                </ng-container>
                <ng-container
                    [ngTemplateOutlet]="AddPerson"
                    [ngTemplateOutletContext]="{
                        $implicit: {
                            roleName: 'prm',
                        },
                    }"
                ></ng-container>
            </mds-expansion-panel>
        </div>
    </div>

    <ng-container *ngIf="!isClinTrakComparison">
        <div class="o-grid__row">
            <div class="o-grid__field">
                <form [formGroup]="countriesFormGroup">
                    <mds-dropdown
                        id="countriesMultiselectDropdown"
                        [formCtrl]="countriesFormGroup.controls.countriesDropdown"
                        floatLabel="always"
                        appearance="outline"
                        [multiple]="true"
                        [closeOnPageScroll]="true"
                        [label]="'Countries'"
                        [options]="countriesForView"
                        [required]="false"
                        [disabled]="false"
                        [defaultErrorSpacing]="false"
                        [placeholder]="'Select Country'"
                        [hasSelectAll]="true"
                        [filterable]="true"
                        [autofocusFilter]="false"
                        (selectionChange)="countriesSelectionChange($event, 'studyCountries')"
                    ></mds-dropdown>
                </form>
            </div>
        </div>

        <div class="o-grid__row">
            <div class="o-grid__field half">
                <mds-text-field
                    id="numSites"
                    [formGrp]="parentFormGroup"
                    formCtrlName="sitesControl"
                    placeholder="Type here..."
                    appearance="outline"
                    inputType="text"
                    floatLabel="always"
                    label="Prospected Sites"
                    width="100%"
                    [required]="true"
                    (input)="emitChange($event.target.value, { id: 'numSites' })"
                ></mds-text-field>
            </div>
            <div class="o-grid__field half">
                <mds-text-field
                    id="numPatients"
                    [formGrp]="parentFormGroup"
                    formCtrlName="numPatients"
                    placeholder="Type here..."
                    appearance="outline"
                    inputType="text"
                    floatLabel="always"
                    label="Prospected Patients"
                    width="100%"
                    [required]="true"
                    (input)="emitChange($event.target.value, { id: 'numPatients' })"
                ></mds-text-field>
            </div>
        </div>
        <form [formGroup]="datesFormGroup">
            <div class="o-grid__row">
                <div class="o-grid__item">
                    <medpace-datepicker
                        id="fpfv"
                        appearance="outline"
                        [required]="true"
                        label="FPFV"
                        [formCtrl]="datesFormGroup.controls.dateFPFV"
                        (dateChange)="emitChange($event.target.value, { id: 'fpfv' })"
                    ></medpace-datepicker>
                </div>
                <div class="o-grid__item">
                    <medpace-datepicker
                        id="lplv"
                        appearance="outline"
                        [required]="true"
                        label="LPLV"
                        [formCtrl]="datesFormGroup.controls.dateLPLV"
                        (dateChange)="emitChange($event.target.value, { id: 'lplv' })"
                    ></medpace-datepicker>
                </div>
            </div>
        </form>
        <div class="o-grid__row" *ngIf="showTravelOptions()">
            <div class="o-grid__field">
                <form [formGroup]="travelOptionsFormGroup">
                    <mds-dropdown
                        floatLabel="always"
                        [closeOnPageScroll]="false"
                        appearance="outline"
                        label="Travel options allowed by the Sponsor"
                        placeholder="Select travel options"
                        [options]="travelOptionsForView"
                        id="travelOptionsDropdown"
                        (selectionChange)="emitTravelOptions($event.value, { id: 'studyTravelOptions' })"
                        [multiple]="true"
                        formCtrlName="travelOptionsDropdown"
                        [formGrp]="travelOptionsFormGroup"
                        [required]="true"
                    ></mds-dropdown>
                </form>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <h5 class="draggable-list-header">Visit Schedule</h5>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <mds-button
                    id="MappingVisitsBtn"
                    (clickEvent)="openVisitMappingModal()"
                    label="Mapping"
                    [variant]="'primary'"
                    [disabled]="!isValidVisitsFormGroup()"
                ></mds-button>

                <mds-button
                    *ngIf="hasMultipleVisitSchedules"
                    id="AddGroupBtn"
                    (clickEvent)="addVisitScheduleGroup()"
                    label="Add Cohort/Part"
                    [variant]="'primary'"
                ></mds-button>
            </div>
        </div>

        <div class="o-grid__row" *ngIf="showVisitGroupField">
            <div class="o-grid__item">
                <mds-text-field
                    [formCtrlName]="'groupNameInput'"
                    [formGrp]="parentFormGroup"
                    id="goupNameInput"
                    floatLabel="always"
                    appearance="outline"
                    label="{{ 'Cohort/Group name' }}"
                    placeholder="Type here..."
                ></mds-text-field>
            </div>
            <div class="o-grid__item center-buttons">
                <mds-button
                    id="NewVisitsGroupBtn"
                    (clickEvent)="addVisitGroup()"
                    label="Add"
                    appearance="outline"
                    [variant]="'outline'"
                ></mds-button>
                <mds-button
                    id="CancelVisitsGroupBtn"
                    (clickEvent)="this.showVisitGroupField = false"
                    label="Cancel"
                    appearance="outline"
                    [variant]="'outline'"
                ></mds-button>
            </div>
        </div>
        <div class="o-grid__row" *ngIf="cohortExists">
            <medpace-alert content="The cohort name already exists." [additionalClass]="'warning'"></medpace-alert>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item center-text">
                <span>Does study have multiple visit schedules?</span>
            </div>
            <div class="o-grid__item">
                <mds-radio-group
                    [buttons]="radioButtons"
                    [formGrp]="multipleVisitRadioFormGroup"
                    formCtrlName="multipleVisitControl"
                    id="hasMultipleVisitSchedules"
                    (changeEvent)="
                        hasMultipleVisitScheduleRadioChanged($event.value, { id: 'hasMultipleVisitSchedules' })
                    "
                >
                </mds-radio-group>
            </div>
        </div>
        <div class="o-grid__row" *ngFor="let groupName of getVistGroupNamesAsArray(); index as i">
            <div class="o-grid__item">
                <medpace-draggable-list-with-checkboxes
                    id="visitNamesDraggableList{{ i }}"
                    #visitNamesDraggableList
                    [title]="groupName"
                    labelText="visit name"
                    (optionsChanged)="emitVisitScheduleChange($event, { id: 'visitTypes' }, groupName)"
                    [optionName]="groupName"
                    [index]="i"
                    [options]="getVistNamesByGroupName(groupName)"
                    [hasMultipleVisitSchedules]="content?.hasMultipleVisitSchedules"
                    [parentFormGroup]="parentFormGroup"
                    (tryRemoveOption)="tryRemoveVisitName($event, i)"
                    (tryRemoveGroup)="removeVisitGroup($event)"
                    (changeGroupName)="changeGroupName($event)"
                    (isPrimaryChange)="isPrimaryChange($event)"
                ></medpace-draggable-list-with-checkboxes>
            </div>
        </div>
        <div class="o-grid__row">
            <div class="o-grid__item">
                <medpace-draggable-list
                    #expenseTypesDraggableList
                    title="Expense Types"
                    labelText="expense type"
                    (optionsChanged)="emitChange($event, { id: 'expenseTypes' })"
                    [optionName]="'expenseType'"
                    [options]="content?.expenseTypes"
                    [parentFormGroup]="parentFormGroup"
                    (tryRemoveOption)="tryRemoveExpenseType($event)"
                ></medpace-draggable-list>
            </div>
        </div>
    </ng-container>
</div>
<div *ngIf="!isEditing && !loaderSpinner" class="o-grid">
    <div class="o-grid__row">
        <div class="o-grid__item">
            <mds-expansion-panel
                [panelTitle]="'Global CTM'"
                [description]="''"
                icon="people"
                [attr.data-cy]="'globalCtmExpansion'"
            >
                <div class="c-info__block" *ngFor="let globalCtm of content?.globalCtm">
                    <span>{{ globalCtm.fullName }}</span>
                </div>
            </mds-expansion-panel>
        </div>
    </div>

    <div class="o-grid__row">
        <div class="o-grid__item">
            <mds-expansion-panel
                [panelTitle]="'Regional CTMs'"
                [description]="''"
                icon="people"
                [attr.data-cy]="'regionalCtmsExpansion'"
            >
                <div class="c-info__block" *ngFor="let regional of content?.regionalCtms">
                    <div class="people-view">
                        <span style="width: 20%">{{ regional.fullName }}</span>
                        <span>{{ getRegionNamesByGuid(regional.studyPersonLocales) }}</span>
                    </div>
                </div>
            </mds-expansion-panel>
        </div>
    </div>
    <div class="o-grid__row">
        <div class="o-grid__item">
            <mds-expansion-panel [panelTitle]="'PC'" [description]="''" icon="people" [attr.data-cy]="'pcExpansion'">
                <div class="c-info__block" *ngFor="let pc of content?.pc">
                    <div class="people-view">
                        <span style="width: 20%">{{ pc.fullName }}</span>
                        <span>{{ getRegionNamesByGuid(pc.studyPersonLocales) }}</span>
                    </div>
                </div>
            </mds-expansion-panel>
        </div>
    </div>
    <div class="o-grid__row">
        <div class="o-grid__item">
            <mds-expansion-panel
                [panelTitle]="'Global RSM'"
                [description]="''"
                icon="people"
                [attr.data-cy]="'globalRsmExpansion'"
            >
                <div class="c-info__block" *ngFor="let globalRsm of content?.globalRsm">
                    <span>{{ globalRsm.fullName }}</span>
                </div>
            </mds-expansion-panel>
        </div>
    </div>
    <div class="o-grid__row">
        <div class="o-grid__item">
            <mds-expansion-panel [panelTitle]="'PRM'" [description]="''" icon="people" [attr.data-cy]="'prmExpansion'">
                <div class="c-info__block" *ngFor="let prm of content?.prm">
                    <span>{{ prm.fullName }}</span>
                </div>
            </mds-expansion-panel>
        </div>
    </div>
    <div class="o-grid__row">
        <div class="o-grid__item">
            <div class="c-info__block">
                <h5>Status</h5>
                <span>{{ !!content?.status ? content?.status : 'N/A' }}</span>
            </div>
        </div>
    </div>
    <div class="o-grid__row">
        <div class="o-grid__item">
            <div class="c-info__block">
                <h5>Indication</h5>
                <span>{{ !!content?.indication ? content?.indication : 'N/A' }}</span>
            </div>
        </div>
    </div>
    <div class="o-grid__row">
        <div class="o-grid__item">
            <div class="c-info__block">
                <h5>Country</h5>
                <p *ngFor="let country of content?.studyCountries">{{ country.countryName }}</p>
            </div>
        </div>
    </div>

    <div class="o-grid__row">
        <div class="o-grid__item">
            <div class="c-info__block">
                <h5>Prospected Sites</h5>
                <span>{{ content?.numSites }}</span>
            </div>
        </div>
    </div>
    <div class="o-grid__row">
        <div class="o-grid__item">
            <div class="c-info__block">
                <h5>Prospected Patients</h5>
                <span>{{ content?.numPatients }}</span>
            </div>
        </div>
    </div>
    <div class="o-grid__row">
        <div class="o-grid__item">
            <div class="c-info__block">
                <h5>FPFV</h5>
                <span>{{ content?.fpfv?.toDate() | dateFormat | uppercase }}</span>
            </div>
        </div>
        <div class="o-grid__item">
            <div class="c-info__block">
                <h5>LPLV</h5>
                <span>{{ content?.lplv?.toDate() | dateFormat | uppercase }}</span>
            </div>
        </div>
    </div>
    <div class="o-grid__row" *ngIf="showTravelOptions()">
        <div class="o-grid__item">
            <div class="c-info__block">
                <h5>Travel Options</h5>
                <p *ngFor="let travelOption of content?.studyTravelOptions">{{ travelOption.name }}</p>
            </div>
        </div>
    </div>
    <div class="o-grid__row">
        <div class="o-grid__item">
            <div class="c-info__block">
                <h5>Visit Schedule</h5>
                <div *ngFor="let visitGroupName of getVistGroupNamesAsArray()">
                    <h6 *ngIf="hasMultipleVisitSchedules">{{ visitGroupName }}</h6>
                    <div *ngFor="let visit of getVistNamesByGroupName(visitGroupName)" style="display: flex">
                        <div class="visits-width" data-cy="visitName">{{ visit.name }}</div>
                        <div class="visits-width">{{ visit.scheduled ? 'Scheduled' : 'Unscheduled' }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="o-grid__row">
        <div class="o-grid__item">
            <div class="c-info__block">
                <h5>Expense Types</h5>
                <p *ngFor="let x of content?.expenseTypes">
                    {{ x.name }}
                </p>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!isEditing && loaderSpinner" class="o-grid">
    <medpace-loading-card></medpace-loading-card>
</div>
<ng-template #AddPerson let-context>
    <mds-button
        id="addPersonBtn"
        (clickEvent)="addEmptyPersonControl(context.roleName)"
        label="Add Person"
        [variant]="'primary'"
        icon="person_add"
    ></mds-button>
</ng-template>
