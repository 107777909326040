import { ReimbursementTypes } from '@app/enums/ReimbursementTypes';
import { Moment, utc } from 'moment';
import { Patient } from './patient';
import { VisitName } from './study';
import { TransferData, TransferData_DTO } from './transferData';

export class TravelAccommodations {
    needsAirTravel: boolean;
    flightRequests: string;

    flightTransDate: Moment;
    flightTransAmount: number;
    flightTransCurrency: string;
    flightDoc: File; //base64 binary string (from btoa())
    flightAttachmentInfoId?: number;
    flightRequestDetailsId?: number;
    flightRequestDetails: TravelRequestFlightTravelDetail;

    needsTrainTravel: boolean;

    /**
     * Legacy field for "Special requests" textarea value. Displayed for old requests that don't have data in the structured form.
     * All new Travel Requests store this value in `trainTravelDetailsForPatient.specialRequests`.
     */
    trainRequests: string;

    trainTransDate: Moment;
    trainCost: number;
    trainCurrency: string;
    trainDoc: File;
    trainAttachmentInfoId?: number;
    trainTravelDetailsForPatient: TrainTravelDetails;

    needsLodging: boolean;
    lodgingCheckInDate: Moment;
    lodgingCheckOutDate: Moment;
    lodgingDateRequired: boolean;
    lodgingRequests: string;
    lodgingTransDate: Moment;
    lodgingCost: number;
    lodgingCurrency: string;
    lodgingDoc: File;
    lodgingAttachmentInfoId?: number;

    needsCar: boolean;
    carServiceRequests: string;
    groundTransDate: Moment;
    groundCost: number;
    groundCurrency: string;
    groundDoc: File;
    groundAttachmentInfoId?: number;
    transferDatas: TransferData[];

    needsRentalCar: boolean;
    rentalCarRequests: string;
    rentalCarDate: Moment;
    rentalCarCost: number;
    rentalCarCurrency: string;
    rentalCarDoc: File;
    rentalCarPickUpTime: string;
    rentalCarDropOffTime: string;
    rentalCarPickUpDate: Moment;
    rentalCarDropOffDate: Moment;
    rentalCarCountry: string;
    rentalCarLocation: string;
    rentalCarAttachmentInfoId?: number;

    needsOther: boolean;
    otherRequests: string;
    otherTransDate: Moment;
    otherCost: number;
    otherCurrency: string;
    otherDoc: File;
    otherAttachmentInfoId?: number;
}

export interface IRequest {
    type: string;
    visitDetails: VisitDetails;
    status: number;
}

export class VisitDetails {
    visitNameId: number;
    visitName: VisitName;
    travelerInstructions: string;
    visitStartDate: Moment;
    visitEndDate: Moment;
    visitStartTime: string;
    visitEndTime: string;
}

export class Expense {
    id: number;
    requestId: number;
    category: string;
    // attachment: string; //this is the base64 string
    attachment: File;
    attachmentId?: number;
    timestamp: Moment;
    amount: number;
    authorizedAmount: number | null;
    notes: string;
    currency: string;
    mileageStartLocation: string;
    mileageEndLocation: string;
    mileageTotalDistance: number;
    mileageReimbursementCountry: string;
    mileageComments: string;
    mileageReimbursementRatePerUnit: number;
    mileageDistanceUnits: string;
    manualTotalCalculation: boolean;
    isIncomplete: boolean;
    constructor(init?: Partial<Expense>) {
        if (init) Object.assign(this, init);
    }
}
export class Expense_DTO {
    id: number;
    requestId: number;
    category: string;
    attachment: File;
    attachmentId?: number;
    timestamp: string;
    amount: number;
    authorizedAmount: number | null;
    currency: string;
    mileageStartLocation: string;
    mileageEndLocation: string;
    mileageTotalDistance: number;
    mileageReimbursementCountry: string;
    mileageComments: string;
    mileageReimbursementRatePerUnit: number;
    mileageDistanceUnits: string;
    manualTotalCalculation: boolean;
    isIncomplete: boolean;
    constructor(init?: Partial<Expense_DTO>) {
        if (init) Object.assign(this, init);
    }
}

export class Reimbursement {
    type: ReimbursementTypes;
    transactionDate: Moment;
    totalAmount: number;
    totalAuthorizedAmount: number | null;
    currency: string;
    expenses: Expense[]; //this will map to ICollection<ReimbursementTransactions>
    changedRequestType: boolean;
    constructor(init?: Partial<Reimbursement>) {
        if (init) Object.assign(this, init);
    }
}

export enum GenericRequestStatus {
    New = 0,
    Draft = 1,
    Submitted = 2,
    Completed = 3,
    InProcess = 4,
    PendingApprovel = 5,
    Denied = 6,
    Cancelled = 7,
    PendingSetUp = 8,
    PendingSiteVerification = 9,
}

export class GenericRequest implements IRequest {
    id: number;
    requestPseudoId: string;
    type: string; //travel or payment;
    paymentType: string; //stipend or out-of-pocket
    reimbursement: Reimbursement;
    visitDetails: VisitDetails;
    status: GenericRequestStatus;

    patientId: number;
    patient: Patient;
    travelRequests: TravelAccommodations;
    caregiver: boolean;
    isMinor: boolean;
    requestDate: Moment;
    submittingUserId: number;
    submittingUserName: string;
    completingUserId: number;
    completingUserName: number;
    flaggedDuplicate: boolean;
    additionalDetails: string;

    travelRequestCaregivers: TravelRequestCaregiver[];
}
export interface TravelRequest_DTO {
    id: number;
    requestPseudoId: string;
    statusId: number;
    patientId: number;
    visitNameId: number;
    visitName: VisitName;
    visitStartDate: string;
    visitEndDate: string;
    visitStartTime: string;
    visitEndTime: string;
    caregiver: boolean;
    isMinor: boolean;
    travelerInstructions: string;

    flightRequests: string;
    lodgingRequests: string;
    otherRequests: string;
    carServiceRequests: string;
    trainRequests: string;
    rentalCarRequests: string;

    flightRequired: boolean;
    trainRequired: boolean;
    carServiceRequired: boolean;
    lodgingRequired: boolean;
    otherServiceRequired: boolean;
    rentalCarRequired: boolean;

    flightTransactionDate: string;
    flightTransactionAmount: number;
    flightTransactionCurrency: string;
    flightDoc: File; //base64 binary string (from btoa())
    flightRequestDetailsId?: number;
    flightRequestDetails?: TravelRequestFlightTravelDetail_DTO;

    trainTransactionDate: string;
    trainTransactionAmount: number;
    trainTransactionCurrency: string;
    trainDoc: File;

    // old Travel Requests in the database will have no train travel request objects assigned, so it can be null
    trainTravelDetailsForPatient: TrainTravelDetails_DTO | null;

    carServiceTransactionDate: string;
    carServiceTransactionAmount: number;
    carServiceTransactionCurrency: string;
    carServiceDoc: File;
    travelRequestTransferData: TransferData_DTO[];

    lodgingCheckInDate: string;
    lodgingCheckOutDate: string;
    lodgingDateRequired: boolean;
    lodgingTransactionDate: string;
    lodgingTransactionAmount: number;
    lodgingTransactionCurrency: string;
    lodgingDoc: File;

    rentalCarPickUpDate: string;
    rentalCarDropOffDate: string;
    rentalCarPickUpTime: string;
    rentalCarDropOffTime: string;
    rentalCarLocation: string;
    rentalCarCountry: string;
    rentalCarTransactionDate: string;
    rentalCarTransactionAmount: number;
    rentalCarTransactionCurrency: string;
    rentalCarDoc: File;

    otherTransactionDate: string;
    otherTransactionAmount: number;
    otherTransactionCurrency: string;
    otherDoc: File;

    createdTimestamp: string;

    submittingUserId: number;
    submittingUserName: string;
    completingUserId: number;
    completingUserName: number;
    flaggedDuplicate: boolean;
    additionalDetails: string;

    travelRequestCaregivers: TravelRequestCaregiver_DTO[];
}
export interface TravelRequest {
    id: number;
    requestPseudoId: string;
    statusId: number;
    patientId: number;
    visitNameId: number;
    visitName: VisitName;
    visitStartDate: Moment;
    visitEndDate: Moment;
    visitStartTime: string;
    visitEndTime: string;
    caregiver: boolean;
    isMinor: boolean;
    travelerInstructions: string;

    flightRequests: string;
    lodgingRequests: string;
    otherRequests: string;
    carServiceRequests: string;
    trainRequests: string;
    rentalCarRequests: string;

    flightRequired: boolean;
    trainRequired: boolean;
    carServiceRequired: boolean;
    lodgingRequired: boolean;
    otherServiceRequired: boolean;
    rentalCarRequired: boolean;

    flightTransactionDate: Moment;
    flightTransactionAmount: number;
    flightTransactionCurrency: string;
    flightDoc: File; //base64 binary string (from btoa())
    flightAttachmentInfoId?: number;
    flightRequestDetailsId?: number;
    flightRequestDetails?: TravelRequestFlightTravelDetail;

    trainTransactionDate: Moment;
    trainTransactionAmount: number;
    trainTransactionCurrency: string;
    trainDoc: File;
    trainAttachmentInfoId?: number;
    trainTravelDetailsForPatient: TrainTravelDetails;

    carServiceTransactionDate: Moment;
    carServiceTransactionAmount: number;
    carServiceTransactionCurrency: string;
    carServiceDoc: File;
    carServiceAttachmentInfoId?: number;
    travelRequestTransferData: TransferData[];

    lodgingCheckInDate: Moment;
    lodgingCheckOutDate: Moment;
    lodgingDateRequired: boolean;
    lodgingTransactionDate: Moment;
    lodgingTransactionAmount: number;
    lodgingTransactionCurrency: string;
    lodgingDoc: File;
    lodgingAttachmentInfoId?: number;

    rentalCarPickUpDate: Moment;
    rentalCarDropOffDate: Moment;
    rentalCarPickUpTime: string;
    rentalCarDropOffTime: string;
    rentalCarLocation: string;
    rentalCarCountry: string;
    rentalCarTransactionDate: Moment;
    rentalCarTransactionAmount: number;
    rentalCarTransactionCurrency: string;
    rentalCarDoc: File;
    rentalCarAttachmentInfoId?: number;

    otherTransactionDate: Moment;
    otherTransactionAmount: number;
    otherTransactionCurrency: string;
    otherDoc: File;
    otherAttachmentInfoId?: number;

    createdTimestamp: Moment;

    submittingUserId: number;
    submittingUserName: string;
    completingUserId: number;
    completingUserName: number;
    flaggedDuplicate: boolean;
    additionalDetails: string;
    travelRequestCaregivers: TravelRequestCaregiver[];
}

export interface ReimbursementRequest_DTO {
    id: number;
    requestPseudoId: string;
    patientId: number;
    visitNameId: number;
    statusId: number;
    visitStartDate: string;
    apptTime: string;
    apptDurationHours: string;
    totalAmount: number;
    totalAuthorizedAmount: number | null;
    totalCurrency: string;
    transactionDate: string;
    reimbursementTransactions: Expense_DTO[];
    travelerInstructions: string;
    createdTimestamp: string;
    submittingUserId: number;
    submittingUserName: string;
    completingUserId: number;
    completingUserName: number;
    flaggedDuplicate: boolean;
    additionalDetails: string;
    changedRequestType: boolean;
}
export interface ReimbursementRequest {
    id: number;
    requestPseudoId: string;
    patientId: number;
    visitNameId: number;
    statusId: number;
    visitStartDate: Moment;
    apptTime: string;
    apptDurationHours: string;
    totalAmount: number;
    totalAuthorizedAmount: number | null;
    totalCurrency: string;
    transactionDate: Moment;
    reimbursementTransactions: Expense[];
    travelerInstructions: string;
    createdTimestamp: Moment;
    submittingUserId: number;
    submittingUserName: string;
    completingUserId: number;
    completingUserName: number;
    flaggedDuplicate: boolean;
    additionalDetails: string;
    changedRequestType: boolean;
}

export interface StipendRequest_DTO {
    id: number;
    requestPseudoId: string;
    patientId: number;
    visitNameId: number;
    statusId: number;
    visitStartDate: string;
    apptTime: string;
    apptDurationHours: string;
    amount: number;
    totalAuthorizedAmount: number | null;
    currency: string;
    transactionDate: string;
    travelerInstructions: string;
    createdTimestamp: string;
    liaisonInitials: string;
    submittingUserId: number;
    submittingUserName: string;
    completingUserId: number;
    completingUserName: number;
    flaggedDuplicate: boolean;
    additionalDetails: string;
    changedRequestType: boolean;
}
export interface StipendRequest {
    id: number;
    requestPseudoId: string;
    patientId: number;
    visitNameId: number;
    statusId: number;
    visitStartDate: Moment;
    apptTime: string;
    apptDurationHours: string;
    amount: number;
    totalAuthorizedAmount: number | null;
    currency: string;
    transactionDate: Moment;
    travelerInstructions: string;
    createdTimestamp: Moment;
    submittingUserId: number;
    submittingUserName: string;
    completingUserId: number;
    completingUserName: number;
    flaggedDuplicate: boolean;
    additionalDetails: string;
    changedRequestType: boolean;
}

export interface RequestLog {
    id: number;
    requestApprovalStatusId: number;
    timestamp: Date;
    details: string;
    responsibleUserName: string;
    responsibleUserEmail: string;
    actionWasPerformedInPatientPortal: boolean;
    anonymized: boolean;
}

export interface RequestStatusDTO {
    request: GenericRequest;
    newStatus: number;
}

export class MileageReimbursementRate {
    id: string = '';
    countryCode: string = '';
    currencyCode: string = '';
    effectiveDate: Date = null;
    mileageDistanceUnits: string = '';
    mileageReimbursementRatePerUnit: number = 0;
    constructor(init?: Partial<MileageReimbursementRate>) {
        if (init) Object.assign(this, init);
    }
}

export class MileageReimbursementRateData {
    countryCode: string = '';
    currencyCode: string = '';
    effectiveDate: Moment = null;
    mileageDistanceUnits: string = '';
    mileageReimbursementRatePerUnit: number = 0;
}

export interface TravelRequestCaregiver {
    travelRequestId?: number;
    caregiverId: number;
    caregiverName: string;
    isPrimary: boolean;
    isChecked: boolean;

    trainTravelDetails: TrainTravelDetails | null;
    flightRequestDetailsId?: number;
    flightRequestDetails?: TravelRequestFlightTravelDetail;
    flightRequestTheSameAsPatient: boolean;
}

export interface TravelRequestCaregiver_DTO {
    travelRequestId?: number;
    caregiverId: number;
    caregiverName: string;
    isPrimary: boolean;
    isChecked: boolean;

    flightRequestDetailsId?: number;
    flightRequestDetails?: TravelRequestFlightTravelDetail_DTO;
    flightRequestTheSameAsPatient: boolean;

    trainTravelDetails: TrainTravelDetails_DTO | null;
}

export enum TrainTravelType {
    OneWay,
    RoundTrip,
    NoTravel,
}

export interface TrainTravelDetails {
    trainFormInLegacyMode: boolean;

    isSameAsPatient: boolean;
    trainTravelType: TrainTravelType;

    trainStationOrigin: string;
    trainStationDestination: string;
    departureDate: Moment;
    returnDate: Moment | null;
    specialRequests: string;
}

// model of an event sent through EventEmitter when something in the travel plan changes
export interface TrainTravelDetailsFormModel {
    /* Legacy mode means that the train travel request was created when only a single textarea, `trainSpecialRequests`, was available in the form.
       In this mode, we don't display new fields like "Train Station" or "Departure Date", so users can edit old requests without being bothered by
       the validation of new required fields.
    */
    trainFormInLegacyMode: boolean;

    caregiverId: number;

    useIdenticalTravelPlanAsForPatient: boolean;
    trainTravelType: TrainTravelType;

    trainDepartureDate: Moment;
    trainReturnDate: Moment;
    trainSpecialRequests: string;
    trainStationDestination: string;
    trainStationOrigin: string;
}

export interface TravelRequestFlightTravelDetail {
    id: number;
    travelType: number;
    airportOrigin: string;
    airportDestination: string;
    departureDate: Moment;
    returnDate: Moment;
    specialRequests: string;
}

export interface TravelRequestFlightTravelDetail_DTO {
    id: number;
    travelType: number;
    airportOrigin: string;
    airportDestination: string;
    departureDate: string;
    returnDate: string;
    specialRequests: string;
}
export function mapTravelRequestFlightTravelDetail_DTO_To_TravelRequestFlightTravelDetail(
    dto: TravelRequestFlightTravelDetail_DTO
): TravelRequestFlightTravelDetail {
    return <TravelRequestFlightTravelDetail>{
        ...dto,
        departureDate: !!dto.departureDate ? utc(dto.departureDate) : undefined,
        returnDate: !!dto.returnDate ? utc(dto.returnDate) : undefined,
    };
}

export interface TrainTravelDetails_DTO {
    trainTravelType: TrainTravelType;
    trainStationOrigin: string;
    trainStationDestination: string;
    departureDate: string;
    returnDate: string;
    specialRequests: string;
}

export interface RequestSummary {
    id: number;
    flaggedDuplicate: boolean;
    patientId?: number;
    pcsId?: string;
    requestPseudoId?: string;
    isPatientDeleted: boolean;
    patientName?: string;
    patientNum?: string;
    protocol?: string;
    readyForPayment: string;
    readyForPaymentDb?: boolean;
    requestDate: string;
    requestId?: number;
    requestType: string;
    siteId?: number;
    status: string;
    studyId?: number;
    visitDate: string;
    visitName: string;
    type?: string;
}

export function mapTrainTravelDetails_DTO_To_TrainTravelDetails(dto: TrainTravelDetails_DTO): TrainTravelDetails {
    if (!dto) return null;

    return <TrainTravelDetails>{
        ...dto,
        departureDate: utc(dto.departureDate),
        returnDate: dto.returnDate ? utc(dto.returnDate) : null,
    };
}
export function mapTrainTravelDetails_To_TrainTravelDetailsDTO(value: TrainTravelDetails): TrainTravelDetails_DTO {
    if (!value) return null;

    return <TrainTravelDetails_DTO>{
        ...value,
        departureDate: value.departureDate.toISOString(),
        returnDate: value.returnDate ? value.returnDate.toISOString() : null,
    };
}

export function mapTravelRequestCaregiver_DTO_To_TravelRequestCaregivers(
    dto: TravelRequestCaregiver_DTO
): TravelRequestCaregiver {
    return <TravelRequestCaregiver>{
        ...dto,
        trainTravelDetails: mapTrainTravelDetails_DTO_To_TrainTravelDetails(dto.trainTravelDetails),
        flightRequestDetails: dto.flightRequestDetails
            ? mapTravelRequestFlightTravelDetail_DTO_To_TravelRequestFlightTravelDetail(dto.flightRequestDetails)
            : null,
    };
}

export function mapTrainTravelFormModelToTrainTravelDetails(
    formModel: TrainTravelDetailsFormModel
): TrainTravelDetails {
    return {
        isSameAsPatient: formModel.useIdenticalTravelPlanAsForPatient,
        trainTravelType: formModel.trainTravelType,
        trainStationOrigin: formModel.trainStationOrigin,
        trainStationDestination: formModel.trainStationDestination,
        departureDate: formModel.trainDepartureDate,
        returnDate: formModel.trainReturnDate,
        specialRequests: formModel.trainSpecialRequests,
        trainFormInLegacyMode: formModel.trainFormInLegacyMode,
    };
}

export interface TravelRequestTypeCount {
    carServiceCount: number;
    flightCount: number;
    lodgingCount: number;
    otherServiceCount: number;
    rentalCarCount: number;
    trainCount: number;
}

export interface BaseRequest {
    id: number;
    visitNameId: number;
    statusId: number;
    visitStartDate: string;
}

export interface BaseTravelRequest extends BaseRequest {
    visitStartTime: string;
    visitEndDate: string;
    visitEndTime: string;
}
