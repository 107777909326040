import { DatePipe } from '@angular/common';
import { Component, inject, input } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import {
    PatientEditRequestDto,
    PatientEditRequestTableViewModel,
    transformPatientEditRequestDtoToPatientEditRequestTableViewModel,
} from '@models/patientEditRequestDto';
import { TableColumnHeader } from '@models/table-header';
import { map } from 'rxjs';
import { PatientEditRequestModalComponent } from '../modals/patient-edit-request-modal/patient-edit-request-modal.component';

@Component({
    selector: 'medpace-patient-edit-request-table',
    templateUrl: './patient-edit-request-table.component.html',
    styleUrls: ['./patient-edit-request-table.component.scss'],
})
export class PatientEditRequestTableComponent {
    #datePipe = inject(DatePipe);
    dialog = inject(MatDialog);
    patientEditRequests = input<PatientEditRequestDto[]>([]);

    tableData$ = toObservable(this.patientEditRequests).pipe(
        map((patientEditRequest: PatientEditRequestDto[]) => {
            return patientEditRequest.map((per) =>
                transformPatientEditRequestDtoToPatientEditRequestTableViewModel(per, this.#datePipe)
            );
        })
    );

    columns: string[] = ['patientNum', 'pseudoId', 'firstName', 'lastName', 'submitTimestamp'];
    columnsMap: TableColumnHeader[] = [
        { header: 'patient id', name: 'patientNum' },
        { header: 'PCS ID', name: 'pseudoId' },
        { header: 'first name', name: 'firstName' },
        { header: 'last name', name: 'lastName' },
        { header: 'submit date', name: 'submitTimestamp' },
    ];

    openDialog(event: PatientEditRequestTableViewModel) {
        const patientData = this.patientEditRequests().find((patient) => patient.patientId === event.patientId);

        this.dialog.open(PatientEditRequestModalComponent, { data: patientData, width: '75%', minWidth: '700px' });
    }
}
