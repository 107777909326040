import { DialogRef } from '@angular/cdk/dialog';
import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ModalResult } from '@app/enums/modal-result.enum';
import { dateFormatPipe } from '@app/pipes/datepipe';
import { CountryViewModel } from '@models/country';
import { DateFormat } from '@models/date-format';
import { AcceptedFieldFormGroup } from '@models/form-models/patient-edit-request-form/accepted-field-form';
import {
    AcceptedFieldsCaregiverFormType,
    AcceptedFieldsPatientFormType,
} from '@models/form-models/patient-edit-request-form/accepted-field-form-type';
import { PatientEditFormGroup } from '@models/form-models/patient-edit-request-form/patient-edit-request-form';
import {
    AirlineFormGroupType,
    CaregiverEditFormType,
    LodgingFormGroupType,
    PassportInfoFormGroupType,
    PatientEditFormType,
} from '@models/form-models/patient-edit-request-form/patient-edit-request-form-type';
import { Caregiver, Patient, PatientDataModel, getFullName } from '@models/patient';
import { PatientEditRequestDto } from '@models/patientEditRequestDto';
import { AdminPatientServices } from '@services/admin/admin-patient.service';
import { AdminSiteServices } from '@services/admin/admin-site.service';
import { EventService } from '@services/event/event.service';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { CountryStateService } from '@services/state-management/country-state.service';
import { SaveStatusResponse, buildSnackBar, collectErrors } from '@utility/utility';
import {
    Observable,
    Subject,
    combineLatest,
    defer,
    filter,
    map,
    of,
    shareReplay,
    startWith,
    switchMap,
    take,
    tap,
} from 'rxjs';
import { MedpaceMessageModalComponent } from '../medpace-message-modal/medpace-message-modal.component';

@Component({
    selector: 'app-patient-edit-request-modal',
    templateUrl: './patient-edit-request-modal.component.html',
    styleUrls: ['./patient-edit-request-modal.component.scss'],
})
export class PatientEditRequestModalComponent {
    patientEditRequest = inject<PatientEditRequestDto>(MAT_DIALOG_DATA);
    private countryStateService = inject(CountryStateService);
    private adminPatientServices = inject(AdminPatientServices);
    private adminSiteServices = inject(AdminSiteServices);
    private snackbarService = inject(SnackbarService);
    private router = inject(Router);
    private eventService = inject(EventService);
    private dialogRef = inject(DialogRef);
    private dialog = inject(MatDialog);
    datePipe = inject(dateFormatPipe);

    editRequestFormGroup: PatientEditFormType;
    acceptedFieldsFormGroup: AcceptedFieldsPatientFormType = AcceptedFieldFormGroup.create(this.patientEditRequest);

    patient$: Observable<Patient> = this.adminPatientServices
        .getPatientTrialSummary(this.patientEditRequest.patientId)
        .pipe(shareReplay(1));

    countries$: Observable<CountryViewModel[]> = this.countryStateService.getCountries();
    countriesStrings$: Observable<string[]> = this.countryStateService.getCountriesStringArray();

    isTravelSupported$ = this.patient$.pipe(
        switchMap((patient: Patient) => this.adminSiteServices.isTravelSupportedInSite(patient.siteId))
    );

    deleteRequestSubject: Subject<boolean> = new Subject();
    delete$ = this.deleteRequestSubject.asObservable().pipe(switchMap((_) => this.#deleteEditRequest$));
    #deleteEditRequest$ = defer(() =>
        this.dialog
            .open<MedpaceMessageModalComponent, MedpaceMessageModalComponent['data'], ModalResult>(
                MedpaceMessageModalComponent,
                {
                    data: {
                        title: `Delete Patient Edit Request`,
                        bodyText: 'Are you sure you want to delete this Edit Request?',
                        showCancelButton: true,
                        okayButtonLabel: 'Yes',
                        cancelButtonLabel: 'No',
                    },
                    width: '80%',
                    maxWidth: 600,
                    minWidth: 360,
                    disableClose: true,
                }
            )
            .afterClosed()
    ).pipe(
        filter((result: ModalResult) => result === ModalResult.Okay),
        switchMap((_) =>
            this.adminPatientServices.removePatientEditRequest(this.patientEditRequest.patientId).pipe(
                take(1),
                tap((result: SaveStatusResponse) => {
                    if (result.saveSuccessful) {
                        //display snackbar
                        this.snackbarService.openInfoSnackbar('Deleted Edit Request');
                        //route to patients table
                        this.router.navigate(['admin', 'patients', 'editrequest']);
                        //reload notifications to update number of edit requests
                        this.eventService.emit({ name: 'notification-reload', value: null });
                        this.dialogRef.close('remove');
                    } else throw new Error(result.errorMessage);
                })
            )
        )
    );

    submitSubject: Subject<boolean> = new Subject();
    submit$ = this.submitSubject.asObservable().pipe(switchMap((_) => this.#submitEditRequest$));
    #submitEditRequest$ = defer(() =>
        this.dialog
            .open<MedpaceMessageModalComponent, MedpaceMessageModalComponent['data'], ModalResult>(
                MedpaceMessageModalComponent,
                {
                    data: {
                        title: `Save Changes`,
                        bodyText: 'Do you want to save edit request changes to Patient?',
                        showCancelButton: true,
                        okayButtonLabel: 'Yes',
                        cancelButtonLabel: 'No',
                    },
                    width: '80%',
                    maxWidth: 600,
                    minWidth: 360,
                    disableClose: true,
                }
            )
            .afterClosed()
    ).pipe(
        filter((result: ModalResult) => result === ModalResult.Okay),
        switchMap((_) => combineLatest([this.patient$, this.countries$])),
        map(([patient, countries]) =>
            PatientEditFormGroup.applyChanges(
                this.editRequestFormGroup,
                this.acceptedFieldsFormGroup,
                patient,
                countries
            )
        ),
        switchMap((patientDataModel: PatientDataModel) =>
            //Update patient
            combineLatest([
                of(patientDataModel.id),
                this.adminPatientServices.updatePatient(patientDataModel.id, patientDataModel),
            ])
        ),
        switchMap(([patientId, saveStatus]: [number, SaveStatusResponse]) => {
            if (saveStatus.saveSuccessful) {
                //updated successfully, delete patientEditRequest
                return this.adminPatientServices.removePatientEditRequest(patientId);
            }
            //not updated, throw error
            throw new Error(saveStatus.errorMessage);
        }),
        tap((removeEditRequestSaveStatus: SaveStatusResponse) => {
            if (removeEditRequestSaveStatus.saveSuccessful) {
                //route to patients table
                //display snackbar
                this.snackbarService.openInfoSnackbar('Patient updated successfully');
                this.router.navigate(['admin', 'patients', 'editrequest']);
                //reload notifications to update number of edit requests
                this.eventService.emit({ name: 'notification-reload', value: null });
                this.dialogRef.close('saved');
            } else throw new Error(removeEditRequestSaveStatus.errorMessage);
        })
    );

    setupFormGroup$ = combineLatest([this.countries$, this.patient$]).pipe(
        tap(([countries, patient]: [CountryViewModel[], Patient]) => {
            this.editRequestFormGroup = PatientEditFormGroup.create(this.patientEditRequest, patient, countries);
        })
    );

    regionType$ = defer(() =>
        !this.editRequestFormGroup.controls.country
            ? of(null)
            : this.editRequestFormGroup.controls.country.valueChanges.pipe(
                  startWith(this.editRequestFormGroup.controls.country.value)
              )
    ).pipe(switchMap((country: string) => this.countryStateService.getRegionStateType(country)));

    submit() {
        if (this.editRequestFormGroup.valid) {
            this.submitSubject.next(true);
        } else {
            this.editRequestFormGroup.markAllAsTouched();
            buildSnackBar(collectErrors(this.editRequestFormGroup), this.snackbarService);
        }
    }
    closeModal() {
        this.dialogRef.close(null);
    }
    toPatient(untyped: any): Patient {
        return untyped as Patient;
    }
    getCountryNameFromCode(code: string): Observable<string> {
        return this.countryStateService
            .getCountryByCode(code)
            .pipe(map((country: CountryViewModel) => country?.viewValue));
    }

    getCaregiver(patient: Patient, caregiverId: number): Caregiver {
        const caregiver = patient.caregivers.find((c) => c.id === caregiverId);
        return caregiver;
    }

    getAcceptedFieldsCaregiver(
        formGroup: AcceptedFieldsPatientFormType,
        caregiverId: number
    ): AcceptedFieldsCaregiverFormType {
        return formGroup.controls.caregiverEditRequest.controls.find(
            (control) => control.controls.caregiverId.value === caregiverId
        );
    }

    getPatientFullNameTemplate(patient: Patient): string {
        return getFullName(
            patient.patientIdentification.firstName,
            patient.patientIdentification?.middleName,
            patient.patientIdentification.lastName
        );
    }

    getCaregiverFullNameTemplate(caregiver: Caregiver): string {
        return getFullName(caregiver.firstName, caregiver.middleName, caregiver.lastName);
    }

    getFormattedDate(date: string | Date): string {
        return this.datePipe.transform(date, DateFormat.dateOnly);
    }

    showPatientData(form: PatientEditFormType): boolean {
        const formValue = form.defaultValue;

        // show Patient Data form if at least one property is changed
        return this.isAnyValueTruthy([
            formValue.phone,
            formValue.preferredLang,
            formValue.address1,
            formValue.address2,
            formValue.city,
            formValue.state,
            formValue.zipcode,
            formValue.country,
        ]);
    }

    showFlightTab(form: AirlineFormGroupType): boolean {
        const formValue = form.defaultValue;

        return this.isAnyValueTruthy([
            formValue.airlinePrimary,
            formValue.airlineSecondary,
            formValue.airlineRewardsPrimary,
            formValue.airlineRewardsSecondary,
            formValue.airlineSpecialNeeds,
            formValue.knownTravelerNum,
            formValue.seatPreference,
        ]);
    }

    showInternationalTab(form: PassportInfoFormGroupType): boolean {
        const formValue = form.defaultValue;

        return this.isAnyValueTruthy([
            formValue.passportCountry,
            formValue.passportNum,
            formValue.passportIssue,
            formValue.passportExpiration,
        ]);
    }

    showLodgingTab(form: LodgingFormGroupType): boolean {
        const formValue = form.defaultValue;

        return this.isAnyValueTruthy([
            formValue.allergies,
            formValue.hotelChainPrimary,
            formValue.lodgingRoomPreference,
            formValue.lodgingSpecialNeeds,
        ]);
    }

    showTravelForm(form: PatientEditFormType): boolean {
        const showFlightTab = this.showFlightTab(form.controls.airlineEditRequest);
        const showTrainTab = !!form.defaultValue?.trainEditRequest?.trainSpecialNeeds;
        const showInternationalTab = this.showInternationalTab(form.controls.passportInfo);
        const showLodgingtab = this.showLodgingTab(form.controls.lodgingEditRequest);
        const showRentalCarTab = !!form.defaultValue?.rentalCarEditRequest?.rentalCarFrequentTravelerNum;
        const showCarServiceTab = !!form.defaultValue?.carServiceEditRequest?.groundSpecialNeeds;

        return this.isAnyValueTruthy([
            showFlightTab,
            showTrainTab,
            showInternationalTab,
            showLodgingtab,
            showRentalCarTab,
            showCarServiceTab,
        ]);
    }

    showCaregiverCard(form: CaregiverEditFormType): boolean {
        const formValue = form.defaultValue;
        const showPassportInfo = this.showInternationalTab(form.controls?.passportInfo);

        return this.isAnyValueTruthy([
            formValue.addressSameAsPatient,
            formValue.phone,
            formValue.preferredLang,
            formValue.address1,
            formValue.address2,
            formValue.city,
            formValue.state,
            formValue.zipcode,
            formValue.country,
            showPassportInfo,
        ]);
    }

    private isAnyValueTruthy(values: unknown[]): boolean {
        return values.some((value) => this.isNotEmpty(value));
    }

    isNotEmpty(value: any): boolean {
        if (value === null || value === undefined) return false;

        if (Array.isArray(value) && value.length === 0) return false;

        if (typeof value === 'object' && !Array.isArray(value) && Object.keys(value).length === 0) return false;

        if (typeof value === 'boolean' && !value) return false;

        return true;
    }

    showField(currentValue: any, pendingValue: any): boolean {
        if (!!currentValue && this.isNotEmpty(pendingValue)) return true;

        if (!currentValue && !!pendingValue) return true;

        return false;
    }
}
