import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, inject } from '@angular/core';
import {
    AbstractControl,
    AbstractControlOptions,
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { iDraggableWithCheckboxesObject } from '@models/interfaces/iDraggableObject';
import {
    duplicateFormGroupInFormGroupValidator,
    maxLengthValidator,
    requiredAtLeastOneOption,
} from '@utility/utility.validators';
import { capitalize } from 'lodash-es';
import { Subject } from 'rxjs';

export type RemoveOptionData<T extends iDraggableWithCheckboxesObject> = { optionToRemove: T; options: T[] };
@Component({
    selector: 'medpace-draggable-list-with-checkboxes',
    templateUrl: './medpace-draggable-list-with-checkboxes.component.html',
    styleUrls: ['./medpace-draggable-list-with-checkboxes.component.scss'],
})
export class MedpaceDraggableListWithCheckboxesComponent<T extends iDraggableWithCheckboxesObject>
    implements OnInit, OnDestroy
{
    private fb = inject(FormBuilder);

    @Input() optionName: string;
    @Input() hasMultipleVisitSchedules: boolean;
    @Input() index?: number;
    @Input() title: string;
    @Input() labelText: string;
    @Input() options: T[];
    @Input() parentFormGroup: FormGroup = this.fb.group({});
    @Output() optionsChanged = new EventEmitter<T[]>();
    @Output() tryRemoveOption = new EventEmitter<RemoveOptionData<T>>();
    @Output() tryRemoveGroup = new EventEmitter<string>();
    @Output() changeGroupName = new EventEmitter<{ currentGroupName: string; newGroupName: string }>();
    @Output() isPrimaryChange = new EventEmitter<{ groupName: string; isPrimary: boolean }>();

    isPrimaryControl: FormControl<boolean> = new FormControl<boolean>(false);
    optionsFormGroup: FormGroup = this.fb.group({}, <AbstractControlOptions>{ validators: requiredAtLeastOneOption });
    optionFormControlName: string = '';
    private componentDestroyed$: Subject<boolean> = new Subject();
    isEditGroupName: boolean = false;

    ngOnInit(): void {
        this.addFormGroupToParent(this.optionsFormGroup);
        this.optionFormControlName = `${this.optionName}Control`;

        if (this.options === null || this.options === undefined) {
            this.options = <T[]>[{ name: '', scheduled: false, sortOrder: 1 }];
        }
        this.initializeFormControls(this.options);
    }

    doneChangeHeaderNameClickEvent() {
        let newValue = <string>this.parentFormGroup.get('groupNameEditInput').value;

        if (!newValue) return;

        if (this.optionName === newValue) {
            this.isEditGroupName = false;
            return;
        }

        this.changeGroupName.emit({ currentGroupName: this.optionName, newGroupName: newValue });
    }

    isPrimaryOptionChange(value: boolean) {
        this.isPrimaryChange.emit({ groupName: this.optionName, isPrimary: value });
    }

    optionChanged(value: string, option: T): void {
        this.options.find((val) => val === option).name = value;
        this.optionsChanged.emit(this.options);
    }

    checkboxChanged(value: boolean, option: T) {
        this.options.find((val) => val === option).scheduled = value;
        this.optionsChanged.emit(this.options);
    }

    getFormGroupControl<T extends iDraggableWithCheckboxesObject>(formGroup: FormGroup, option: T): FormGroup {
        return <FormGroup>formGroup.get(this.getFormControlName(option));
    }

    getFormControlText<T extends iDraggableWithCheckboxesObject>(formGroup: FormGroup, option: T): FormControl<string> {
        return <FormControl<string>>this.getFormGroupControl(formGroup, option).get('name');
    }

    getFormControlCheckbox<T extends iDraggableWithCheckboxesObject>(
        formGroup: FormGroup,
        option: T
    ): FormControl<boolean> {
        return <FormControl<boolean>>this.getFormGroupControl(formGroup, option).get('scheduled');
    }

    getFormControlName<T extends iDraggableWithCheckboxesObject>(option: T): string {
        return `${this.optionFormControlName + option.sortOrder}`;
    }

    ngOnDestroy(): void {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }

    addFormGroupToParent(formGroup: FormGroup<any>): void {
        if (!!this.parentFormGroup) {
            this.parentFormGroup.addControl(`${this.optionName}Group`, formGroup);
        }
    }

    drop(event: CdkDragDrop<T[]>): void {
        if (event.previousIndex === event.currentIndex) {
            return;
        }
        moveItemInArray(this.options, event.previousIndex, event.currentIndex);
        this.reassignSortOrder(this.options);
        this.synchronizeFormControls(this.optionsFormGroup, this.options, this.optionFormControlName);

        this.optionsChanged.emit(this.options);
    }

    reassignSortOrder(options: T[]): void {
        options.forEach((value, index) => {
            value.sortOrder = index + 1;
        });
    }

    editGroupNameClickEvent() {
        this.isEditGroupName = true;
        this.parentFormGroup.get('groupNameEditInput').setValue(this.optionName);
    }

    addOption(): void {
        let newVisit: T = <T>{ sortOrder: this.options.length + 1, groupName: this.title };

        this.options.push(newVisit);
        this.synchronizeFormControls(this.optionsFormGroup, this.options, this.optionFormControlName);

        //event is not emitted, empty value
    }
    onOptionRemoveClick(option: T) {
        this.tryRemoveOption.emit({ optionToRemove: option, options: this.options });
    }
    removeOption(option: T): void {
        const optionToRemove = this.options.find((val) => val === option);

        if (!!optionToRemove) {
            this.options.splice(this.options.indexOf(optionToRemove), 1);
            this.reassignSortOrder(this.options);
            this.synchronizeFormControls(this.optionsFormGroup, this.options, this.optionFormControlName);

            this.optionsChanged.emit(this.options);
        }
    }

    removeGroup() {
        this.parentFormGroup.removeControl(`${this.optionName}Group`);
        this.tryRemoveGroup.emit(this.optionName);
    }

    /* istanbul ignore next */
    convertToFormControl(abstractControl: AbstractControl): FormControl {
        return <FormControl>abstractControl;
    }

    initializeFormControls(options: T[]): void {
        options.forEach((option) => {
            const name = this.getFormControlName(option);
            this.optionsFormGroup.addControl(name, this.createFormGroupControl(option?.name, option?.scheduled));
        });
    }

    /* istanbul ignore next */
    capitalizeText(text: string): string {
        if (!!text) return capitalize(text);
    }

    getButtonID(name: string): string {
        return `add-${name}-btn`.replace(' ', '_');
    }

    getRemoveButtonID(name: string): string {
        return `remove-${name}-btn`.replace(' ', '_');
    }

    /* istanbul ignore next */
    createFormGroupControl(name: string, checked: boolean) {
        return new FormGroup(
            {
                name: new FormControl(name, [Validators.required, maxLengthValidator(100)]),
                scheduled: new FormControl(checked),
            },
            [
                duplicateFormGroupInFormGroupValidator(
                    this.optionsFormGroup,
                    `${this.capitalizeText(this.labelText)} has that option already`
                ),
            ]
        );
    }

    private synchronizeFormControls(formGroup: FormGroup, options: T[], formControlName: string): void {
        // Remove old controls from formGroup
        const controlNames = Object.keys(formGroup.controls);
        controlNames.forEach((name) => formGroup.removeControl(name));

        // Add controls with updated names
        for (let i = 0; i < options.length; i++) {
            const newControl = this.createFormGroupControl(options[i].name, options[i].scheduled);
            formGroup.addControl(`${formControlName}${i + 1}`, newControl);
        }

        formGroup.markAllAsTouched();
    }

    getValidationMessage(formGroup: FormGroup): string {
        let validationMessage: string;
        if (!!formGroup?.errors) {
            validationMessage = Object.values(formGroup.errors)[0];
        } else {
            //no error in formGroup, but in formControls inside
            validationMessage = `Please fill in all "${this.capitalizeText(this.labelText)}" fields correctly`;
        }

        return validationMessage;
    }
}
