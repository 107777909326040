import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Caregiver, Patient, PatientDataModel } from '@models/patient';
import { transformToPatientDataModel } from '@services/transforms/patient-transform';
import { PersistentFormControl } from '@utility/persistent-forms';
import { filterObject } from '@utility/utility';
import { Moment, utc } from 'moment';
import {
    lessThanTodayDateValidator,
    maxLengthValidator,
    moreThanTodayDateValidator,
    passportValidatorCaregiver,
    passportValidatorPatient,
    phoneNumberFormatValidatorWithMaxLength,
} from '../../../utility/utility.validators';
import { CaregiverEditRequestDto } from '../../caregiverEditRequestDto';
import { CountryViewModel } from '../../country';
import { PatientEditRequestDto } from '../../patientEditRequestDto';
import { AcceptedFieldsCaregiverFormType, AcceptedFieldsPatientFormType } from './accepted-field-form-type';
import {
    AirlineFormGroupType,
    CaregiverEditFormType,
    LodgingFormGroupType,
    PassportInfoFormGroupType,
    PatientEditFormType,
} from './patient-edit-request-form-type';

export class PatientEditFormGroup {
    public static create(
        patientEditRequest: PatientEditRequestDto,
        patient: Patient,
        countries: CountryViewModel[]
    ): PatientEditFormType {
        const filterUndefineds = <T extends Object>(obj: T) => filterObject(obj, (key, value) => value !== undefined);
        //Caregiver FormArray
        const caregiversFormArray = new FormArray<CaregiverEditFormType>([]);
        patientEditRequest?.caregiverEditRequests?.forEach((caregiverEditRequest: CaregiverEditRequestDto) => {
            const caregiver = patient.caregivers.find((c) => c.id === caregiverEditRequest.caregiverId);
            const caregiverFormGroup = new CaregiverEditFormType(
                filterUndefineds({
                    id: new FormControl<number | null>(caregiverEditRequest?.id ?? null),
                    patientEditRequestId: new FormControl<number | null>(
                        caregiverEditRequest?.patientEditRequestId ?? null
                    ),
                    address1: !!caregiverEditRequest?.address1
                        ? new FormControl<string | null>(caregiverEditRequest?.address1 ?? null, [
                              maxLengthValidator(100),
                              Validators.required,
                          ])
                        : undefined,
                    address2: !!caregiverEditRequest?.address2
                        ? new FormControl<string | null>(caregiverEditRequest?.address2 ?? null, [
                              maxLengthValidator(100),
                          ])
                        : undefined,
                    caregiverId: new FormControl<number>(caregiverEditRequest.caregiverId, [Validators.required]),
                    addressSameAsPatient: new FormControl<boolean | null>(
                        caregiverEditRequest?.addressSameAsPatient ?? false
                    ),
                    city: !!caregiverEditRequest?.city
                        ? new FormControl<string | null>(caregiverEditRequest?.city ?? null, [
                              maxLengthValidator(100),
                              Validators.required,
                          ])
                        : undefined,
                    country: !!caregiverEditRequest?.country
                        ? new FormControl<string | null>(caregiverEditRequest?.country ?? null, [
                              maxLengthValidator(65),
                              Validators.required,
                          ])
                        : undefined,
                    phone: !!caregiverEditRequest?.phone
                        ? new FormControl<string | null>(caregiverEditRequest?.phone ?? null, [
                              phoneNumberFormatValidatorWithMaxLength(16),
                              Validators.required,
                          ])
                        : undefined,
                    preferredLang: !!caregiverEditRequest?.preferredLang
                        ? new FormControl<string | null>(caregiverEditRequest?.preferredLang ?? null, [
                              maxLengthValidator(50),
                              Validators.required,
                          ])
                        : undefined,
                    state: new FormControl<string | null>(caregiverEditRequest?.state ?? null, [
                        maxLengthValidator(100),
                    ]),
                    zipcode: !!caregiverEditRequest?.zipcode
                        ? new FormControl<string | null>(caregiverEditRequest?.zipcode ?? null, [
                              maxLengthValidator(10),
                          ])
                        : undefined,
                    //international
                    passportInfo: new PassportInfoFormGroupType(
                        filterUndefineds({
                            passportCountry: !!caregiverEditRequest?.passportCountry
                                ? new PersistentFormControl<string | null>(
                                      countries.find((country) =>
                                          caregiverEditRequest?.passportCountry
                                              ? country.countryCode === caregiverEditRequest?.passportCountry
                                              : false
                                      )?.viewValue ?? null,
                                      [maxLengthValidator(65)]
                                  )
                                : undefined,
                            passportExpiration: !!caregiverEditRequest?.passportExpiration
                                ? new PersistentFormControl<Moment | null>(
                                      !!caregiverEditRequest?.passportExpiration
                                          ? utc(caregiverEditRequest?.passportExpiration)
                                          : null,
                                      [moreThanTodayDateValidator]
                                  )
                                : undefined,
                            passportIssue: !!caregiverEditRequest?.passportIssue
                                ? new PersistentFormControl<Moment | null>(
                                      !!caregiverEditRequest?.passportIssue
                                          ? utc(caregiverEditRequest?.passportIssue)
                                          : null,
                                      [lessThanTodayDateValidator]
                                  )
                                : undefined,
                            passportNum: !!caregiverEditRequest?.passportNum
                                ? new PersistentFormControl<string | null>(caregiverEditRequest?.passportNum ?? null, [
                                      maxLengthValidator(20),
                                  ])
                                : undefined,
                        }),
                        [passportValidatorCaregiver(caregiver)]
                    ),
                })
            );
            caregiversFormArray.push(caregiverFormGroup);
        });

        const patientFormGroup = new PatientEditFormType(
            filterUndefineds({
                //patient
                id: new FormControl<number | null>(patientEditRequest?.id ?? null),
                address1: !!patientEditRequest?.address1
                    ? new FormControl<string | null>(patientEditRequest?.address1 ?? null, [
                          maxLengthValidator(100),
                          Validators.required,
                      ])
                    : undefined,
                address2: new FormControl<string | null>(patientEditRequest?.address2 ?? null, [
                    maxLengthValidator(100),
                ]),
                patientId: new FormControl<number>(patientEditRequest.patientId, [Validators.required]),
                city: !!patientEditRequest?.city
                    ? new FormControl<string | null>(patientEditRequest?.city ?? null, [
                          maxLengthValidator(100),
                          Validators.required,
                      ])
                    : undefined,
                country: !!patientEditRequest?.country
                    ? new FormControl<string | null>(patientEditRequest?.country ?? null, [
                          maxLengthValidator(65),
                          Validators.required,
                      ])
                    : undefined,
                //Database allows 15 characters for phone number, will be parsed to allow edge cases
                phone: !!patientEditRequest?.phone
                    ? new FormControl<string | null>(patientEditRequest?.phone ?? null, [
                          phoneNumberFormatValidatorWithMaxLength(16),
                          Validators.required,
                      ])
                    : undefined,
                preferredLang: !!patientEditRequest?.preferredLang
                    ? new FormControl<string | null>(patientEditRequest?.preferredLang ?? null, [
                          maxLengthValidator(50),
                          Validators.required,
                      ])
                    : undefined,
                state: new FormControl<string | null>(patientEditRequest?.state ?? null, [maxLengthValidator(100)]),
                zipcode: !!patientEditRequest?.zipcode
                    ? new FormControl<string | null>(patientEditRequest?.zipcode ?? null, [maxLengthValidator(10)])
                    : undefined,
                profileChangeDetails: new FormControl<string | null>(patientEditRequest?.profileChangeDetails ?? null, [
                    maxLengthValidator(500),
                ]),
                //caregivers
                caregiverEditRequest: caregiversFormArray,
                //airline
                airlineEditRequest: new AirlineFormGroupType({
                    airlinePrimary: new FormControl<string | null>(patientEditRequest?.airlinePrimary ?? null, [
                        maxLengthValidator(50),
                    ]),
                    airlineRewardsPrimary: new FormControl<string | null>(
                        patientEditRequest?.airlineRewardsPrimary ?? null,
                        [maxLengthValidator(50)]
                    ),
                    airlineRewardsSecondary: new FormControl<string | null>(
                        patientEditRequest?.airlineRewardsSecondary ?? null,
                        [maxLengthValidator(50)]
                    ),
                    airlineSecondary: new FormControl<string | null>(patientEditRequest?.airlineSecondary ?? null, [
                        maxLengthValidator(50),
                    ]),
                    airlineSpecialNeeds: new FormControl<string | null>(
                        patientEditRequest?.airlineSpecialNeeds ?? null,
                        [maxLengthValidator(500)]
                    ),
                    seatPreference: new FormControl<string | null>(patientEditRequest?.seatPreference ?? null, [
                        maxLengthValidator(75),
                    ]),
                    knownTravelerNum: new FormControl<string | null>(patientEditRequest?.knownTravelerNum ?? null, [
                        maxLengthValidator(20),
                    ]),
                }),
                //train
                trainEditRequest: new FormGroup({
                    trainSpecialNeeds: new FormControl<string | null>(patientEditRequest?.trainSpecialNeeds ?? null, [
                        maxLengthValidator(500),
                    ]),
                }),
                //international
                passportInfo: new PassportInfoFormGroupType(
                    filterUndefineds({
                        //Find country by threeCharCode and return name of country
                        passportCountry: !!patientEditRequest?.passportCountry
                            ? new PersistentFormControl<string | null>(
                                  countries.find((country) =>
                                      patientEditRequest?.passportCountry
                                          ? country.countryCode === patientEditRequest?.passportCountry
                                          : false
                                  )?.viewValue ?? null,
                                  [maxLengthValidator(65)]
                              )
                            : undefined,
                        passportExpiration: !!patientEditRequest?.passportExpiration
                            ? new PersistentFormControl<Moment | null>(
                                  !!patientEditRequest?.passportExpiration
                                      ? utc(patientEditRequest?.passportExpiration)
                                      : null,
                                  [moreThanTodayDateValidator]
                              )
                            : undefined,
                        passportIssue: !!patientEditRequest?.passportIssue
                            ? new PersistentFormControl<Moment | null>(
                                  !!patientEditRequest?.passportIssue ? utc(patientEditRequest?.passportIssue) : null,
                                  [lessThanTodayDateValidator]
                              )
                            : undefined,
                        passportNum: !!patientEditRequest?.passportNum
                            ? new PersistentFormControl<string | null>(patientEditRequest?.passportNum ?? null, [
                                  maxLengthValidator(20),
                              ])
                            : undefined,
                    }),
                    [passportValidatorPatient(patient)]
                ),
                //lodging
                lodgingEditRequest: new LodgingFormGroupType({
                    hotelChainPrimary: new FormControl<string | null>(patientEditRequest?.hotelChainPrimary ?? null, [
                        maxLengthValidator(50),
                    ]),
                    lodgingRoomPreference: new FormControl<string | null>(
                        patientEditRequest?.lodgingRoomPreference ?? null,
                        [maxLengthValidator(500)]
                    ),
                    allergies: new FormControl<string | null>(patientEditRequest?.allergies ?? null, [
                        maxLengthValidator(1000),
                    ]),
                    lodgingSpecialNeeds: new FormControl<string | null>(
                        patientEditRequest?.lodgingSpecialNeeds ?? null,
                        [maxLengthValidator(500)]
                    ),
                }),

                //rental car
                rentalCarEditRequest: new FormGroup({
                    rentalCarFrequentTravelerNum: new FormControl<string | null>(
                        patientEditRequest?.rentalCarFrequentTravelerNum ?? null,
                        [maxLengthValidator(100)]
                    ),
                }),
                //car service
                carServiceEditRequest: new FormGroup({
                    groundSpecialNeeds: new FormControl<string | null>(patientEditRequest?.groundSpecialNeeds ?? null, [
                        maxLengthValidator(500),
                    ]),
                }),
            })
        );
        return patientFormGroup;
    }

    static applyChanges(
        editFormGroup: PatientEditFormType,
        acceptedFields: AcceptedFieldsPatientFormType,
        patient: Patient,
        countries: CountryViewModel[]
    ): PatientDataModel {
        let patientDataModel = transformToPatientDataModel(patient);

        let caregiversWithAppliedChanges: Caregiver[] = [];

        // save caregiver fields
        patientDataModel.caregivers.forEach((caregiver) => {
            //find editFormControl
            const caregiverEdit = editFormGroup.controls.caregiverEditRequest.controls.find(
                (control) => control.controls.caregiverId.value === caregiver.id
            );
            //find acceptFormControl
            const caregiverAccept = acceptedFields.controls.caregiverEditRequest.controls.find(
                (control) => control.controls.caregiverId.value === caregiver.id
            );
            if (!!caregiverEdit && !!caregiverAccept) {
                caregiversWithAppliedChanges.push(
                    applyChangesCaregiver(caregiverEdit, caregiverAccept, caregiver, countries)
                );
            }
        });

        // save patient fields
        const acceptValues = acceptedFields.value;
        const editValues = editFormGroup.value;

        const patientWithAcceptedData: PatientDataModel = {
            ...patientDataModel,
            caregivers: caregiversWithAppliedChanges,
            patientDatum: {
                ...patientDataModel.patientDatum,
                //Addresss fields
                address1: applyChange(
                    acceptValues.address1,
                    editValues.address1,
                    patientDataModel.patientDatum.address1
                ),
                address2: applyChange(
                    acceptValues.address2,
                    editValues.address2,
                    patientDataModel.patientDatum.address2
                ),
                city: applyChange(acceptValues.city, editValues.city, patientDataModel.patientDatum.city),
                country: applyChange(acceptValues.country, editValues.country, patientDataModel.patientDatum.country),
                state: applyChange(acceptValues.state, editValues.state, patientDataModel.patientDatum.state),
                zipcode: applyChange(acceptValues.zipcode, editValues.zipcode, patientDataModel.patientDatum.zipcode),
                //Phone & preferred language
                phone: applyChange(acceptValues.phone, editValues.phone, patientDataModel.patientDatum.phone),
                preferredLang: applyChange(
                    acceptValues.preferredLang,
                    editValues.preferredLang,
                    patientDataModel.patientDatum.preferredLang
                ),
                //Airline Fields 2
                airlineRewardsPrimary: applyChange(
                    acceptValues.airlineEditRequest?.airlineRewardsPrimary,
                    editValues.airlineEditRequest?.airlineRewardsPrimary,
                    patientDataModel.patientDatum.airlineRewardsPrimary
                ),
                airlineRewardsSecondary: applyChange(
                    acceptValues.airlineEditRequest?.airlineRewardsSecondary,
                    editValues.airlineEditRequest?.airlineRewardsSecondary,
                    patientDataModel.patientDatum.airlineRewardsSecondary
                ),
                airlineSpecialNeeds: applyChange(
                    acceptValues.airlineEditRequest?.airlineSpecialNeeds,
                    editValues.airlineEditRequest?.airlineSpecialNeeds,
                    patientDataModel.patientDatum.airlineSpecialNeeds
                ),
                knownTravelerNum: applyChange(
                    acceptValues.airlineEditRequest?.knownTravelerNum,
                    editValues.airlineEditRequest?.knownTravelerNum,
                    patientDataModel.patientDatum.knownTravelerNum
                ),
                //Train Field
                trainSpecialNeeds: applyChange(
                    acceptValues.trainEditRequest?.trainSpecialNeeds,
                    editValues.trainEditRequest?.trainSpecialNeeds,
                    patientDataModel.patientDatum.trainSpecialNeeds
                ),
                //International (Passport) Fields
                passportCountry: applyChange(
                    acceptValues.passportInfo?.passportCountry,
                    getPassportCode(editValues.passportInfo?.passportCountry, countries),
                    patientDataModel.patientDatum.passportCountry
                ),
                passportNum: applyChange(
                    acceptValues.passportInfo?.passportNum,
                    editValues.passportInfo?.passportNum,
                    patientDataModel.patientDatum.passportNum
                ),
                passportIssue: applyChange(
                    acceptValues.passportInfo?.passportIssue,
                    editValues.passportInfo?.passportIssue?.toISOString(),
                    patientDataModel.patientDatum.passportIssue
                ),
                passportExpiration: applyChange(
                    acceptValues.passportInfo?.passportExpiration,
                    editValues.passportInfo?.passportExpiration?.toISOString(),
                    patientDataModel.patientDatum.passportExpiration
                ),
                //Lodging Fields 2
                lodgingRoomPreference: applyChange(
                    acceptValues.lodgingEditRequest?.lodgingRoomPreference,
                    editValues.lodgingEditRequest?.lodgingRoomPreference,
                    patientDataModel.patientDatum.lodgingRoomPreference
                ),
                lodgingSpecialNeeds: applyChange(
                    acceptValues.lodgingEditRequest?.lodgingSpecialNeeds,
                    editValues.lodgingEditRequest?.lodgingSpecialNeeds,
                    patientDataModel.patientDatum.lodgingSpecialNeeds
                ),
                //Rental Car Field
                rentalCarFrequentTravelerNum: applyChange(
                    acceptValues.rentalCarEditRequest?.rentalCarFrequentTravelerNum,
                    editValues.rentalCarEditRequest?.rentalCarFrequentTravelerNum,
                    patientDataModel.patientDatum.rentalCarFrequentTravelerNum
                ),
                //Car Service Field
                groundSpecialNeeds: applyChange(
                    acceptValues.carServiceEditRequest?.groundSpecialNeeds,
                    editValues.carServiceEditRequest?.groundSpecialNeeds,
                    patientDataModel.patientDatum.groundSpecialNeeds
                ),
            },
            //Airline Fields 1
            airlinePrimary: applyChange(
                acceptValues.airlineEditRequest?.airlinePrimary,
                editValues.airlineEditRequest?.airlinePrimary,
                patientDataModel.airlinePrimary
            ),
            airlineSecondary: applyChange(
                acceptValues.airlineEditRequest?.airlineSecondary,
                editValues.airlineEditRequest?.airlineSecondary,
                patientDataModel.airlineSecondary
            ),
            seatPreference: applyChange(
                acceptValues.airlineEditRequest?.seatPreference,
                editValues.airlineEditRequest?.seatPreference,
                patientDataModel.seatPreference
            ),
            //Lodging Fields 1
            hotelChainPrimary: applyChange(
                acceptValues.lodgingEditRequest?.hotelChainPrimary,
                editValues.lodgingEditRequest?.hotelChainPrimary,
                patientDataModel.hotelChainPrimary
            ),
            allergies: applyChange(
                acceptValues.lodgingEditRequest?.allergies,
                editValues.lodgingEditRequest?.allergies,
                patientDataModel.allergies
            ),
        };
        return patientWithAcceptedData;
    }
}

function applyChangesCaregiver(
    caregiverEdit: CaregiverEditFormType,
    caregiverAccept: AcceptedFieldsCaregiverFormType,
    caregiver: Caregiver,
    countries: CountryViewModel[]
): Caregiver {
    const editValues = caregiverEdit.value;
    const acceptValues = caregiverAccept.value;
    let addressFields = {};
    if (!editValues.addressSameAsPatient) {
        addressFields = {
            address1: applyChange(acceptValues.address1, editValues.address1, caregiver.address1),
            address2: applyChange(acceptValues.address2, editValues.address2, caregiver.address2),
            city: applyChange(acceptValues.city, editValues.city, caregiver.city),
            country: applyChange(acceptValues.country, editValues.country, caregiver.country),
            state: applyChange(acceptValues.state, editValues.state, caregiver.state),
            zipcode: applyChange(acceptValues.zipcode, editValues.zipcode, caregiver.zipcode),
        };
    } else {
        addressFields = {
            address1: undefined,
            address2: undefined,
            city: undefined,
            country: undefined,
            state: undefined,
            zipcode: undefined,
        };
    }

    const caregiverWithAcceptedData: Caregiver = {
        ...caregiver,
        addressSameAsPatient: editValues.addressSameAsPatient,
        ...addressFields,
        phone: applyChange(acceptValues.phone, editValues.phone, caregiver.phone),
        preferredLang: applyChange(acceptValues.preferredLang, editValues.preferredLang, caregiver.preferredLang),
        //Passport Fields
        passportCountry: applyChange(
            acceptValues.passportInfo?.passportCountry,
            getPassportCode(editValues.passportInfo?.passportCountry, countries),
            caregiver.passportCountry
        ),
        passportNum: applyChange(
            acceptValues.passportInfo?.passportNum,
            editValues.passportInfo?.passportNum,
            caregiver.passportNum
        ),
        passportIssue: applyChange(
            acceptValues.passportInfo?.passportIssue,
            editValues.passportInfo?.passportIssue?.toISOString(),
            caregiver.passportIssue
        ),
        passportExpiration: applyChange(
            acceptValues.passportInfo?.passportExpiration,
            editValues.passportInfo?.passportExpiration?.toISOString(),
            caregiver.passportExpiration
        ),
    };
    return caregiverWithAcceptedData;
}

/**
 * Returns field value dependant on `accept` property or returns `oldValue` if `newValue` is empty
 * @param accept Whether the new value should be accepted
 * @param newValue New field value
 * @param oldValue Old field value
 */
function applyChange<T>(accept: boolean, newValue: T, oldValue: T): T {
    return accept ? (newValue ?? oldValue) : oldValue;
}

function getPassportCode(passportCountry: string, countries: CountryViewModel[]): string {
    return countries.find((country) => country.viewValue === passportCountry)?.countryCode ?? null;
}
