import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Study } from '../../models/study';
import { AdminRequestServices } from '../../services/admin/admin-request.service';
import { AdminStudyServices } from '../../services/admin/admin-study.sevice';
import { FormDataService } from '../../services/form-data/form-data.service';

@Component({
    selector: '',
    templateUrl: './study-table.component.html',
    styleUrls: [],
})
export class MedpaceStudyTableComponent implements OnInit {
    studies: Study[];

    regions: any[];

    columns: string[] = ['sponsor', 'protocol', 'ctm', 'prm', 'numSites', 'numPatients', 'requests'];

    columnMap: any[] = [
        { header: 'sponsor name', name: 'sponsor' },
        { header: 'protocol', name: 'protocol' },
        { header: 'ctm', name: 'ctm' },
        { header: 'prm', name: 'prm' },
        { header: '# sites', name: 'numSites' },
        { header: '# patients', name: 'numPatients' },
        { header: 'Open requests', name: 'requests' },
        { header: 'regions', name: 'regions' },
    ];

    constructor(
        private router: Router,
        private adminStudies: AdminStudyServices,
        private requestServices: AdminRequestServices,
        private formData: FormDataService
    ) {}

    ngOnInit(): void {
        this.regions = [];

        this.adminStudies.getStudySummaries().subscribe((result) => (this.studies = result));
    }

    doClickRow(event: any) {
        this.router.navigate([`studies/${event.id}`], { state: event });
    }
}
