// shared functionality between the components
import { AbstractControl, FormArray, FormControl, FormGroup, isFormArray, isFormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DistanceUnit } from '@components/molecules/forms/medpace-create-edit-request-expense-details/medpace-create-edit-request-expense-details.component';
import { MedpaceMessageModalComponent } from '@components/molecules/modals/medpace-message-modal/medpace-message-modal.component';
import { MdsOption } from '@medpacesoftwaredevelopment/designsystem/interfaces/mds-option';
import { MedpaceAutocompleteObject } from '@models/medpaceAutocompleteObject';
import { CRCData, SiteCRC } from '@models/site';
import { SnackbarService } from '@services/snackbar/snackbar.service';
import { saveAs } from 'file-saver';

import { ParseError, parsePhoneNumber } from 'libphonenumber-js/min';
import { Observable, combineLatest, map, skipWhile, startWith } from 'rxjs';
import { WorkBook, WorkSheet, utils, writeFile } from 'xlsx';

export function _filterCountries(aCountry: any, countries: MdsOption[]): any {
    if (typeof aCountry === 'string') {
        let countryName = aCountry.toLowerCase();
        return countries.filter((c) => c.viewValue.toLowerCase().includes(countryName));
    } else {
        return countries;
    }
}

function saveAsFile(buffer: any, fileName: string, fileType: string): void {
    const data: Blob = new Blob([buffer], { type: fileType });
    saveAs(data, fileName);
}

export function markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
        control.markAsTouched();

        if (control instanceof FormGroup) {
            markFormGroupTouched(control);
        }
        if (control instanceof FormArray) {
            control.controls.forEach((value) => {
                if (value instanceof FormGroup) {
                    markFormGroupTouched(value);
                }
            });
        }
    });
}

/**
 * Creates a new object that has live references to the properties of the input object,
 *  except those specified to be excluded.
 *
 * @param {T} obj The input object that the function will create a live reference of.
 * @param {(keyof T)[]} [excludeProps=[]] An optional array of property keys to be excluded
 *  from the live reference. If not provided, the function will keep a live reference of all properties.
 *
 * @returns {T} The new object that has live references to the properties of
 *  the input object (except for the excluded properties).
 */
export function createLiveReference<T>(obj: T, excludeProps: (keyof T)[] = []): T {
    const newObj = <T>{};
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            if (!excludeProps.includes(key as keyof T)) {
                Object.defineProperty(newObj, key, {
                    get: () => obj[key],
                    set: (value: any) => {
                        obj[key] = value;
                    },
                    enumerable: true,
                });
            } else {
                newObj[key] = undefined;
            }
        }
    }
    return newObj as T;
}

export function collectErrors(control: AbstractControl): { [key: string]: any } | null {
    let errors = {};
    let recursiveFunc = (control: AbstractControl) => {
        if ((isFormGroup(control) || isFormArray(control)) && control.invalid) {
            return Object.entries(control.controls).reduce((acc, [key, childControl]) => {
                const childErrors = recursiveFunc(childControl);
                if (childErrors) {
                    if (!isFormGroup(childControl) && control.invalid) {
                        errors = { ...errors, [key]: childErrors };
                    }
                    acc = { ...acc, [key]: childErrors };
                }
                return acc;
            }, null);
        } else {
            if (control?.errors) {
                return control.errors;
            }
            return null;
        }
    };
    recursiveFunc(control);
    return errors;
}

export function getNamesOfTravelRequestTabsContainingErrors(control: AbstractControl): string[] {
    if (!control.invalid) {
        return new Array<string>();
    }

    let namesOfInvalidTabsInElementAndChildren = new Array<string>();
    if ('tab' in control && control.tab !== undefined) {
        namesOfInvalidTabsInElementAndChildren.push(control.tab as string);
    }

    if (isFormGroup(control) || isFormArray(control)) {
        for (let c of Object.values(control.controls)) {
            const namesOfInvalidTabsInChild = getNamesOfTravelRequestTabsContainingErrors(c);
            namesOfInvalidTabsInElementAndChildren =
                namesOfInvalidTabsInElementAndChildren.concat(namesOfInvalidTabsInChild);
        }
    }

    const namesOfInvalidTabsInElementAndChildrenWithoutDuplicates = [
        ...new Set(namesOfInvalidTabsInElementAndChildren),
    ];
    return namesOfInvalidTabsInElementAndChildrenWithoutDuplicates;
}

export function snackbarErrorMessage(error: { [key: string]: any }, controlerName: string): string {
    let text = '';
    const key = Object.keys(error)[0];
    switch (key) {
        case 'required':
            text = `${controlerName} is required`;
            break;
        case 'pattern':
            text = `${controlerName} has incorrect format`;
            break;
        case 'email':
            text = `${controlerName} has wrong email format`;
            break;
        case 'minlength':
            text = `${controlerName} field is too short`;
            break;
        case 'min':
            text = `${controlerName} is too low`;
            break;
        case 'max':
            text = `${controlerName} is too high`;
            break;
        case 'maxlength':
            text = `${controlerName} field is too long`;
            break;
        case 'areEqual':
            text = `${controlerName} must be equal to ${error['equalTo']}`;
            break;
        default:
            let defaultError = error[key];

            if (typeof defaultError !== 'object') {
                defaultError = `${controlerName}: ${defaultError}`;
                text = defaultError;
            } else {
                text = `${controlerName} has invalid data`;
            }
    }
    return text;
}

export function buildSnackBar(errors: { [key: string]: any }, snackbarService: SnackbarService): void {
    let snackbarText = '';
    Object.keys(errors).forEach((controlName) => {
        let name = '';
        if (controlName.includes('visitType')) {
            let visitName = controlName.split(/(\d+)/);
            name = `${controlLabels[visitName[0]]} ${visitName[1]}`;
        } else if (Object.keys(controlLabels).includes(controlName)) {
            name = controlLabels[controlName];
        } else {
            //name is empty, dont display snackbar
            console.warn(
                `Validation failed for the control named '${controlName}'. Snackbar was not displayed to the user because control's name was not listed as something we should display (possibly, because it's clearly highlighted in red already).`
            );

            name = '';
        }
        if (name !== '') {
            const errorMessage = snackbarErrorMessage(errors[controlName], name);
            if (!snackbarText.includes(errorMessage)) {
                snackbarText += ` ${errorMessage}\n`;
            }
        }
    });
    if (snackbarText.endsWith('\n')) {
        snackbarText = snackbarText.slice(1, -1);
    }

    snackbarService.openErrorSnackbar(snackbarText);
}

export function prepareDialogForNoDataToExport(dialog: MatDialog): void {
    if (!dialog) return;

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.maxWidth = 400;
    dialogConfig.minWidth = 350;

    dialogConfig.data = {
        title: 'There is no data available to export.',
        bodyText: '',
        okayButtonLabel: 'Close',
        showCancelButton: false,
    };

    dialog.open(MedpaceMessageModalComponent, dialogConfig);
}

/**
 * Creates an array of data to CSV. It will automatically generate a title row based on object keys.
 *
 * @param rows array of data to be converted to CSV.
 * @param fileName filename to save as.
 * @param columns array of object properties to convert to CSV. If skipped, then all object properties will be used for CSV.
 */
export function exportToCsv(rows: object[], fileName: string, columns?: string[], dialog?: MatDialog): string {
    if (!rows || !rows.length) {
        prepareDialogForNoDataToExport(dialog);
        return;
    }
    const separator = ',';

    //create a copy of original keys without reference
    let renamedKeys = JSON.parse(JSON.stringify(columns));
    renamedKeys[renamedKeys.indexOf('patientNum')] = renamedKeys[renamedKeys?.indexOf('patientNum')]?.replace(
        'patientNum',
        'patientID'
    );
    renamedKeys[renamedKeys.indexOf('visitType')] = renamedKeys[renamedKeys?.indexOf('visitType')]?.replace(
        'visitType',
        'visitName'
    );

    const csvContent =
        renamedKeys?.join(separator) +
        '\n' +
        rows
            ?.map((row) => {
                return columns
                    ?.map((k) => {
                        let cell = row[k] === null || row[k] === undefined ? '' : row[k];
                        cell = cell instanceof Date ? cell?.toLocaleString() : cell?.toString()?.replace(/"/g, '""');

                        if (cell?.search(/("|,|\n)/g) >= 0) {
                            cell = `"${cell}"`;
                        }

                        if (k === 'patientNum') {
                            cell = `="${cell}"`;
                        }
                        return cell;
                    })
                    ?.join(separator);
            })
            ?.join('\n');
    saveAsFile(csvContent, `${fileName}.csv`, 'text/csv');
}

export function mapCRCData_To_SiteCRCs(data: CRCData, siteId: number): SiteCRC[] {
    const primaryCRC = <SiteCRC>{
        isPrimary: true,
        siteId: siteId,
        user: data.primaryCRC,
        userId: data.primaryCRC.id,
    };
    const supportingCRCs = data.supportingCRCs.map(
        (crc) =>
            <SiteCRC>{
                isPrimary: false,
                siteId: siteId,
                user: crc,
                userId: crc.id,
            }
    );
    return [primaryCRC, ...supportingCRCs];
}

export function filterAsync<T>(
    inputValues$: Observable<MdsOptionGeneric<T>[]>,
    control: FormControl
): Observable<string[]> {
    return combineLatest([
        control?.valueChanges.pipe(startWith(null)),
        inputValues$.pipe(skipWhile((x) => x === null)),
    ]).pipe(
        map(([value, input]) => {
            let filteredArray: string[] = input.map((v) => v.viewValue);
            if (!!value && !control.valid) {
                filteredArray = input
                    .map((v) => v.viewValue)
                    .filter((v) => v.toLowerCase().includes(value.toLowerCase()));
            }
            return filteredArray ?? [];
        })
    );
}

export const visitNameStrings = [
    'Screening',
    'Admin1',
    'Admin2',
    'Efficacy 1',
    'Adverse Reaction',
    'Efficacy 2',
    'Visit 1.2',
    'Efficacy Check 2',
];

export const requestTypeStrings = [
    'Travel',
    'Out of Pocket',
    'Lodging',
    'Stipend',
    'Flight',
    'Train',
    'Car',
    'Reimbursement',
];

const studyControlLabels = {
    dateFPFV: 'FPFV',
    dateLPLV: 'LPLV',
    globalCtmControl: 'Global CTM',
    numPatients: '# Patients',
    pcControl: 'PC',
    prmControl: 'PRM',
    protocolControl: 'Protocol',
    regionsDropdown: 'Region',
    sitesControl: '# Sites',
    sponsorControl: 'Sponsor',
    studyCodeControl: 'Study Code',
    travelOptionsDropdown: 'Travel Options Field',
    visitTypeControl: 'Visit Schedule',
};

const siteControlLabels = {
    checkedSpecificServicesFormGroup: 'Specific services option',
    cityControl: 'City',
    countryControl: 'Country',
    primaryCRC: 'CRC',
    line1Control: 'Address Line 1',
    nameControl: 'Institution Name',
    pilastNameControl: 'PI Last Name',
    postalCodeControl: 'Zipcode / Postal Code',
    regionControl: 'Region',
    siteNumberControl: 'Site #',
    supportingCRCs: 'Supporting CRC',
};

const patientControlLabels = {
    address1Control: 'Address Line 1',
    address2Control: 'Address Line 2',
    birthDateControl: 'Birthdate',
    cityControl: 'City',
    consentControl: 'Consent',
    emailAddressControl: 'Email Address',
    firstNameControl: 'First / Given Name',
    genderControl: 'Gender',
    lastNameControl: 'Last / Surname',
    patientIDControl: 'Patient ID',
    passportCountry: 'Passport Country',
    passportExpiration: 'Passport Expiration Date',
    passportIssue: 'Passport Issue Date',
    passportNum: 'Passport Number',
    phoneNumberControl: 'Phone Number',
    preferredLanguageControl: 'Preferred Language',
    zipcodeControl: 'Zipcode / Postal Code',
    travellersPassportFormArray: '',
    additionalRequirementsControl: 'Additional Requirements field',
    kycVendorIdControl: 'Vendor ID',
    preferredMethodDopdownControl: 'Preferred Method',
    preferredCurrencyDopdownControl: 'Preferred Currency',
};

const caregiverControlLabels = {
    address1: 'Address Line 1',
    address2: 'Address Line 2',
    birthDate: 'Birthdate',
    city: 'City',
    country: 'Country',
    email: 'Email Address',
    firstName: 'First Name',
    gender: 'Gender',
    language: 'Preferred Language',
    lastName: 'Last / Surname',
    phoneNumber: 'Phone Number',
    relationshipToPatient: 'Relationship to Patient',
    zipCode: 'Zipcode / Postal Code',
};

const requestControlLabels = {
    isAnyCaregiverOptionSelected: 'Travel Information Option',
    requestTypeCheckbox: 'Request Type',

    visitEndDate: 'Visit Details',
    visitEndTime: 'Visit Details',
    visitName: 'Visit Name',
    visitStartDate: 'Visit Details',
    visitStartTime: 'Visit Details',

    fieldFormArrayAirlinePrefsRequest: 'Flight tab',
    fieldFormArrayGroundPrefsRequest: 'Car Services tab',
    fieldFormArrayLodgingPrefsRequest: 'Lodging tab',
    fieldFormArrayOtherPrefsRequest: 'Other tab',
    fieldFormArrayTrainPrefsRequest: 'Train tab',
    fieldFormArrayRentalCarPrefsRequest: 'Rental Car tab',

    amountControl: 'Amount to be Authorized',
    totalAmount: 'Total to be Authorized',
    category: 'Expense Type',
    timestamp: 'Receipt Date',
    stipendReimbursementControl: 'Request Type',
    visitDate: 'Visit Date',
    fromLocation: 'From Location Field',
    toLocation: 'To Location Field',
};

const patientEditRequestLabels = {
    preferredLang: 'Preferred Language',
    phone: 'Phone Number',
    address1: 'Address Line 1',
    address2: 'Address Line 2',
    city: 'City',
    state: 'State',
    zipcode: 'Zipcode / Postal Code',
    country: 'Country',
    profileChangeDetails: 'Profile Change Details',
    airlinePrimary: 'Airline Preference #1',
    airlineSecondary: 'Airline Preference #2',
    airlineRewardsPrimary: 'Frequent Flyer No. #1',
    airlineRewardsSecondary: 'Frequent Flyer No. #2',
    seatPreference: 'Seat Preference',
    knownTravelerNum: 'Known Traveler Number',
    airlineSpecialNeeds: 'Airline Special Needs',
    trainSpecialNeeds: 'Train Special Needs',
    passportNum: 'Passport Number',
    passportCountry: 'Passport Country',
    passportIssue: 'Passport Issue Date',
    passportExpiration: 'Passport Expiration Date',
    hotelChainPrimary: 'Hotel Brand Preference',
    lodgingRoomPreference: 'Hotel Room Preference',
    allergies: 'Allergies',
    lodgingSpecialNeeds: 'Lodging Special Needs',
    rentalCarFrequentTravelerNum: 'Rental Car Frequent Traveler Number',
    groundSpecialNeeds: 'Car Service Special Needs',
    caregiverEditRequest: 'Caregiver',
};

export const controlLabels = {
    ...siteControlLabels,
    ...studyControlLabels,
    ...patientControlLabels,
    ...caregiverControlLabels,
    ...requestControlLabels,
    ...patientEditRequestLabels,
};

let baseDropdownControls = [
    { labelText: 'Status', type: 'MultiDropDown', placeholderText: '', values: null },
    { labelText: 'Visit Name', type: 'MultiDropDown', placeholderText: '', values: null },
    { labelText: 'Visit Date', type: 'RangeDatePicker', placeholderText: 'Select Range', values: null },
    { labelText: 'Request Type', type: 'MultiDropDown', placeholderText: '', values: null },
    { labelText: 'Request Date', type: 'RangeDatePicker', placeholderText: 'Select Range', values: null },
];

let patientDropdownControls = [
    { labelText: 'Protocol', type: 'MultiDropDown', placeholderText: '', values: null },
    { labelText: 'Patient ID', type: 'MultiDropDown', placeholderText: '', values: null },
];

let patientAllDropdownControls = [
    { labelText: 'Country', type: 'MultiDropDown', placeholderText: '', values: null },
    {
        filterByName: 'statusid',
        labelText: 'Status',
        type: 'MultiDropDown',
        placeholderText: '',
        values: null,
    },
];

let accessRequestDropdownControls = [
    { labelText: 'Status', type: 'MultiDropDown', placeholderText: '', values: null },
    { labelText: 'Access Type', type: 'MultiDropDown', placeholderText: '', values: null },
    { labelText: 'Processed By', type: 'MultiDropDown', placeholderText: '', values: null },
    { labelText: 'Request Timestamp', type: 'RangeDatePicker', placeholderText: 'Select Range', values: null },
    { labelText: 'Close Timestamp', type: 'RangeDatePicker', placeholderText: 'Select Range', values: null },
];

export const _getInputControls = (controlName: string): {}[] => {
    switch (controlName) {
        case 'requests':
            return patientDropdownControls.concat(baseDropdownControls);
        case 'patientAll':
            return patientAllDropdownControls;
        case 'accessRequests':
            return accessRequestDropdownControls;
        default:
            return baseDropdownControls;
    }
};

export const getThereTimeOptions = (): MdsOption[] => {
    let target: MdsOption[] = [];
    ['am', 'pm'].forEach((part) => {
        target.push(<MdsOption>{
            value: `12:00 ${part}`,
            viewValue: `12:00 ${part}`,
        });
        for (let i = 1; i < 12; i++) {
            const option: MdsOption = {
                value: `${i}:00 ${part}`,
                viewValue: `${i}:00 ${part}`,
            };
            target.push(option);
        }
    });
    return target;
};

export interface MdsOptionGeneric<T> {
    value: T;
    viewValue: string;
}
export enum PageMode {
    CREATE,
    EDIT,
    VIEW,
}

export function RequestPageMode(activeRoute: ActivatedRoute) {
    if (activeRoute.snapshot.routeConfig.path.indexOf('/newrequest') != -1) {
        return PageMode.CREATE;
    } else if (activeRoute.snapshot.routeConfig.path.indexOf('/edit') != -1) {
        return PageMode.EDIT;
    }
}

export function toStringOrFallback(object: Object, fallback: string) {
    return !!object ? object.toString() : fallback;
}

export type FormGroupValueOf<T extends FormGroup> = {
    [K in keyof ReturnType<T['getRawValue']>]: ReturnType<T['getRawValue']>[K];
};

export function isTypeMileageExpense(expenseType: string): boolean {
    if (!!expenseType) {
        return expenseType.toLowerCase().includes('mileage');
    }
    return false;
}

export interface SaveStatusResponse {
    affectedEntities: number;
    errorMessage: string;
    modelValid: boolean;
    saveComplete: boolean;
    saveSuccessful: boolean;
    scopeIdentity: number;
    infoLabel: string;
}

/**
 *
 * @param phoneNumber string that contains a phone number
 * @returns a phone number in E.164 standard if phone number is valid, else input phone number
 */
export function tryFormatPhoneNumberToE164(phoneNumber: string): string {
    let parsedPhoneNumber;
    try {
        parsedPhoneNumber = parsePhoneNumber(phoneNumber);
    } catch (error) {
        if (error instanceof ParseError) console.debug(error);
        else throw error;
    }

    let result = parsedPhoneNumber?.number ?? phoneNumber;
    if (result.length > 15) {
        result = result.replace(/[\s+-/(/)]/g, '');
    }
    return result;
}

export function exportToExcel<T>(
    objectArray: T[],
    outputFileName: string,
    customHeaders?: string[],
    dialog?: MatDialog
): void {
    if (!objectArray || !objectArray.length) {
        prepareDialogForNoDataToExport(dialog);
        return;
    }

    const ws: WorkSheet = utils.json_to_sheet(objectArray);

    // Add custom headers to the worksheet
    if (customHeaders) {
        const headerRange = utils.decode_range(ws['!ref']);
        for (let i = headerRange.s.c; i <= headerRange.e.c; ++i) {
            const address = utils.encode_col(i) + '1'; // Assuming headers should be in the first row
            ws[address] = { t: 's', v: customHeaders[i], w: customHeaders[i] };
        }
    }
    const wb: WorkBook = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Users');

    // Generate Excel file and trigger download
    writeFile(wb, outputFileName + '.xlsx');
}

// for multi-sheet excel exports
export function createExcelSheet<T>(objectArray: T[], customHeaders?: string[], dialog?: MatDialog) {
    if (!objectArray || !objectArray.length) {
        prepareDialogForNoDataToExport(dialog);
        return;
    }

    const workSheet: WorkSheet = utils.json_to_sheet(objectArray);

    // Add custom headers to the worksheet
    if (customHeaders) {
        const headerRange = utils.decode_range(workSheet['!ref']);
        for (let i = headerRange.s.c; i <= headerRange.e.c; ++i) {
            const address = utils.encode_col(i) + '1'; // Assuming headers should be in the first row
            workSheet[address] = { t: 's', v: customHeaders[i], w: customHeaders[i] };
        }
    }
    return workSheet;
}
// create excel file from an array of sheets
export function downloadExcelFile(sheets: { workSheet: WorkSheet; name: string }[], outputFileName: string) {
    const workBook: WorkBook = utils.book_new();
    sheets.forEach((sheet) => {
        utils.book_append_sheet(workBook, sheet.workSheet, sheet.name);
    });

    // Generate Excel file and trigger download
    writeFile(workBook, outputFileName + '.xlsx');
}

export function transformStringtoMdsOption(text: string): MdsOption {
    return <MdsOption>{ value: text, viewValue: text };
}
export function parseDistanceUnit(input: string): DistanceUnit | null {
    if (!input) return null;
    return ['m', 'mi', 'miles'].includes(input.toLowerCase())
        ? 'Miles'
        : ['k', 'km', 'kilometers', 'kilometres'].includes(input.toLowerCase())
          ? 'Kilometers'
          : null;
}
export function serializeDistanceUnit(distanceUnit: DistanceUnit) {
    if (distanceUnit === 'Kilometers') return 'k';
    else if (distanceUnit === 'Miles') return 'm';
    else return '';
}
export function filterObject<T extends Object>(
    obj: T,
    filterFn: (key: keyof T, value: T[keyof T]) => boolean
): Partial<T> {
    return Object.entries(obj)
        .filter(([key, value]) => filterFn(key as keyof T, value as T[keyof T]))
        .reduce((o, [key, value]) => {
            o[key] = value;
            return o;
        }, {});
}

export function getUniqueObjects(array: MedpaceAutocompleteObject[]) {
    if (array?.length <= 0) {
        return array;
    } else {
        let keys: string[] = Object.keys(array[0]);
        const seen = new Set<string>();
        return array.filter((item) => {
            const keyString = keys.map((key) => item[key]).join('|');
            if (seen.has(keyString)) {
                return false;
            } else {
                seen.add(keyString);
                return true;
            }
        });
    }
}

export function getUniqueStrings(array: string[]) {
    return array.filter((value, index, self) => {
        return self.indexOf(value) === index;
    });
}
